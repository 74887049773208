import React, { useState, useCallback } from 'react';
import { getNiceName } from "../../utils";
import { 
    AreaChart,
    Area,
    BarChart,
    Bar,
    Cell,
    LineChart,
    Line,
    PieChart,
    Pie,
    RadialBarChart,
    RadialBar,
    Radar,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis, 
    Sector,
    ScatterChart,
    Scatter,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer 
} from 'recharts';
import { interpolateYlOrBr } from 'd3-scale-chromatic'; //https://github.com/d3/d3-scale-chromatic

function RenderGraph(props) {
    if(props.data.length === 0) {
        return "No Data";
    }

    if(props.type === "LineMarkGraph"){
        return (RenderLineChart(props));
    }
    else if(props.type === "VerticalBarGraph"){
        return (RenderBarChart(props));
    }
    else if(props.type === "RadialBarGraph"){
        return (RenderRadialBarChart(props));
    }
    else if(props.type === "StackGraph"){
        return (RenderStackBarChart(props));
    }
    else if(props.type === "PieGraph"){
        return (RenderPieChart(props));
    }
    else if(props.type === "AreaGraph"){
        return (RenderAreaChart(props));
    }
    else if(props.type === "ScatterGraph"){
        return (RenderScatterChart(props));
    }
    else if(props.type === "RadarGraph"){
        return (RenderRadarChart(props));
    }
    else {
        return (<>Unknown Graph Type</>);
    }
}

function generateColours(number) {
    let out = [];
    for(let i = 0; i < number; i++) {
        out.push(interpolateYlOrBr((number - i)/number));
    }
    return out
}

function RenderLineChart(props) {
    let newOpacityProps = {};
    let colours = [];
    let niceNames = {};
    
    if(props.data.length > 0){
        colours = generateColours(Object.keys(props.data[0]).length)
        Object.keys(props.data[0]).forEach((key) => {
            if(key !== props.GraphX) {
                newOpacityProps[key] = 1;
                niceNames[key] = getNiceName(key);
            }
        });

        //change values to numbers
        let objKeys = Object.keys(props.data[0]);
        for(var i = 0; i < props.data.length; i++) {
            for(var j = 0; j < objKeys.length; j++) {
                if(objKeys[j] !== props.GraphX && !isNaN(props.data[i][objKeys[j]])) {
                    props.data[i][objKeys[j]] = Number(props.data[i][objKeys[j]]);
                }
            }
        }
    }
    const [chartColours, setChartColours] = useState(colours);
    const [opacity, setOpacity] = useState(newOpacityProps);

    const handleMouseEnter = useCallback(
        (o) => {
            const { dataKey } = o;

            setOpacity({ ...opacity, [dataKey]: 0.5 });
        },
        [opacity, setOpacity]
    );

    const handleMouseLeave = useCallback(
        (o) => {
            const { dataKey } = o;
            setOpacity({ ...opacity, [dataKey]: 1 });
        },
        [opacity, setOpacity]
    );

    return(
        <ResponsiveContainer width="100%"  aspect={2}>
            <LineChart
                height={300}
                data={props.data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey={props.GraphX}/>
                <YAxis/>
                <Tooltip/>
                <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}/>
                {Object.keys(props.data[0]).map((datakey, index) => {
                    if(datakey !== props.GraphX) {
                        return (<Line name={niceNames[datakey]} key={index} type="monotone" dataKey={datakey} strokeOpacity={opacity[datakey]} stroke={chartColours[index]} activeDot={{ r: 8 }}/>);
                    }
                    else {
                        return (<></>);
                    }
                })}
            </LineChart>
        </ResponsiveContainer>
    )
}

function RenderBarChart(props) {
    let newOpacityProps = {};
    let colours = [];
    let niceNames = {};
    if(props.data.length > 0){
        colours = generateColours(Object.keys(props.data[0]).length);
        Object.keys(props.data[0]).forEach((key) => {
            if(key !==  props.GraphX) {
                newOpacityProps[key] = 1;
                niceNames[key] = getNiceName(key);
            }
        });

        //change values to numbers
        let objKeys = Object.keys(props.data[0]);
        for(var i = 0; i < props.data.length; i++) {
            for(var j = 0; j < objKeys.length; j++) {
                if(objKeys[j] !== props.GraphX && !isNaN(props.data[i][objKeys[j]])) {
                    props.data[i][objKeys[j]] = Number(props.data[i][objKeys[j]]);
                }
            }
        }
    }
    const [chartColours, setChartColours] = useState(colours);
    const [opacity, setOpacity] = useState(newOpacityProps);

    const handleMouseEnter = useCallback(
        (o) => {
            const { dataKey } = o;

            setOpacity({ ...opacity, [dataKey]: 0.5 });
        },
        [opacity, setOpacity]
    );

    const handleMouseLeave = useCallback(
        (o) => {
            const { dataKey } = o;
            setOpacity({ ...opacity, [dataKey]: 1 });
        },
        [opacity, setOpacity]
    );

    return(
        <ResponsiveContainer width="99%" aspect={2}>
            <BarChart
                data={props.data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey={props.GraphX}/>
                <YAxis/>
                <Tooltip/>
                <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}/>
                {Object.keys(props.data[0]).map((datakey, index) => {
                    if(datakey !== props.GraphX) {
                        return (<Bar name={niceNames[props.GraphY]} key={index} dataKey={props.GraphY} strokeOpacity={opacity[props.GraphY]} fill={chartColours[index]}/>);
                    }
                    else {
                        return (<></>);
                    }
                })}
            </BarChart>
        </ResponsiveContainer>
    )
}

function RenderRadialBarChart(props) {
    let colours = [];
    let niceNames = {};
    if(props.data.length > 0){
        colours = generateColours(Object.keys(props.data[0]).length);

        //change values to numbers
        let objKeys = Object.keys(props.data[0]);
        for(var i = 0; i < props.data.length; i++) {
            for(var j = 0; j < objKeys.length; j++) {
                if(objKeys[j] !== props.GraphX && !isNaN(props.data[i][objKeys[j]])) {
                    props.data[i][objKeys[j]] = Number(props.data[i][objKeys[j]]);
                }
            }
        }
    }
    const [chartColours, setChartColours] = useState(colours);
    return(
        <ResponsiveContainer width="99%" aspect={2}>
            <RadialBarChart 
                cx="50%"
                cy="50%"
                innerRadius="10%"
                outerRadius="80%"
                barSize={10}
                data={props.data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <RadialBar
                    minAngle={15}
                    label={{ position: 'insideStart', fill: '#fff' }}
                    background
                    clockWise
                    dataKey={props.GraphY}
                >
                    {props.data.map((entry, cindex) => (
                        <Cell key={`cell-${cindex}`} fill={chartColours[cindex]} />
                    ))}
                </RadialBar>
                <Legend iconSize={10} layout="vertical" verticalAlign="middle" wrapperStyle={{top: 0, left: 350, lineHeight: "24px"}} />
            </RadialBarChart>
        </ResponsiveContainer>
    )
}

function RenderStackBarChart(props) {
    let newOpacityProps = {};
    let colours = [];
    let niceNames = {};

    if(props.data.length > 0){
        colours = generateColours(Object.keys(props.data[0]).length);
        Object.keys(props.data[0]).forEach((key) => {
            if(key !== props.GraphX) {
                newOpacityProps[key] = 1;
                niceNames[key] = getNiceName(key);
            }
        });

        //change values to numbers
        let objKeys = Object.keys(props.data[0]);
        for(var i = 0; i < props.data.length; i++) {
            for(var j = 0; j < objKeys.length; j++) {
                if(objKeys[j] !== props.GraphX && !isNaN(props.data[i][objKeys[j]])) {
                    props.data[i][objKeys[j]] = Number(props.data[i][objKeys[j]]);
                }
            }
        }
    }

    const [chartColours, setChartColours] = useState(colours);
    const [opacity, setOpacity] = useState(newOpacityProps);

    const handleMouseEnter = useCallback(
        (o) => {
            const { dataKey } = o;

            setOpacity({ ...opacity, [dataKey]: 0.5 });
        },
        [opacity, setOpacity]
    );

    const handleMouseLeave = useCallback(
        (o) => {
            const { dataKey } = o;
            setOpacity({ ...opacity, [dataKey]: 1 });
        },
        [opacity, setOpacity]
    );
    
    return(
        <ResponsiveContainer width="99%" aspect={2}>
            <BarChart
                data={props.data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey={props.GraphX}/>
                <YAxis/>
                <Tooltip/>
                <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}/>
                {Object.keys(props.data[0]).map((datakey, index) => {
                    if(datakey !== props.GraphX) {
                        return (<Bar name={niceNames[datakey]} key={index} dataKey={datakey} stackId="a" fill={chartColours[index]}/>);
                    }
                    else {
                        return (<></>);
                    }
                })}
            </BarChart>
        </ResponsiveContainer>
    )
}

function RenderPieChart(props) {
    let colours = [];
    if(props.data.length > 0){
        colours = generateColours(props.data.length);

        //change values to numbers
        for(var i = 0; i < props.data.length; i++) {
            props.data[i][props.GraphY] = Number(props.data[i][props.GraphY]);
        }
    }
    

    const [chartColours, setChartColours] = useState(colours);
    const [activeIndex, setActiveIndex] = useState(0);
    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';
        return (
          <g>
            <Sector
              cx={cx}
              cy={cy}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              startAngle={startAngle}
              endAngle={endAngle}
              fill={fill}
            />
            <Sector
              cx={cx}
              cy={cy}
              startAngle={startAngle}
              endAngle={endAngle}
              innerRadius={outerRadius + 6}
              outerRadius={outerRadius + 10}
              fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
              {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text>
          </g>
        );
    };

    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };

    return(
        <ResponsiveContainer width="99%" aspect={2}>
            <PieChart
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 20,
                }}
            >
                <Pie
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    dataKey={props.GraphY}
                    data={props.data}
                    cx="50%"
                    cy="50%"
                    onMouseEnter={onPieEnter}
                >
                    {props.data.map((entry, cindex) => (
                        <Cell name={getNiceName(entry[props.GraphX])} key={`cell-${cindex}`} fill={chartColours[cindex]} />
                    ))}
                </Pie>
            <Legend />
            </PieChart>
        </ResponsiveContainer>
    )
}

function RenderAreaChart(props) {
    let newOpacityProps = {};
    let colours = [];
    let niceNames = {};
    if(props.data.length > 0){
        colours = generateColours(Object.keys(props.data[0]).length);
        Object.keys(props.data[0]).forEach((key) => {
            if(key !== props.GraphX) {
                newOpacityProps[key] = 1;
                niceNames[key] = getNiceName(key);
            }
        });

        //change values to numbers
        let objKeys = Object.keys(props.data[0]);
        for(var i = 0; i < props.data.length; i++) {
            for(var j = 0; j < objKeys.length; j++) {
                if(objKeys[j] !== props.GraphX && !isNaN(props.data[i][objKeys[j]])) {
                    props.data[i][objKeys[j]] = Number(props.data[i][objKeys[j]]);
                }
            }
        }
    }
    const [chartColours, setChartColours] = useState(colours);
    const [opacity, setOpacity] = useState(newOpacityProps);

    const handleMouseEnter = useCallback(
        (o) => {
            const { dataKey } = o;

            setOpacity({ ...opacity, [dataKey]: 0.5 });
        },
        [opacity, setOpacity]
    );

    const handleMouseLeave = useCallback(
        (o) => {
            const { dataKey } = o;
            setOpacity({ ...opacity, [dataKey]: 1 });
        },
        [opacity, setOpacity]
    );

    return(
        <ResponsiveContainer width="99%" aspect={2}>
            <AreaChart
                data={props.data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey={props.GraphX}/>
                <YAxis/>
                <Tooltip/>
                <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}/>
                {Object.keys(props.data[0]).map((datakey, index) => {
                    if(datakey !== props.GraphX) {
                        return (<Area name={niceNames[datakey]} key={index} type="monotone" dataKey={datakey} strokeOpacity={opacity[datakey]} fill={chartColours[index]} stroke={chartColours[index]}/>);
                    }
                    else {
                        return (<></>);
                    }
                })}
            </AreaChart>
        </ResponsiveContainer>
    )
}

function RenderScatterChart(props) {
    let colours = [];
    if(props.data.length > 0){
        colours = generateColours(Object.keys(props.data[0]).length);

        //change values to numbers
        let objKeys = Object.keys(props.data[0]);
        for(var i = 0; i < props.data.length; i++) {
            for(var j = 0; j < objKeys.length; j++) {
                if(!isNaN(props.data[i][objKeys[j]])) {
                    props.data[i][objKeys[j]] = Number(props.data[i][objKeys[j]]);
                }
            }
        }
    }
    const [chartColours, setChartColours] = useState(colours);

    return(
        <ResponsiveContainer width="99%" aspect={2}>
            <ScatterChart
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid/>
                <XAxis type="number" dataKey={props.GraphX}/>
                <YAxis type="number" dataKey={props.GraphY}/>
                <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                <Scatter data={props.data}/>
                {props.data.map((entry, cindex) => (
                    <Cell key={`cell-${cindex}`} fill={chartColours[cindex]} />
                ))}
            </ScatterChart>
        </ResponsiveContainer>
    )
}

function RenderRadarChart(props) {
    let colour = generateColours(Object.keys(props.data[0]).length);
    const [chartColours, setChartColours] = useState(colour);

    //change values to numbers
    let objKeys = Object.keys(props.data[0]);
    for(var i = 0; i < props.data.length; i++) {
        for(var j = 0; j < objKeys.length; j++) {
            if(objKeys[j] !== props.GraphX && !isNaN(props.data[i][objKeys[j]])) {
                props.data[i][objKeys[j]] = Number(props.data[i][objKeys[j]]);
            }
        }
    }

    return(
        <ResponsiveContainer width="99%" aspect={2}>
            <RadarChart
                data={props.data}
                cx="50%"
                cy="50%"
                outerRadius="80%"
            >
                <PolarGrid/>
                <PolarAngleAxis dataKey={props.GraphX} />
                <PolarRadiusAxis />
                <Radar name="Mike" dataKey={props.GraphY} stroke={chartColours[chartColours.length-1]} fill={chartColours[chartColours.length-1]} fillOpacity={0.6} />
            </RadarChart>
        </ResponsiveContainer>
    )
}

export default RenderGraph;