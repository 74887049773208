import React, { memo, useState } from 'react';
import { PopupModalContext } from "../contexts/popupModalContext";
import { Form, Modal, Button, Col, Container, Row} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { LoaderButton } from './common';
import { useLocalStorage } from '@rehooks/local-storage';
import { Auth, API } from 'aws-amplify';
import { validateFieldType } from 'utils';


function Login() {
    let { handlePopupModal } = React.useContext(PopupModalContext);
	let history = useHistory();
	const [form, setForm] = useState({
		"email": '',
		"password": '',
		"name": "",
		"surname": "",
        "idNumber": "",
        "idNumberType": "02",
		"code": "",
        "cellphoneNumber":""
	});
	const [errors, setErrors] = useState({});
	const [isLogingIn, setIsLogingIn] = useState(false);
	const [isRegistering, setIsRegistering] = useState(false);
	const [showConfirmaionModal, setShowConfirmaionModal] = useState(false);
	const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [userDetes, setUserDetails] = useLocalStorage('userDetails', {});
    if(userDetes) { //do nothing, just get react to stop complaining
    }

	const setField = (field, value) => {
		setForm({
			...form,
			[field]: value
		});
		// Check and see if errors exist, and remove them from the error object:
		if (!!errors[field]) {
			setErrors({
				...errors,
				[field]: null
			});
		}
	};

	const getField = (field) => {
		return form[field];
	};

	const validateForm = () => {
		const { email, password } = form;
		const newErrors = {};

		if (!email || email === '') {
			newErrors.email = 'Cannot be blank!';
		}
        if(!validateFieldType(email, "Email")) {
            newErrors.email = 'Email is invalid!';
        }

		if (!password || password === '') {
			newErrors.password = 'Cannot be blank!';
		}

		return newErrors;
	};

	const processUserDetails = async (responseBody, userUuid) => {
	    let	body = responseBody.payload;
		body.data.strCognitoUuid = userUuid;
		localStorage.setItem('roles', body.data.strRole);
		setUserDetails(body);
		
		//workout what ID's there are associated to the user
		let ids = [];
		if(body.data.strIdNumber !== "" && body.data.strIdNumber !== undefined) {
			ids.push(body.data.strIdNumber+":"+body.data.strIdType);
		}
		for(let i = 0; i < body.additionalIds.length; i++) {
			if(body.additionalIds[i].strIDNumber !== "" && body.additionalIds[i].strIDNumber !== undefined) {
				ids.push(body.additionalIds[i].strIDNumber+":"+body.additionalIds[i].strIdType);
			}
		}
		localStorage.setItem('IDNumbers', JSON.stringify(ids));
		setIsLogingIn(false);
		setTimeout(() => {
			history.push('/outstandingfines');
		}, 100);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsLogingIn(true);
		// get our new errors
		const newErrors = validateForm();
		// Conditional logic:
		if (Object.keys(newErrors).length > 0) {
			// We got errors!
			setErrors(newErrors);
			setIsLogingIn(false);
		} else {
			try {
				debugger;
				let response = await Auth.signIn(
					getField('email'),
					getField('password')
				);
				//const jwtToken = response.signInUserSession.accessToken.jwtToken;
				const userUuid = response.username;
				if (response.attributes.email_verified === true) {
					Auth.currentSession();
					let body = await API.get(
						'unity',
						`/user/details?strCognitoUuid=${userUuid}&email=${getField('email')}`
					);
                    
					if (body.result === true) {
                        processUserDetails(body, userUuid);
					} else {
						if(body.payload === "Profile update required") {
							setField('strCognitoUuid', userUuid);
							setShowDetailsModal(true);
						} else if(body.payload === "Cognito ID update required") {
							await API.post('unity', `/updateregistration`, {"body": {"strCognitoUuid": userUuid, "strEmail": getField('email')}})
									 .then(async response =>
										{
											debugger;
											if(response.result === true) {
												body = await API.get(
													'unity',
													`/user/details?strCognitoUuid=${userUuid}&email=${getField('email')}`
												);

												if(body.result == true) {
													processUserDetails(body, userUuid);
												} else {
													setField('strCognitoUuid', userUuid);
													setShowDetailsModal(true);
												}
											} else {
												setIsLogingIn(false);
												handlePopupModal('Oops something went wrong, Please try again later.');
											}
										});
						} else {
							setField('strCognitoUuid', userUuid);
							setShowDetailsModal(true);
						}
					}
				} else {
					setShowConfirmaionModal(true);
				}
			} catch (error) {
				console.log(' Error Loging In...', error);
				setIsLogingIn(false);

                var userInfoResp = await API.get(
					'unity',
					`/user/details?strEmail=${getField("email")}`
				);

				if (!userInfoResp) {
					handlePopupModal('Oops something went wrong, Please try again later.');
				}			
				else if(userInfoResp.payload.data !== undefined && userInfoResp.payload.data.dtLastEdit === '1/1/0001 12:00:00 AM') {
					handlePopupModal('If this is your first time on the new site please reset your password by choosing the forgot password option');
				}
				else if (error.code === 'NotAuthorizedException') {
					handlePopupModal('Incorrect email or password');
				}
                else if(error.code === 'UserNotConfirmedException') {
                    setShowConfirmaionModal(true);
                }
                else {
					handlePopupModal(error.message);
				}
			}
		}
	};

	const handleDialogProceed = async (proceed) => {
		setIsLogingIn(false);
		if (proceed) {
			history.push('/register?verify=' + getField('email'));
		} else {
			setShowConfirmaionModal(false);
			setIsLogingIn(false);
		}
	};

	const validateDetailsForm = () => {
		const {name, surname, idNumber, idNumberType, cellphoneNumber} = form;
		const newErrors = {};

		if(!name || name === '') {
			newErrors.name = 'Cannot be blank!';
		}
        else if(!validateFieldType(name, "Name")) {
            newErrors.name = 'Must be valid!';
        }

		if(!surname || surname === '') {
			newErrors.surname = 'Cannot be blank!';
		}
        else if(!validateFieldType(surname, "Name")) {
            newErrors.surname = 'Must be valid!';
        }

        if(!cellphoneNumber || cellphoneNumber === '') {
			newErrors.cellphoneNumber = 'Cannot be blank!';
		}
        else if(!validateFieldType(cellphoneNumber, "Phone")) {
            newErrors.cellphoneNumber = 'Must be valid!';
        }

        if(!idNumber || idNumber === '') {
			newErrors.idNumber = 'Cannot be blank!';
		}
        else if(!validateFieldType(idNumber, idNumberType)) {
            newErrors.idNumber = 'Must be valid!';
        }

		return newErrors;
	}

	const handleDetailsProceed = async (e) => {
		setIsRegistering(true);
		// get our new errors
		const newErrors = validateDetailsForm();
		// Conditional logic:
		if (Object.keys(newErrors).length > 0) {
			// We got errors!
			setErrors(newErrors);
			setIsRegistering(false);
		} else {
			let newUserData = {
				body: {
					"strCognitoUuid": getField('strCognitoUuid'),
					"strName": getField('name'),
					"strSurname": getField('surname'),
					"strEmail": getField('email'),
                    "strIdType": getField('idNumberType'),
                    "strIdNumber": getField('idNumber'),
                    "strMobileNumber": getField('cellphoneNumber')
                }
			};

			try {
				//add a new entry
				let body = await API.post('unity', `/register`, newUserData);
				if (body.result === true) {
                    if(body.payload === "User Email Already exists"){
                        await API.post('unity', `/updateregistration`, {"body": {"strCognitoUuid":getField('strCognitoUuid'), "strEmail": getField('email')}});
                    }
					setIsRegistering(false);
					setShowDetailsModal(false);
					setIsLogingIn(false);
                    handleSubmit(e);
				} else {
					setIsRegistering(false);
					handlePopupModal('Unable to update user details');
				}
			} catch (error) {
				console.log('Error updating user details...', error);
				setIsRegistering(false);
				handlePopupModal(error.message);
			}
		}
	};

	return (
		<>
			<Modal
				show={showConfirmaionModal}
				onHide={() => handleDialogProceed(false)}
				backdrop={true}
				keyboard={true}
			>
				<Modal.Header>
					<Modal.Title>Error</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Your email address has not been verified. Please select yes to
					redirect to the code verification page
				</Modal.Body>
				<Modal.Footer>
					<Button
						className="button-l"
						bsstyle="primary"
						onClick={() => handleDialogProceed(true)}
					>
						Yes
					</Button>
					<Button onClick={() => handleDialogProceed(false)}>No</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={showDetailsModal}
				onHide={() => handleDetailsProceed()}
				backdrop={true}
				keyboard={true}
			>
				<Modal.Header>
					<Modal.Title>User Details</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Some Details are missing, please fill them in below
					<Form className="form">
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formBasicName" >
                                    <Form.Control
                                        type="text" 
                                        placeholder="Name" 
                                        value={getField('name')} 
                                        onChange={e => setField('name', e.target.value)}
                                        isinvalid={ !!errors.name ? "true" : "false"}
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        { errors.name }
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formBasicSurname" >
                                    <Form.Control
                                        type="text" 
                                        placeholder="Surname" 
                                        value={getField('surname')} 
                                        onChange={e => setField('surname', e.target.value)}
                                        isinvalid={ !!errors.surname ? "true" : "false" }
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        { errors.surname }
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formBasicIDNumberType" >
                                    <select
                                        className="outstandingScreenDropDowns"
                                        value={getField('idNumberType')} 
                                        onChange={e => setField('idNumberType', e.target.value)}
                                        isinvalid={ !!errors.idNumberType  ? "true" : "false" }
                                    >
                                        <option key="02" value="02">
                                            RSA ID Number
                                        </option>
                                        <option key="03" value="03">
                                            Foreign ID Number
                                        </option>
                                        <option key="04" value="04">
                                            Business Registration
                                        </option>
                                    </select>
                                    <Form.Control.Feedback type='invalid'>
                                        { errors.idNumberType }
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>                            
                            <Col md={6}>
                                <Form.Group controlId="formBasicIDNumber" >
                                    <Form.Control
                                        type="text" 
                                        placeholder="ID Number" 
                                        value={getField('idNumber')} 
                                        onChange={e => setField('idNumber', e.target.value)}
                                        isinvalid={ !!errors.idNumber  ? "true" : "false" }
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        { errors.idNumber }
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formBasicMobileNumber" >
                                    <Form.Control
                                        type="text" 
                                        placeholder="Cellphone Number" 
                                        value={getField('cellphoneNumber')} 
                                        onChange={e => setField('cellphoneNumber', e.target.value)}
                                        isinvalid={ !!errors.cellphoneNumber  ? "true" : "false" }
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        { errors.cellphoneNumber }
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
				</Modal.Body>
				<Modal.Footer>
                    <Form className="form">
                        <Row>
                            <Col md={{span:4, offset:4}}>
                                <LoaderButton
                                    block
                                    type="submit"
                                    bssize="large"
                                    isLoading={isRegistering}
                                    onClick={handleDetailsProceed}
                                >
                                    Submit
                                </LoaderButton>
                            </Col>
                        </Row>
                    </Form>
				</Modal.Footer>
			</Modal>
			<div className="center-to-screen pt-3">
				<Container>
					<Row>
						<Col>
							<Form className="form">
								<Row>
									<Col md={{ span: 6, offset: 3}}>
										<h1>Login</h1>
										<Form.Group controlId="formBasicEmail">
											<Form.Control
												type="email"
												placeholder="Email"
												value={getField('email')}
												onChange={(e) => setField('email', e.target.value)}
												isinvalid={!!errors.email ? "true" : "false"}
											/>
											<Form.Control.Feedback type="invalid">
												{errors.email}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
								</Row>

								<Row>
									<Col md={{ span: 6, offset: 3}}>
										<Form.Group controlId="formBasicPassword">
											<Form.Control
												type="password"
												placeholder="Password"
												value={getField('password')}
												onChange={(e) => setField('password', e.target.value)}
												isinvalid={!!errors.password ? "true" : "false"}
											/>
											<Form.Control.Feedback type="invalid">
												{errors.password}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
								</Row>

								<Row>
									<Col md={{ span: 2, offset: 5}}>
										<LoaderButton
										block
										type="submit"
										bssize="large"
										isLoading={isLogingIn}
										onClick={handleSubmit}
										// onClick={(e)=>{e.preventDefault(); setShowDetailsModal(true);}}
										>
										Login
										</LoaderButton>
									</Col>
								</Row>

								<Row>
									<Col md={{ span: 6, offset: 3}} style={{marginTop:'20px'}}>
										<div className="text-right">
											<Link to="/forgot-password">Forgot Password</Link>
											&nbsp;&nbsp;&nbsp;
											<Link to="/register">Register</Link>
										</div>
									</Col>
								</Row>
							</Form>
						</Col>
					</Row>
				</Container>
			</div>
			
		</>
	);
}

export default memo(Login);
