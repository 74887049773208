import React, { memo, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Form, Spinner } from 'react-bootstrap';
import { PopupModalContext } from "../contexts/popupModalContext";
import unityRequests from './unityRequests';
import { isLoggedIn, getUserDetails, validateFieldType } from 'utils';
import search_icon from 'assets/img/search.svg';



function LandingPage() {
    let { handlePopupModal } = React.useContext(PopupModalContext);
    const [form, setForm] = useState({
        infringementNumberValue: ''
    });
    let history = useHistory();
    const [errors, setErrors] = useState({});
    const [showPreloader, setShowPreLoader] = useState(false);

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        });
        // Check and see if errors exist, and remove them from the error object:
        if (!!errors[field]) {
            setErrors({
                ...errors,
                [field]: null
            });
        }
    };

    const getField = (field) => {
        return form[field];
    };

    function validation() {
        const newErrors = {};

        if (!getField('infringementNumberValue') || getField('infringementNumberValue') === '') {
            newErrors.infringementNumberValue = 'Cannot be blank!';
        }
        else if (!validateFieldType(getField('infringementNumberValue'), "Text")) {
            newErrors.infringementNumberValue = 'Must be valid!';
        }

        return newErrors;
    }

    async function searchFines(e) {
        e.preventDefault();
        setShowPreLoader(true);

        const newErrors = validation();

        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors);
        } else {

            let userID = "";

            if (isLoggedIn()) {
                userID = getUserDetails().data.UserID;
            }
            else {
                userID = "GUEST";
            }

            let cleanedInfringementNumberValue = "";

            let finesRequest = {};
            let idSearch = false;

            if (getField('infringementNumberValue').includes("/") || getField('infringementNumberValue').length > 13) {
                idSearch = false;
                if (getField('infringementNumberValue').includes("-")) {
                    cleanedInfringementNumberValue = getField('infringementNumberValue').replace(/-/g, "");
                } else {
                    cleanedInfringementNumberValue = getField('infringementNumberValue');
                }

                finesRequest = await unityRequests(
                    `/getfinesearch?Id=${userID}&noticeNumber=${cleanedInfringementNumberValue}`,
                    {},
                    'get'
                );
            } else {
                debugger;
                idSearch = true;
                let idNumber = getField('infringementNumberValue');
                if (!idNumber || idNumber === '') {
                    newErrors.idNumber = 'Cannot be blank!';
                }
                else if (!validateFieldType(idNumber, "02")) {
                    newErrors.idNumber = 'Must be valid!';
                }

                let params = [];

                params.push('pageSize=' + 100);
                params.push('pageStart=' + 1);
                params.push('pageEnd=' + 1);

                params.push('strIdNumber=' + idNumber);
                params.push('strIdType=' + "02");

                params = params.join("&");

                finesRequest = await unityRequests(
                    `/getpaginatedfines?${params}`,
                    {},
                    'get'
                );

                if (finesRequest.status === 500 || finesRequest.status === 504 || finesRequest.status === 400)//if error switch preloader off
                {
                    console.log("error status code: " + finesRequest.status)
                    setShowPreLoader(false);
                }

                console.log(finesRequest);
            }
            if (finesRequest.result === true) {
                debugger;
                if (typeof finesRequest.payload === "string" && finesRequest.payload.includes("is incorrect")) {
                    handlePopupModal("Invalid fine number");
                }
                else {
                    let apiStatus = [];

                    let eNatisdata = finesRequest.payload.eNatis;
                    let iForceData = finesRequest.payload.iForce;

                    let finedata = {};
                    let noFinesFound = 0;
                    if (iForceData === "Third party did not respond") {
                        apiStatus.push("iForce did not respond");
                    }
                    else if (typeof iForceData === 'string' && iForceData.includes("No fines found")) {
                        apiStatus.push("could not find any relevant fines on iForce");
                        noFinesFound++;
                    }
                    else {
                        if(iForceData!=undefined){
                        finedata = iForceData.fines[0];
                        }
                    }

                    if (eNatisdata === "Third party did not respond") {
                        apiStatus.push("eNatis did not respond");
                    }
                    else if (typeof eNatisdata === 'string' && eNatisdata.includes("No fines found")) {
                        apiStatus.push("could not find any relevant fines on eNatis");
                        noFinesFound++;
                    }
                    else {
                        if(eNatisdata!=undefined){
                        finedata = { ...eNatisdata[0] };
                        }
                    }

                    if (noFinesFound === 2) {
                        handlePopupModal("Fine not found");
                    }

                    if (apiStatus.length > 0) {
                        apiStatus[0] = apiStatus[0].replace("could", "Could");
                        apiStatus = apiStatus.join(" and ");
                        handlePopupModal(apiStatus);
                    }

                    let SelectedFineKeys = Object.keys(finedata);
                    setShowPreLoader(false);
                    if (!idSearch) {
                        if (SelectedFineKeys.includes('infringementNoticeNumber')) {
                            history.push('/aartofinedetails?id=' + finedata.infringementNoticeNumber);
                        } else if (SelectedFineKeys.includes('noticeNumber')) {
                            history.push('/cpafinedetails?id=' + finedata.noticeNumber);
                        }
                    } else {
                        history.push('/quickidfinesearch?idno=' + getField('infringementNumberValue'));
                    }
                }
            }
            else {
                setShowPreLoader(false);
                handlePopupModal("There was a problem trying to fetch fines");
            }
        }
        setShowPreLoader(false);
    }


    return (
        <>
            <div className="home-banner-image">
                <div className="row home-banner-text">
                    <h1 style={{ fontSize: "40px", textShadow: '1px 4px 8px #005276', lineHeight: '59px', fontWeight: '700' }}>Pay your fines, and get back to the joy of driving.</h1>
                </div>
                <div className="row home-banner-search-box">
                    <Form id="searchBox" onSubmit={searchFines}>
                        <Form.Group className="mb-3">

                            {showPreloader ? (
                                <div>
                                    <Spinner
                                        animation="border"
                                        role="status"
                                        variant="warning"
                                        style={{ display: 'block', margin: '0 auto' }}
                                    >
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>
                            ) : (
                                <>
                                    <div className='left-inner-addon'>
                                        <Form.Control
                                            type="text"
                                            id="strNoticeNumber"
                                            placeholder={"Search by ID or Notice Number"}
                                            size="lg"
                                            className="home-banner-search-box-textfield"
                                            style={{ backgroundColor: 'rgba(255,212,0,0.75)', color: '#000', borderRadius: '40px', textAlign: 'center', borderColor: 'rgba(255,212,0,0.75)' }}
                                            onChange={(e) => setField('infringementNumberValue', e.target.value)}
                                            onBlur={(e) => setField('infringementNumberValue', e.target.value)}
                                            value={getField('infringementNumberValue')}
                                        />
                                        <img src={search_icon} alt="search icon" />
                                    </div>

                                    <Form.Control.Feedback type='invalid'>
                                        {errors.noticeNumberValue}
                                    </Form.Control.Feedback>
                                    <Form.Text className="text-white">
                                        {/* <Link to="/quickfinesearch" style={{textDecoration:'none', color:'#fff'}}>FIND YOUR FINES</Link> */}
                                        <span onClick={(e) => { searchFines(e) }}>FIND YOUR FINES</span>
                                    </Form.Text>
                                </>
                            )}
                        </Form.Group>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default memo(LandingPage);
