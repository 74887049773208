import React, { memo, useState, useEffect } from 'react';
import { ExportableTable, Graphs } from './common';
import { PopupModalContext } from "../contexts/popupModalContext";
import { Form, Container, Row, Col, Jumbotron, Spinner, Tabs, Tab } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocalStorage } from '@rehooks/local-storage';
import unityRequests from './unityRequests';
import { getUserDetails } from 'utils';

function AdminDashoard() {
  let currDate = new Date();
  let priorDate = new Date();
  const month = priorDate.getMonth();
  priorDate.setMonth(priorDate.getMonth() - 1);

  while (priorDate.getMonth() === month) {
    priorDate.setDate(priorDate.getDate() - 1);
  }

  let { handlePopupModal } = React.useContext(PopupModalContext);
  const [showPreloader, setShowPreLoader] = useState(false);
  const [fromDate, setFromDate] = useState(priorDate);
  const [toDate, setToDate] = useState(currDate);
  const [tablesArr, setTablesArr] = useState([]);
  const [countersTabArr, setCountersTabArr] = useState([]);
  const [graphsArr, setGraphsArr] = useState([]);
  const [financeReportArr, setfinanceReport] = useState([]);
  const [currentTab, setcurrentTab] = useState("graphs");

  const [backFilters, setbackFilters] = useLocalStorage(
    'backFilters',
    {
      "financeIssuingAuthFilter": "ALL",
      "issuingAuthFilter": "ALL"
    }
  );

  const [financeIssuingAuthFilter, setFinanceIssuingAuthFilter] = useState("ALL");
  const [financeIssuingAuthList, setfinanceIssuingAuthList] = useLocalStorage(
  	'financeIssuingAuthList',
  	[]
  );

  useEffect(() => {
    var onLoad = async () => {
      setShowPreLoader(true);

      let financeReportParams = [];
      if (financeIssuingAuthFilter !== "ALL") {
        financeReportParams.push('issuingAuthority=' + financeIssuingAuthFilter);
      }
      else {
        financeReportParams.push('issuingAuthority=' + financeIssuingAuthFilter);
      }

      //get the shematics of the graphs
      let params = [];
      params.push("userId=" + getUserDetails().data.UserID);

      if (fromDate !== false) {
        params.push("startDate=" + fromDate.toISOString().substr(0, 10));
      }
      if (toDate !== false) {
        params.push("endDate=" + toDate.toISOString().substr(0, 10));
      }

      let graphSchematics = await unityRequests(
        `/getgraphs?${params.join("&")}`,
        {},
        'get'
      );

      //console.log(graphSchematics.payload);
      let tables = [];
      let countersTab = [];
      let graphsInside = [];
      let financeTab = [];

      graphSchematics.payload.forEach((graph) => {
        if (graph.GraphType === "Table") {
          if (graph.GraphX !== "" && graph.GraphX !== "N/A") {
            try {
              graph.GraphX = JSON.parse(graph.GraphX);
            }
            catch (e) {
              console.log("Invalid table config: '" + graph.GraphX + "'");
              graph.GraphX = {};
            }
          }
          tables.push(graph);
        }
        else if (graph.GraphType === "CounterTab") {
          countersTab.push(graph);
        }
        else if (graph.GraphType === "LineMarkGraph" || graph.GraphType === "VerticalBarGraph" || graph.GraphType === "RadialBarGraph" || graph.GraphType === "StackGraph" || graph.GraphType === "PieGraph" || graph.GraphType === "AreaGraph" || graph.GraphType === "ScatterGraph" || graph.GraphType === "RadarGraph") {
          graphsInside.push(graph);
        }
      });

      setTablesArr(tables);
      setCountersTabArr(countersTab);
      setGraphsArr(graphsInside);

      if (graphSchematics.result !== true) {
        handlePopupModal("There was an error fetching the graphs!")
      }

      financeReportParams.push("startSettlementDate=" + fromDate.toISOString().substr(0, 10));
      financeReportParams.push("endSettlementDate=" + toDate.toISOString().substr(0, 10));

      let financeReportData = await unityRequests(
        `/reports?${financeReportParams.join("&")}`,
        {},
        'get'
      );

      if (financeReportData.payload !== undefined && financeReportData.payload !== "No settings found"){
        if (financeReportData.payload.forEach !== undefined)
        {
          financeReportData.payload.forEach((item) => {
            const isFound = financeIssuingAuthList.some(element => {
              if (element.issuingAuthorityCode === item.IssuingAuthority) {
                return true;
              }
              else{
                return false;
              }
            });

            if(!isFound){
              financeIssuingAuthList.push({ "issuingAuthorityCode": item.IssuingAuthority, "issuingAuthorityDesc": item.IssuingAuthority });
            }

          });
    
          setfinanceIssuingAuthList(financeIssuingAuthList);
          setfinanceReport(financeReportData.payload);
        }
      }

      setShowPreLoader(false);
    }

    onLoad();
  }, [financeIssuingAuthFilter, fromDate, toDate]);



  let tableConfig = {
    "isSearchable": true,
    "isSortable": true,
    "isExportable": true,
    "isPaginated": true,
    "topFilters": () => (
      <>
        <Col md={3}>
          <label htmlFor="financeIssuingAuthDropDown">
            Issuing Authority:&nbsp;
            <select
              id="financeIssuingAuthDropDown"
              className="financeIssuingAuthDropDown adminDashScreenDropDowns"
              value={financeIssuingAuthFilter}
              onChange={(e) => setFinanceIssuingAuthFilter(e.target.value)}
              onBlur={(e) => setFinanceIssuingAuthFilter(e.target.value)}
            >
              <option value="ALL">All</option>
              {financeIssuingAuthList.map((finIssuingAuth) => {
                return (
                  <option key={finIssuingAuth.issuingAuthorityCode} value={finIssuingAuth.issuingAuthorityDesc}>
                    {finIssuingAuth.issuingAuthorityDesc}
                  </option>
                );
              })}
            </select>
          </label>
        </Col>
      </>
    )
  }


  function displayGraph(graph, currentGraphNo) {
    return (
      <Col md={6} key={currentGraphNo}>
        <Jumbotron fluid
          style={{ padding: '20px' }}
        >
          <h5 style={{ textTransform: 'uppercase' }}>{graph.GraphTitle}</h5>
          <Graphs type={graph.GraphType} data={graph.Data} GraphX={graph.GraphX} GraphY={graph.GraphY} />
        </Jumbotron>
      </Col>
    );
  }

  function handleToDateChange(newDate) {
    if (new Date(newDate) > new Date(fromDate)) {
      setToDate(newDate);
    }
    else {
      handlePopupModal("End Date must be after Start Date");
    }
  }

  function handleFromDateChange(newDate) {
    if (new Date(newDate) < new Date(toDate)) {
      setFromDate(newDate);
    }
    else {
      handlePopupModal("Start Date must be before End Date");
    }
  }

  let numRows = Math.floor(graphsArr.length / 2);
  if (numRows !== graphsArr.length / 2) {
    numRows = numRows + 1;
  }

  let displayGraphs = [];
  let currentGraphNo = -1;
  for (let i = 0; i < numRows; i++) {
    let hold = [];
    if (graphsArr[currentGraphNo + 1]) {
      currentGraphNo++;
      hold.push(displayGraph(graphsArr[currentGraphNo], currentGraphNo))
    }
    if (graphsArr[currentGraphNo + 1]) {
      currentGraphNo++;
      hold.push(displayGraph(graphsArr[currentGraphNo], currentGraphNo))
    }
    displayGraphs.push(hold);
  }

  return (
    <div className="center-to-screen mt-5 mb-5">
        <Container>
          <Row>
            <Col>
              <h1 className="form-header">Admin Dashboard</h1>
            </Col>
          </Row>
          <Row>
            <Form className="form">
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <label style={{ color: '#000' }}>Start Date:</label>
                    <DatePicker
                      id="startDate"
                      className="form-control"
                      selected={fromDate}
                      onChange={(date) => handleFromDateChange(date)}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <label style={{ color: '#000' }}>End Date:</label>
                    <DatePicker
                      id="endDate"
                      className="form-control"
                      selected={toDate}
                      onChange={(date) => handleToDateChange(date)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Row>
            <Tabs defaultActiveKey="graphs" activeKey={currentTab} onSelect={(newTab) => setcurrentTab(newTab)} id="adminDashboardTabs" className="mb-3">
                <Tab eventKey="graphs" title="Graphs">
                    {showPreloader ? (
                        <div>
                            <Spinner
                                animation="border"
                                role="status"
                                style={{ display: 'block', margin: '0 auto' }}
                            >
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    ) : (<>
                        {graphsArr.length <= 0 ? (
                            <Row>
                            <Col style={{ textAlign: 'center' }}>
                                <h1>No Data Available</h1>
                            </Col>
                            </Row>
                        ) : (
                            <>
                            <Row>
                                {countersTabArr.length > 0 ? (
                                <>
                                    {countersTabArr.map(item => {
                                    if (typeof item.Data !== "object") {
                                        return (
                                        <Col md={4}>
                                            <Jumbotron
                                            fluid
                                            style={{ padding: '20px' }}
                                            >
                                            <h5 style={{ textTransform: 'uppercase' }}>{item.GraphTitle}</h5>
                                            <h1>{item.Data}</h1>
                                            </Jumbotron>
                                        </Col>
                                        );
                                    }
                                    else {
                                        let objKeys = Object.keys(item.Data);
                                        return (
                                        <Col md={objKeys.length < 5 ? 4 : 8}>
                                            <Jumbotron
                                            fluid
                                            style={{ padding: '20px' }}
                                            >
                                            <Row><h5 style={{ textTransform: 'uppercase' }}>{item.GraphTitle}</h5></Row>
                                            <Row>
                                                {objKeys.map((key) => {
                                                return (<Col>
                                                    <h5 style={{ textTransform: 'uppercase' }}>{key}</h5>
                                                </Col>);
                                                })
                                                }
                                            </Row>
                                            <Row>
                                                {objKeys.map((key) => {
                                                return (<Col>
                                                    <h1 style={{ marginTop: "14px", marginBottom: "14px" }}>{item.Data[key]}</h1>
                                                </Col>);
                                                })
                                                }
                                            </Row>
                                            </Jumbotron>
                                        </Col>
                                        );
                                    }
                                    })}
                                </>
                                ) : (
                                <Col style={{ textAlign: 'center' }}>
                                    <h1>No Counters Available</h1>
                                </Col>
                                )}
                            </Row>
                            {displayGraphs.length > 0 ? (
                                <>
                                {displayGraphs.map((graphRow, rowNo) => {
                                    return (<Row key={rowNo}>
                                    {graphRow[0] ? graphRow[0] : <></>}
                                    {graphRow[1] ? graphRow[1] : <></>}
                                    </Row>
                                    )
                                })}
                                </>
                            ) : (
                                <Row>
                                <Col style={{ textAlign: 'center' }}>
                                    <h1>No Graphs Available</h1>
                                </Col>
                                </Row>
                            )}
                            </>
                        )}
                    </>)}
                </Tab>
                <Tab eventKey="reports" title="Reports">
                    {showPreloader ? (
                        <div>
                            <Spinner
                                animation="border"
                                role="status"
                                style={{ display: 'block', margin: '0 auto' }}
                            >
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    ) : (<>
                    <Row>
                        {tablesArr.length > 0 ? (
                        <>
                            {tablesArr.map(item => {
                            return (
                                <Col md={12} key={item.GraphTitle} className="mb-5">
                                <h5 style={{ textTransform: 'uppercase' }}>{item.GraphTitle}</h5>
                                <ExportableTable title={item.GraphTitle} config={item.GraphX} data={item.Data} />
                                </Col>
                            );
                            })}
                        </>
                        ) : (
                        <Col style={{ textAlign: 'center' }}>
                            <h1>No Reports Available</h1>
                        </Col>
                        )}
                    </Row>
                    </>)}
                </Tab>
                <Tab eventKey="financeReports" title="Finance Reports">
                    {showPreloader ? (
                        <div>
                            <Spinner
                                animation="border"
                                role="status"
                                style={{ display: 'block', margin: '0 auto' }}
                            >
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    ) : (<>
                            <Row>
                                <Col md={12} key={"dddd"} className="mb-5">
                                <h5 style={{ textTransform: 'uppercase' }}>{"Settled Fines"}</h5>
                                <ExportableTable title="FinanceReport" config={tableConfig} data={financeReportArr} />
                                </Col>
                            </Row>
                    </>)}
                </Tab>
            </Tabs>
        </Container>
    </div>
  );
}

export default memo(AdminDashoard);