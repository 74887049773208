import React, { memo, useState, useEffect } from 'react';
import { PopupModalContext } from "../contexts/popupModalContext";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation, useHistory } from "react-router-dom";
import { Container, Row, Col, Spinner, Jumbotron } from 'react-bootstrap';
import { ExportableTable, LoaderButton } from './common';
import { FaShoppingCart } from 'react-icons/fa';
import { AiFillInfoCircle } from 'react-icons/ai';
import { useLocalStorage } from '@rehooks/local-storage';
import unityRequests from './unityRequests';
import { getUserDetails, isLoggedIn } from 'utils';

function OutstandingFines() {
    let { handlePopupModal } = React.useContext(PopupModalContext);
	let history = useHistory();
    const [backFilters, setbackFilters] = useLocalStorage(
		'backFilters',
		{
            "IDNumberFilter": "ALL",
            "issuingAuthFilter": "ALL",
            "tableSearch": ""
        }
	);
	const [showPreloader, setShowPreLoader] = useState(false);
	const [isAddingFines, setIsAddingFines] = useState(false);
	const [IDNumberFilter, setIDNumberFilter] = useState(backFilters.IDNumberFilter);
	const [issuingAuthFilter, setIssuingAuthFilter] = useState(backFilters.issuingAuthFilter);
    const [issuingAuthList, setIssuingAuthList] = useLocalStorage(
		'issuingAuthList',
		[]
	);
    const [tableSearch, setTableSearch] = useState(backFilters.tableSearch);
	const [persistedCartItems, setPersistedCartItems] = useLocalStorage(
		'cartItems',
		{}
	);
	const [outstandingFines, setOutstandingFines] = useLocalStorage(
		'outstandingFines',
		[]
	);

    const [perPage, setPerPage] = useState(100);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageEnd, setPageEnd] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [isServerSideEnabled, setServerSideEnabled] = useState(false);

    let urlLocation = useLocation();
    let fineID = false;
    let priorPage = false;

    if (urlLocation.search.length > 0) {
        let urlparams = urlLocation.search.substr(1).split('&');
        urlparams.forEach((item) => {
            let hold = item.split("=");
            if (hold[0] === 'idno') {
                fineID = hold[1];
            }
            else if (hold[0] === 'priorPage') {
                priorPage = hold[1];
            }
        })
    }

   const onLoad = async (currentPageStart, currentPageEnd, currentPageSize = perPage) => {
        try {
            setShowPreLoader(true);
debugger;
            let params = [];

            params.push('pageSize=' + currentPageSize);
            params.push('pageStart=' + currentPageStart);
            params.push('pageEnd=' + currentPageEnd);

            if(IDNumberFilter !== 'ALL') {
                let id = IDNumberFilter.split(":") //id:idType
                params.push('strIdNumber='+id[0]);
                params.push('strIdType='+id[1]);
            } else if(fineID !== false) {
                params.push('strIdNumber='+ fineID);
                params.push('strIdType='+ "02");
            }
            else {
                params.push('userId='+getUserDetails().data.UserID);
            }

            params.push('strIssuingAuthority='+issuingAuthFilter);

            params = params.join("&");

            let outstandingFinesRequest = await unityRequests(
                `/getpaginatedfines?${params}`,
                {},
                'get'
            );

            if(outstandingFinesRequest.status === 500 || outstandingFinesRequest.status === 504 || outstandingFinesRequest.status === 400)//if error switch preloader off
            {
                console.log("error status code: "+ outstandingFinesRequest.status)
                setShowPreLoader(false);
            }

            var out = [];

            if(outstandingFinesRequest.result !== false) {
                if(typeof outstandingFinesRequest.payload === "string" && outstandingFinesRequest.payload === "No fines found") {
                    //do nothing
                }
                else {
                    let eNatisdata = outstandingFinesRequest.payload.eNatis;
                    let iForceData = outstandingFinesRequest.payload.iForce.fines;

                    let apiStatus = [];
                    if(eNatisdata === undefined || eNatisdata === "Third party did not respond"){
                        apiStatus.push("As eNatis did not respond, not all infringements may be currently available");
                    }
                    else if(typeof eNatisdata === 'string' && eNatisdata.includes("No fines found")) {
                        //do nothing
                    }
                    else{
                        eNatisdata = eNatisdata.map((row) => {
                            row.api = "eNatis";
                            return row;
                        })
                        out = [...out, ...eNatisdata];
                    }

                    if(iForceData === undefined || iForceData === "Third party did not respond"){
                        apiStatus.push("As iForce did not respond, not all infringements may be currently available");
                    }
                    else if(typeof iForceData === 'string' && iForceData.includes("No fines found")) {
                        //do nothing
                    }
                    else{
                        if(iForceData.length > 0){
                            setTotalRows(outstandingFinesRequest.payload.iForce.totalCases);
                            if(outstandingFinesRequest.payload.iForce.totalCases > perPage) {
                                handlePopupModal(`Please note: The queried entity(ies) has more than ${perPage} outstanding fines. The list below displays the first ${perPage} outstanding fines and payment can be made on these. Once paid and updated the next set of fines can be queried and paid. Also note that the update process might be delayed as Paymyfines is reliant on external systems to process these payments, which can take up to 4 hours`);
                            }
                            iForceData = iForceData.map((row) => {
                                row.api = "iForce";
                                return row;
                            })
                            out = [...out, ...iForceData];
                        }else{
                            setTotalRows(0);
                            apiStatus.push("As iForce did not respond, not all infringements may be currently available");
                        }
                    }

                    if(apiStatus.length > 0) {
                        apiStatus = apiStatus.join(", and ");
                        handlePopupModal(apiStatus);
                    }
                }
                
            }else {
                handlePopupModal("An error occurred while attempting to obtain the fine information from the source system. This can be due to connectivity or other source system issues. Please retry the query. If the issue persists, please reach out to us through the customer service email address.");
            }

            //loop through the responses and normalize the fields
            let authlist = [...issuingAuthList];
            out = out.map((item) => {
                let newOut = {};
                if(item.api === "iForce"){

                    newOut.original = {...item};

                    if( item.idNumber === '' ||
                        item.idNumber === null ||
                        item.idNumber === undefined
                    ) {
                        newOut.id_number = "N/A";
                    }
                    else {
                        newOut.id_number = item.idNumber;
                    }

                    newOut.infringement_number = item.noticeNumber
                    let infringementDate = item.offenceDate.substring(0, 10);
                    infringementDate = infringementDate.replace(/-/g, '/');
                    newOut.infringement_date = infringementDate;
                    newOut.issuing_authority_code = item.issuingAuthorityCode;
                    newOut.issuing_authority = item.districtName;
                    newOut.vehicle_registration = item.vehicleRegistrationNumber;
                    newOut.status = item.phase;
                    newOut.amount = item.totalAmountDue;
                    newOut.demerit_points = "N/A";

                }else{

                    newOut.original = {...item};

                    if(item.IDNumber === '' ||
                        item.IDNumber === null ||
                        item.IDNumber === undefined
                    ) {
                        newOut.id_number = "N/A";
                    }
                    else {
                        newOut.id_number = item.IDNumber;
                    }

                    newOut.infringement_number = item.infringementNoticeNumber
                    let infringementDate = item.date.substring(0, 10);
                    infringementDate = infringementDate.replace(/-/g, '/');
                    newOut.infringement_date = infringementDate;

                    newOut.issuing_authority_code = item.location.issAuthority.code;
                    newOut.issuing_authority = item.location.issAuthority.description;
                    newOut.vehicle_registration = item.vehicle.licenseNumber;
                    if(newOut.original.paymentNotAllowedCode !== 3 && item.feesList.debt.length > 0 ) {
                        newOut.status = item.feesList.debt[0].debtType;
                        newOut.amount = 0;
                        for (var i = 0; i < item.feesList.debt.length; i++) {
                            newOut.amount = newOut.amount + Number(item.feesList.debt[i].debtAmt);
                        }
                    }
                    else {
                        newOut.original.customerPaymentAllowed = false;
                        newOut.status = "N/A";
                        newOut.amount = "N/A";
                    }
                    newOut.demerit_points = 0;
                    for(var i = 0; i < item.charge.length; i++) {
                        newOut.demerit_points = newOut.demerit_points + Number(item.charge[i].demeritPoints);
                    }
                        
                }

                //build issueing Authority list

                const filterItems = authlist.filter((item) => {
                    return item.issuingAuthorityCode == newOut.issuing_authority_code;
                });
                        
                if(filterItems.length == 0){
                    var issObj = {
                        issuingAuthorityCode: newOut.issuing_authority_code,
                        districtName: newOut.issuing_authority };
                        
                        authlist.push(issObj);
                }
               
                return newOut;
            })
            
            setIssuingAuthList(authlist);
            setOutstandingFines(out);
            setShowPreLoader(false);
        } catch (e) {
            console.log(e);
            setShowPreLoader(false);
            handlePopupModal("An error occurred while attempting to obtain the fine information from the source system. This can be due to connectivity or other source system issues. Please retry the query. If the issue persists, please reach out to us through the customer service email address.");
        }
    };

	useEffect(() => {
	    onLoad(1, 1);
	}, [IDNumberFilter, issuingAuthFilter]);

	function addToCart(item) {

		if (item) {
			let cartItems = { ...persistedCartItems }; //important to not make changes to the persistedCartItems itself else it wont trigger updates
			let fineID = item.infringement_number;
			if (!Object.keys(cartItems).includes(fineID)) {
				cartItems[fineID] = item.original;
				setPersistedCartItems(cartItems);
			} else {
				return false;
			}
		}
		return false;
	}

	function checkIfCartItemExist(item) {
		if (item) {
			let cartItems = { ...persistedCartItems }; //important to not make changes to the persistedCartItems itself else it wont trigger updates
			let fineID = item.infringement_number;
            
			if(item.original.customerPaymentAllowed === false) {
                return true;
            }

			if (Object.keys(cartItems).includes(fineID)) {
				return true;
			} else {
				return false;
			}
		}
		return false;
	}

	function handleInfoClick(item) {
        setbackFilters(
            {
                "IDNumberFilter": IDNumberFilter,
                "issuingAuthFilter": issuingAuthFilter,
                "tableSearch": tableSearch
            }
        )
        debugger;
        let urlparams = urlLocation.search;

		if(item.original.api === "eNatis") {
			history.push('/aartofinedetails?id='+item.infringement_number+"&priorPage=" + urlLocation.pathname + urlLocation.search);
		}
        else if(item.original.api === "iForce") {
			history.push('/cpafinedetails?id='+item.infringement_number+"&priorPage=" + urlLocation.pathname + urlLocation.search);
		}
	}

    function handleAllFinesToCart(e){
        e.preventDefault();
        setIsAddingFines(true);

        let cartItems = { ...persistedCartItems };
        for(var i=0; i<outstandingFines.length;i++) {
            if (!Object.keys(cartItems).includes(outstandingFines[i].infringement_number)) {
                if(outstandingFines[i].original.customerPaymentAllowed === true) {
                    cartItems[outstandingFines[i].infringement_number] = outstandingFines[i].original;
                }
            }
        }
        if(Object.keys(cartItems).length > 0) {
            setPersistedCartItems(cartItems);
            history.push("/Cart");
        }

        setIsAddingFines(false);
    }

    function handleClearFilterClick(e){
        e.preventDefault();
        setIDNumberFilter("ALL");
        setIssuingAuthFilter("ALL");
    }

	let sumOfFines = 0;
    let numOfFines = 0;
	outstandingFines.forEach((fine, index) => {
        if(fine.original.customerPaymentAllowed === true) {
            let fineAmount = parseFloat(fine.amount);
            sumOfFines += fineAmount;
            numOfFines++;
        }
	});

	//new arrays for dropdowns
	let idNumberArr = JSON.parse(localStorage.getItem('IDNumbers'));

    const handlePageChange = page => {
        onLoad(page, page);
        setCurrentPage(page);
      };
    
      const handlePerRowsChange = async (newPerPage, page) => {
        onLoad(page, page, newPerPage);
        setPerPage(newPerPage);
      };

    const handleIDFilterChange = async (e) => {
        setCurrentPage(1);
        setIDNumberFilter(e.target.value);
    }   
      
    const handleIssueingAuthorityFilterChange = async (e) => {
        setCurrentPage(1);
        setIssuingAuthFilter(e.target.value);
    } 
    let tableConfig = {
        "isSearchable": true,
        "isSortable": true,
        "isExportable": true,
        "isPaginated": true,
        "omitColumns": ["original", "issuing_authority_code"],
        "columnAlign": {"Info": "center", "Add to cart": "center", "amount": "right", "demerit_points": "right"},
        "columnFormat": {
            "amount": (row, index) => {return "R "+row["amount"]}
        },
        "customColumns": [
            {
                "name": "Info",
                "selector": (row) => (
                    <AiFillInfoCircle 
                        style={{ "fontSize": '25px' }} 
                        onClick={(e) => handleInfoClick(row)}
                        className="isNotDisabledBtnIconClass"
                    />
                ),
                "isSortable": false
            },
            {
                "name": "Add to cart",
                "selector": (row) => (checkIfCartItemExist(row) || !isLoggedIn()? 
                        <FaShoppingCart
                            id="outFinesCartBtnIcon"
                            style={{ "fontSize": '25px'}}
                            className="isDisabledBtnIconClass"
                        />
                    :
                    <FaShoppingCart
                        id="outFinesCartBtnIcon"
                        className="isNotDisabledBtnIconClass"
                        style={{ "fontSize": '25px' }}
                        onClick={() => {
                            addToCart(row);
                            handlePopupModal("Fine: "+row.infringement_number+" has been added to Cart")
                        }}
                    />
                ),
                "isSortable": false
            }
        ],
        "topFilters": () => (
            <>
                <Col md={3}>
                    <label htmlFor="IDNumberDropDown">
                        ID Number:&nbsp;
                        <select
                            id="IDNumberDropDown"
                            className="IDNumberDropDown outstandingScreenDropDowns"
                            value={IDNumberFilter}
                            onChange={handleIDFilterChange}
                            onBlur={handleIDFilterChange}
                        >
                            <option value="ALL">All</option>
                            {idNumberArr && idNumberArr.map((idNumber) => {
                                return (
                                    <option key={idNumber} value={idNumber}>
                                        {idNumber.split(":")[0]}
                                    </option>
                                );
                            })}
                        </select>
                    </label>
                </Col>
                <Col md={3}>
                    <label htmlFor="issuingAuthDropDown">
                        Issuing Authority:&nbsp;
                        <select
                            id="issuingAuthDropDown"
                            className="issuingAuthDropDown outstandingScreenDropDowns"
                            value={issuingAuthFilter}
                            onChange={handleIssueingAuthorityFilterChange}
                            onBlur={handleIssueingAuthorityFilterChange}
                        >
                            <option value="ALL">All</option>
                            {issuingAuthList.map((issuingAuth) => {
                                return (
                                    <option key={issuingAuth.issuingAuthorityCode} value={issuingAuth.issuingAuthorityCode}>
                                        {issuingAuth.districtName}
                                    </option>
                                );
                            })}
                        </select>
                    </label>
                </Col>
            </>
        ),
        "paginationPerPage": perPage,
        "paginationRowsPerPageOptions": [10, 15, 25, 50, 100],
        "paginationServer": isServerSideEnabled,
        "paginationTotalRows": totalRows,
        "paginationDefaultPage": currentPage,
        "onChangeRowsPerPage": handlePerRowsChange,
        "onChangePage": handlePageChange
    }

	return (
		<div className="center-to-screen pb-3 pt-3">
            {showPreloader ? (
					<div>
						<Spinner
							animation="border"
							role="status"
							style={{ display: 'block', margin: '0 auto' }}
						>
							<span className="visually-hidden">Loading...</span>
						</Spinner>
					</div>
				) : (
                <Container>
                    <Row className="mb-3" style={{ fontWeight: 'bold' }}>
                        <Row className="mb-3">
                            <Col xs={12} sm={12} md={12} lg={8}>
                                <h1 className="mt-0">Outstanding Fines</h1>
                            </Col>
                            {/* <Col md={2}></Col> */}
                            <Col xs={12} sm={10} md={8} lg={4}>
                                <div style={{ backgroundColor: '#b5b1b1', padding: '20px', margin: '5px' }}>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12} style={{textAlign: "center"}}>
                                            <h3 className="mt-0" style={{ fontWeight: 'bold' }}>
                                                Open Fine Summary
                                            </h3>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} sm={8} md={7} lg={7} style={{margin: "auto"}}>
                                            Total unpaid fines: {outstandingFines.length > 0 ? numOfFines : 0}
                                            <br />
                                            Total amount: R {outstandingFines.length > 0 ? sumOfFines : 0}
                                        </Col>                                        
                                        <Col xs={12} sm={4} md={5} lg={5} className="mt-2">
                                            <LoaderButton style={{color:'#fff'}} onClick={(e)=>handleAllFinesToCart(e)} disabled={!isLoggedIn()} isLoading={isAddingFines}>Add All Fines to Cart</LoaderButton>
                                        </Col>                                     
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Row>
                    {outstandingFines.length <= 0 ? <Jumbotron style={{textAlign:'center'}}>No Fine Information <LoaderButton style={{color:'#fff'}} onClick={(e)=>handleClearFilterClick(e)} isLoading={isAddingFines}>Clear Filters</LoaderButton></Jumbotron> : (
                        <Row>
                            <Col xs={12} md={12} lg={12}>
                                <ExportableTable title="Fines" config={tableConfig} data={outstandingFines} searchFilterOverride={tableSearch} searchFilterOverrideSet={setTableSearch}/>
                            </Col>
                        </Row>
                    )}
                </Container>
            )}
		</div>
	);
}

export default memo(OutstandingFines);