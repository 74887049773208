import React, { Component } from 'react';
import Routes from 'routes';

class App extends Component {
	render() {
		return (
			<>
                <div className="p-0">
                    <Routes />
                </div>
			</>
		);
	}
}

export default App;
