import React, { memo } from 'react';
import { Switch, Route } from 'react-router-dom';
import { NotFound } from 'components/common';
import { PopupModalProvider } from "../contexts/popupModalContext";

/*
* This is the route utility component used for generating
* routes and child routes it only requires routes array and basePath
*/
function MapAllowedRoutes({routes, isAddNotFound}) {
	return (
		<Switch>
			{routes.map((route) => {
				/*
				* some variables are used by below code
				* some are not used by the code but destructure due to remove from rest object
				* just make sure that rest object only contain props that supported by react-router's route component
				* you may find props list here https://reactrouter.com/web/api/Route
				*/
				const { path, component: Component, children, title, permission, canNavigate, ...rest } = route;

				var isExact = false;
				if(path === "/") {
					isExact = true;
				}
				return (
					<Route {...rest} exact={isExact} key={path} path={`${path}`}>
                        <PopupModalProvider>
						    <Component children={children} />
                        </PopupModalProvider>
					</Route>
				)
			})}
			{isAddNotFound && <Route><NotFound /></Route>}
		</Switch>
	)
}

export default memo(MapAllowedRoutes);
