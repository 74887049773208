import React, { memo, useState, useEffect, useContext } from 'react';
import { PopupModalContext } from "../contexts/popupModalContext";
import { LoaderButton, ExportableTable } from './common';
import { Button, Form, Row, Container, Card, Col, Modal, Spinner } from 'react-bootstrap';
import { FaTrashAlt } from 'react-icons/fa';
import { FiEdit3 } from 'react-icons/fi';
import { getUserDetails, validateFieldType } from 'utils';
import unityRequests from './unityRequests';
import { Auth } from 'aws-amplify';
import { useLocalStorage } from '@rehooks/local-storage';

function Profile() {
	let { handlePopupModal } = useContext(PopupModalContext);
	const [form, setForm] = useState({
		primaryIDTypeValue: '',
		primaryIDValue: '',
		primaryNameValue: '',
		primarySurnameValue: '',
		primaryEmailValue: '',
		primaryMobileNumberValue: '',
		editIDValue: '',
		secondaryIDTypeValue: '02',
		secondaryIDValue: '',
		secondaryNameValue: '',
		secondarySurnameValue: '',
		password: '',
		passwordOld: ''
	});

	const [userDetails, setUserDetails] = useLocalStorage('userDetails', {});
	const [errors, setErrors] = useState({});
	const [additionalIds, setPrimaryAdditionalIds] = useState([]);
	const [showPreloader, setShowPreLoader] = useState(false);
	const [showAddIDModal, setshowAddIDModal] = useState(false);
	const [removeIDModalDisplayed, setRemoveIDModalDisplayed] = useState(false);
	const [editID, setEditID] = useState(false);
	const [isUpdating, setisUpdating] = useState(false);
	const [isBusiness, setisBusiness] = useState(false);
	const [persistedCartItems, setPersistedCartItems] = useLocalStorage(
		'cartItems',
		{}
	);
	const [unlinkedIDNumbers, setUnlinkedIDNumbers] = useState([]);

	let userID = "";

	useEffect(() => {
		async function onLoad() {
			try {
				setShowPreLoader(true);
				let userID = getUserDetails().data.UserID;

				let userData = await unityRequests(
					`/user/details?userId=${userID}`,
					{},
					'get'
				);

				localStorage.setItem(
					'userProfileDetails',
					JSON.stringify(userData.payload.data)
				);

				//check business account checkbox if idtype business registration
				if (userData.payload.data.strIdType === "04") {
					setisBusiness(true);
				}

				form.primaryIDTypeValue = userData.payload.data.strIdType;
				form.primaryIDValue = userData.payload.data.strIdNumber;
				form.primaryNameValue = userData.payload.data.strName;
				form.primarySurnameValue = userData.payload.data.strSurname;
				form.primaryEmailValue = userData.payload.data.strEmail;
				form.primaryMobileNumberValue = userData.payload.data.strMobileNumber;

				setPrimaryAdditionalIds(userData.payload.additionalIds);

				if (userData.payload.data.maxNumberIds === "False" || userData.payload.data.maxNumberIds === "false" || userData.payload.data.maxNumberIds === false) {
					userData.payload.data.maxNumberIds = false;
				}
				else if (userData.payload.data.maxNumberIds === "True" || userData.payload.data.maxNumberIds === "true" || userData.payload.data.maxNumberIds === true) {
					userData.payload.data.maxNumberIds = true;
				}

				setShowPreLoader(false);
			} catch (e) {
				console.log(e);
			}
		}

		onLoad();
	}, [userID]);

	const setField = (field, value) => {
		setForm({
			...form,
			[field]: value
		});
		// Check and see if errors exist, and remove them from the error object:
		if (!!errors[field]) {
			setErrors({
				...errors,
				[field]: null
			});
		}
	};

	const getField = (field) => {
		return form[field];
	};

	function handleAddIDNumberBtnOnclick() {
		setEditID(false);

		form.secondaryIDValue = '';
		form.secondaryNameValue = '';
		form.secondarySurnameValue = '';
		setshowAddIDModal(true);
	}

	function handleEditIDBtnOnclick(e, profileSecondaryID) {
		setEditID(true);
		setshowAddIDModal(true);

		form.editIDValue = profileSecondaryID.strIDNumber;
		form.secondaryIDTypeValue = profileSecondaryID.strIdType ? profileSecondaryID.strIdType : '02';
		form.secondaryIDValue = profileSecondaryID.strIDNumber ? profileSecondaryID.strIDNumber : 'N/A';
		form.secondaryNameValue = profileSecondaryID.strName ? profileSecondaryID.strName : 'N/A';
		form.secondarySurnameValue = profileSecondaryID.strSurname ? profileSecondaryID.strSurname : 'N/A';
	}

	function removeFromCart() {
		try {
			debugger;
			let cartItems = { ...persistedCartItems };
			let unlinkedIds = [...unlinkedIDNumbers];

			let keys = Object.keys(cartItems);

			for (let i = 0; i < unlinkedIds.length; i++) {
				let id = unlinkedIds[i];

				for (let j = 0; j < keys.length; j++) {
					let element = cartItems[keys[j]];

					if (element != undefined) {
						let idNumber = null;
						
						if(element.idNumber !== undefined) {
							idNumber = element.idNumber;
						} else {
							idNumber = element.IDNumber;
						} 

						if(idNumber == null || idNumber == id || idNumber == 'N/A') {
							let item = element;

							if (item) {
								//important to not make changes to the persistedCartItems itself else it wont trigger updates
								let itemKeys = Object.keys(item);
								let fineID = '';

								if (itemKeys.includes('infringementNoticeNumber')) {
									fineID = item.infringementNoticeNumber;
								} else {
									fineID = item.noticeNumber;
								}

								if (Object.keys(cartItems).includes(fineID)) {
									delete cartItems[fineID];
								}
							}
						}		
					}
				}
			}
			setPersistedCartItems(cartItems);
		} catch (error) {
			setisUpdating(false);
		}

	}

	function handleAddIDNumber(e) {
		e.preventDefault();

		const newErrors = validateIDNumberAdd();

		if (Object.keys(newErrors).length > 0) {
			// We got errors!
			setErrors(newErrors);
		}
		else {

			let existingIdNumbers = [userDetails.data.strIdNumber];
			for (let i = 0; i < additionalIds.length; i++) {
				existingIdNumbers.push(additionalIds[i].strIDNumber);
			}

			if (existingIdNumbers.includes(getField("secondaryIDValue"))) {
				handlePopupModal('ID number already exists!');
			}
			else {
				setPrimaryAdditionalIds([...additionalIds, { strName: getField("secondaryNameValue"), strSurname: getField("secondarySurnameValue"), strIDNumber: getField("secondaryIDValue"), strIdType: getField("secondaryIDTypeValue"), dtDateCaptured: new Date().toLocaleDateString() }]);
				handlePopupModal('New ID Added! Click update to save changes.');
				setshowAddIDModal(false);
			}
		}
	}

	async function handleUpdate(e) {
		e.preventDefault();
debugger;
		const newErrors = validateUpdate();

		if (Object.keys(newErrors).length > 0) {
			// We got errors!
			setErrors(newErrors);
		}
		else {
			setisUpdating(true);

			let userUpdatedDataLocalStorage = JSON.parse(
				localStorage.getItem('userProfileDetails')
			);
			let ids = [];

			userUpdatedDataLocalStorage.strName = getField('primaryNameValue');
			userUpdatedDataLocalStorage.strSurname = getField('primarySurnameValue');
			userUpdatedDataLocalStorage.strIdType = getField('primaryIDTypeValue');
			userUpdatedDataLocalStorage.strIdNumber = getField('primaryIDValue');
			userUpdatedDataLocalStorage.strMobileNumber = getField('primaryMobileNumberValue');
			userUpdatedDataLocalStorage.txtBillAddress = "Home";
			userUpdatedDataLocalStorage.userId = getUserDetails().data.UserID;
			userUpdatedDataLocalStorage.strCognitoUuid = getUserDetails().data.strCognitoUuid;
			userUpdatedDataLocalStorage.strEmail = getField('primaryEmailValue');
			userUpdatedDataLocalStorage.additionalIds = additionalIds;

			if (getField('primaryIDValue') !== "" && getField('primaryIDValue') !== undefined) {
				ids.push(getField('primaryIDValue') + ":" + getField('primaryIDTypeValue'));
			}
			for (let i = 0; i < additionalIds.length; i++) {
				if (additionalIds[i].strIDNumber !== "" && additionalIds[i].strIDNumber !== undefined) {
					ids.push(additionalIds[i].strIDNumber + ":" + additionalIds[i].strIdType);
				}
			}
			localStorage.setItem('IDNumbers', JSON.stringify(ids));

			localStorage.setItem(
				'userProfileDetails',
				JSON.stringify(userUpdatedDataLocalStorage)
			);

			setUserDetails({ data: userUpdatedDataLocalStorage, additionalIds: additionalIds });

			await unityRequests(
				'/update',
				userUpdatedDataLocalStorage,
				'post'
			);

			if (getField("password") !== "") {
				await Auth.currentAuthenticatedUser().then((user) => {
					return Auth.changePassword(user, getField("passwordOld"), getField("password"));
				})
					.catch((err) => { handlePopupModal(err) });
			}

			// console.log(userUpdateResult);
			removeFromCart();
			setisUpdating(false);

			handlePopupModal('Profile Details Updated');
		}
	}

	function handleDeleteID(e, profileSecondaryID) {
		e.preventDefault();

		if (!unlinkedIDNumbers.includes(profileSecondaryID.strIDNumber)) {
			setUnlinkedIDNumbers((ids) => [...ids, profileSecondaryID.strIDNumber]);
		}

		let userUpdatedDataLocalStorage = JSON.parse(
			localStorage.getItem('userProfileDetails')
		);

		userUpdatedDataLocalStorage.additionalIds = additionalIds;

		localStorage.setItem(
			'userProfileDetails',
			JSON.stringify(userUpdatedDataLocalStorage)
		);

		let updatedIds = userUpdatedDataLocalStorage.additionalIds.filter(function (ids) {
			return ids.strIDNumber !== profileSecondaryID.strIDNumber;
		});
		setPrimaryAdditionalIds([...updatedIds]);
		handlePopupModal('ID Deleted! Click update to save changes.');
	}

	function handleUpdateSecondaryIDNumber(e) {
		e.preventDefault();

		const newErrors = validateIDNumberAdd();

		if (Object.keys(newErrors).length > 0) {
			// We got errors!
			setErrors(newErrors);
		} else {

			let userUpdatedDataLocalStorage = JSON.parse(
				localStorage.getItem('userProfileDetails')
			);

			userUpdatedDataLocalStorage.additionalIds = additionalIds;

			localStorage.setItem(
				'userProfileDetails',
				JSON.stringify(userUpdatedDataLocalStorage)
			);

			for (let i = 0; i < userUpdatedDataLocalStorage.additionalIds.length; i++) {
				if (userUpdatedDataLocalStorage.additionalIds[i].strIDNumber === form.editIDValue) {
					userUpdatedDataLocalStorage.additionalIds[i].strIdType = getField("secondaryIDTypeValue");
					userUpdatedDataLocalStorage.additionalIds[i].strIDNumber = getField("secondaryIDValue");
					userUpdatedDataLocalStorage.additionalIds[i].strName = getField("secondaryNameValue");
					userUpdatedDataLocalStorage.additionalIds[i].strSurname = getField("secondarySurnameValue");
				}
			}

			localStorage.setItem(
				'userProfileDetails',
				JSON.stringify(userUpdatedDataLocalStorage)
			);

			setPrimaryAdditionalIds([...additionalIds]);

			handlePopupModal('Linked ID Updated');
			setshowAddIDModal(false);
		}
	}

	function validateUpdate() {
		const newErrors = {};

		if (getField("password") !== "") {
			if (getField("password") === getField("passwordOld")) {
				newErrors.password = 'Passwords cannot match!';
			}
			if (getField("passwordOld") === "") {
				newErrors.passwordOld = 'Must provide old password!';
			}
		}

		if (!getField("primaryIDValue") || getField("primaryIDValue") === '') {
			newErrors.primaryIDValue = 'Cannot be blank!';
		}
		else if (!validateFieldType(getField("primaryIDValue"), getField("primaryIDTypeValue"))) {
			newErrors.primaryIDValue = 'Must be valid!';
		}

		if (!getField("primaryNameValue") || getField("primaryNameValue") === '') {
			newErrors.primaryNameValue = 'Cannot be blank!';
		}
		else if (!validateFieldType(getField("primaryNameValue"), "Name")) {
			newErrors.primaryNameValue = 'Must be valid!';
		}

		if (!getField("primarySurnameValue") || getField("primarySurnameValue") === '') {
			newErrors.primarySurnameValue = 'Cannot be blank!';
		}
		else if (!validateFieldType(getField("primarySurnameValue"), "Name")) {
			newErrors.primarySurnameValue = 'Must be valid!';
		}

		if (!getField("primaryEmailValue") || getField("primaryEmailValue") === '') {
			newErrors.primaryEmailValue = 'Cannot be blank!';
		}
		else if (!validateFieldType(getField("primaryEmailValue"), "Email")) {
			newErrors.primaryEmailValue = 'Must be valid!';
		}

		if (!getField("primaryMobileNumberValue") || getField("primaryMobileNumberValue") === '') {
			newErrors.primaryMobileNumberValue = 'Cannot be blank!';
		}
		else if (!validateFieldType(getField("primaryMobileNumberValue"), "Phone")) {
			newErrors.primaryMobileNumberValue = 'Must be valid!';
		}

		return newErrors;
	}

	function validateIDNumberAdd() {
		const newErrors = {};

		console.log(getField("secondaryIDValue"));
		console.log(getField("secondaryIDTypeValue"));


		if (!getField("secondaryIDValue") || getField("secondaryIDValue") === '') {
			newErrors.secondaryIDValue = 'Cannot be blank!';
		}
		else if (!validateFieldType(getField("secondaryIDValue"), getField("secondaryIDTypeValue"))) {
			newErrors.secondaryIDValue = 'Must be valid!';
		}

		if (!getField("secondaryNameValue") || getField("secondaryNameValue") === '') {
			newErrors.secondaryNameValue = 'Cannot be blank!';
		}
		else if (!validateFieldType(getField("secondaryNameValue"), "Name")) {
			newErrors.secondaryNameValue = 'Must be valid!';
		}

		if (!getField("secondarySurnameValue") || getField("secondarySurnameValue") === '') {
			newErrors.secondarySurnameValue = 'Cannot be blank!';
		}
		else if (!validateFieldType(getField("secondarySurnameValue"), "Name")) {
			newErrors.secondarySurnameValue = 'Must be valid!';
		}

		return newErrors;
	}

	function handleBusinessAccountCheck(e) {
		setField('primaryIDTypeValue', e.target.value);

		if (e.target.value === "04") {
			setisBusiness(true);
		}
		else {
			setisBusiness(false);
		}
	}

	function handleBusinessAccountCheckBoxOnchange() {
		if (isBusiness === true) {
			setisBusiness(false);
			setField('primaryIDTypeValue', "02");
		} else {
			setisBusiness(true);
			setField('primaryIDTypeValue', "04");
		}
	}

	let tableConfig = {
		"isSearchable": false,
		"isSortable": true,
		"isExportable": false,
		"isPaginated": false,
		"columnAlign": { "Edit": "center", "Delete": "center" },
		"columnFormat": {
			"strIdType": (row, index) => {
				if (row["strIdType"] === "02") {
					return "RSA ID Number";
				}
				else if (row["strIdType"] === "03") {
					return "Foreign ID Number";
				}
				else if (row["strIdType"] === "04") {
					return "Business Registration";
				}
				else {
					return "Unkown ID type";
				}
			}
		},
		"customColumns": [
			{
				"name": "Edit",
				"selector": (row) => (
					// <Button onClick={(e) => {handleEditIDBtnOnclick(e, row)}}>
					<FiEdit3
						className="isNotDisabledBtnIconClass"
						style={{ fontSize: '30px' }}
						onClick={(e) => { handleEditIDBtnOnclick(e, row) }}
					/>
					//</Button>
				),
				"isSortable": false
			},
			{
				"name": "Delete",
				"selector": (row) => (
					// <Button onClick={(e)=>handleDeleteID(e, row)}>
					<FaTrashAlt
						className="isNotDisabledBtnIconClass"
						style={{ fontSize: '30px' }}
						onClick={(e) => handleDeleteID(e, row)}
					/>
					// </Button>
				),
				"isSortable": false
			}
		]
	}


	return (
		<div className="center-to-screen pt-3 pb-3">
			{showPreloader ? (
				<div>
					<Spinner
						animation="border"
						role="status"
						style={{ display: 'block', margin: '0 auto' }}
					>
						<span className="visually-hidden">Loading...</span>
					</Spinner>
				</div>
			) : (
				<Container>
					<Row>
						<Col md="12">
							<Form className="form">
								<h1>Profile</h1>
								<Row>
									<Col className="pr-1" md={6}>
										<Form.Group>
											<label style={{ color: '#000' }}>Name:</label>
											<Form.Control
												type="text"
												id="strName"
												defaultValue={getField('primaryNameValue')}
												onChange={(e) => setField('primaryNameValue', e.target.value)}
												isinvalid={!!errors.primaryNameValue ? "true" : "false"}
											></Form.Control>
											<Form.Control.Feedback type='invalid'>
												{errors.primaryNameValue}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col className="pr-1" md={6}>
										<Form.Group>
											<label style={{ color: '#000' }}>Surname:</label>
											<Form.Control
												type="text"
												id="strSurname"
												defaultValue={getField('primarySurnameValue')}
												onChange={(e) => setField('primarySurnameValue', e.target.value)}
												isinvalid={!!errors.primarySurnameValue ? "true" : "false"}
											></Form.Control>
											<Form.Control.Feedback type='invalid'>
												{errors.primarySurnameValue}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col className="pr-1" md={6}>
										<Form.Group>
											<label className="IDTypeDropDownLabel" htmlFor="IDTypeDropDown">
												<span className="pr-3">
													Select ID Type:
												</span>
											</label>

											<select
												id="IDTypeDropDown"
												className="IDTypeDropDown outstandingScreenDropDowns d-block w-100"
												style={{ textAlign: 'left', width: 'auto' }}
												value={getField('primaryIDTypeValue')}
												onChange={(e) => {
													handleBusinessAccountCheck(e)
												}}
											>
												<option
													key="02"
													value="02"
												>
													RSA ID Number
												</option>
												<option
													key="03"
													value="03"
												>
													Foreign ID Number
												</option>
												<option
													key="04"
													value="04"
												>
													Business Registration
												</option>
											</select>
										</Form.Group>
									</Col>
									<Col className="pr-1" md={6}>
										<Form.Group>
											<label style={{ color: '#000' }}>ID Number:</label>
											<Form.Control
												type="text"
												id="strIdNumber"
												defaultValue={getField('primaryIDValue')}
												onChange={(e) => setField('primaryIDValue', e.target.value)}
												isinvalid={!!errors.primaryIDValue ? "true" : "false"}
											></Form.Control>
											<Form.Control.Feedback type='invalid'>
												{errors.primaryIDValue}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col className="pr-1" md={6}>
										<Form.Group>
											<label style={{ color: '#000' }}>Email:</label>
											<Form.Control
												disabled
												type="email"
												id="strEmail"
												defaultValue={getField('primaryEmailValue')}
												onChange={(e) => setField('primaryEmailValue', e.target.value)}
												isinvalid={!!errors.primaryEmailValue ? "true" : "false"}
											></Form.Control>
											<Form.Control.Feedback type='invalid'>
												{errors.primaryEmailValue}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col className="pr-1" md={6}>
										<Form.Group>
											<label style={{ color: '#000' }}>
												Mobile Number:
											</label>
											<Form.Control
												type="text"
												id="strMobileNumber"
												defaultValue={getField('primaryMobileNumberValue')}
												onChange={(e) => setField('primaryMobileNumberValue', e.target.value)}
												isinvalid={!!errors.primaryMobileNumberValue ? "true" : "false"}
											></Form.Control>
											<Form.Control.Feedback type='invalid'>
												{errors.primaryMobileNumberValue}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col className="pr-1" md={6}>
										<Form.Group>
											<label style={{ color: '#000' }}>New Password:</label>
											<Form.Control
												type="password"
												id="strPassword"
												defaultValue={getField('password')}
												onChange={(e) => setField('password', e.target.value)}
												isinvalid={!!errors.password ? "true" : "false"}
												placeholder="(Unchanged)"
											></Form.Control>
											<Form.Control.Feedback type='invalid'>
												{errors.password}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col className="pr-1" md={6}>
										<Form.Group>
											<label style={{ color: '#000' }}>Old Password:</label>
											<Form.Control
												type="password"
												id="strPasswordOld"
												defaultValue={getField('passwordOld')}
												onChange={(e) => setField('passwordOld', e.target.value)}
												isinvalid={!!errors.passwordOld ? "true" : "false"}
												placeholder="(Unchanged)"
											></Form.Control>
											<Form.Control.Feedback type='invalid'>
												{errors.passwordOld}
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
								</Row>
								<div className="clearfix"></div>
							</Form>
						</Col>
					</Row>
					<Row className="mb-3" style={{ margin: '20px' }}>
						<Col sm={12} md={6} lg={8} className="mb-4">
							<input
								readOnly
								type="checkbox"
								id="blnBusinessAccount"
								name="blnBusinessAccount"
								checked={isBusiness}
								style={{
									width: '20px',
									height: '20px',
									verticalAlign: 'middle',
									borderRadius: '50% !important'
								}}
								onChange={handleBusinessAccountCheckBoxOnchange}
							/>
							<label
								htmlFor="blnBusinessAccount"
								style={{ paddingLeft: '10px', color: '#000' }}
							>
								Business Account
							</label>
						</Col>
						{/* <Col sm={12} md={6} lg={4}>
                                {additionalIds.length >= 5 ? (<Button
									className="btn-grey float-end"
									onClick={handleAddIDNumberBtnOnclick}
                                    disabled
								>
									Add ID Number
								</Button>) : (<Button
									className="btn-grey float-end"
									onClick={handleAddIDNumberBtnOnclick}
								>
									Add ID Number
								</Button>)}
							</Col> */}
					</Row>
					<Row>
						<Col xs={12} md={12} lg={12}>
							<Container>
								<Row>
									<Col>
										<h1 className="profileTableHeading">Linked ID Numbers</h1>
									</Col>
								</Row>
							</Container>
							{additionalIds.length <= 0 ? (
								<>
									<Row>
										<Col style={{ textAlign: 'center' }}>
											<h1>No Linked ID's</h1>
										</Col>
									</Row>
								</>
							) : (
								<ExportableTable title="Fines" config={tableConfig} data={additionalIds} />
							)}
						</Col>
					</Row>
					<Row className="mt-5 mb-5">
						<Container style={{ display: 'flex' }}>
							<Col sm={12} lg={{ span: 3, offset: 3 }}>
								{additionalIds.length >= 5 ? (<Button
									className="btn-grey profile-screen-buttons profile-screen-add-button"
									onClick={handleAddIDNumberBtnOnclick}
									disabled
								>
									Add ID Number
								</Button>) : (<Button
									className="btn-grey profile-screen-buttons profile-screen-add-button"
									onClick={handleAddIDNumberBtnOnclick}
								>
									Add ID Number
								</Button>)}
							</Col>
							<Col sm={12} lg={{ span: 3 }}>
								<LoaderButton
									type="submit"
									className="btn-fill profile-screen-buttons"
									isLoading={isUpdating}
									onClick={handleUpdate}
								>
									Update
								</LoaderButton>
							</Col>
						</Container>
					</Row>
					<Row>
						<Modal
							show={showAddIDModal}
							onHide={() => {
								setshowAddIDModal(false);
								setErrors({});
							}}
						>
							<Modal.Header closeButton>
								{editID === true ? (
									<Modal.Title>Edit ID</Modal.Title>
								) : (
									<Modal.Title>Add ID</Modal.Title>
								)}
							</Modal.Header>
							<Modal.Body>
								<Row>
									<Col md="12">
										<Card>
											<Card.Body>
												<Form className="form">
													<Row>
														<Col md={4}>
															<label htmlFor="IDTypeDropDown">
																<span
																	style={{
																		paddingRight: '10px',
																		color: '#000'
																	}}
																>
																	ID Type:
																</span>
																<select
																	id="IDTypeDropDown"
																	className="IDTypeDropDown outstandingScreenDropDowns"
																	style={{ textAlign: 'left', width: ' auto' }}
																	defaultValue={getField('secondaryIDTypeValue')}
																	onChange={(e) => {
																		setField('secondaryIDTypeValue', e.target.value);
																	}}
																>
																	<option
																		key="02"
																		value="02"
																	>
																		RSA ID Number
																	</option>
																	<option
																		key="03"
																		value="03"
																	>
																		Foreign ID Number
																	</option>
																	<option
																		key="04"
																		value="04"
																	>
																		Business Registration
																	</option>
																</select>
															</label>
														</Col>
													</Row>
													<Row>
														<Col className="pr-1" md={6}>
															<Form.Group>
																<label style={{ color: '#000' }}>
																	ID Number:
																</label>
																<Form.Control
																	disabled={editID}
																	type="text"
																	id="strIdNumber"
																	defaultValue={getField('secondaryIDValue')}
																	onChange={(e) => {
																		setField('secondaryIDValue', e.target.value);
																	}}
																	isinvalid={!!errors.secondaryIDValue ? "true" : "false"}
																></Form.Control>
																<Form.Control.Feedback type='invalid'>
																	{errors.secondaryIDValue}
																</Form.Control.Feedback>
															</Form.Group>
														</Col>
													</Row>
													<Row>
														<Col className="pr-1" md={6}>
															<Form.Group>
																<label style={{ color: '#000' }}>Name:</label>
																<Form.Control
																	type="text"
																	id="strName"
																	defaultValue={getField('secondaryNameValue')}
																	onChange={(e) => setField('secondaryNameValue', e.target.value)}
																	isinvalid={!!errors.secondaryNameValue ? "true" : "false"}
																></Form.Control>
																<Form.Control.Feedback type='invalid'>
																	{errors.secondaryNameValue}
																</Form.Control.Feedback>
															</Form.Group>
														</Col>
														<Col className="pr-1" md={6}>
															<Form.Group>
																<label style={{ color: '#000' }}>
																	Surname:
																</label>
																<Form.Control
																	type="text"
																	id="strSurname"
																	defaultValue={getField('secondarySurnameValue')}
																	onChange={(e) => setField('secondarySurnameValue', e.target.value)}
																	isinvalid={!!errors.secondarySurnameValue ? "true" : "false"}
																></Form.Control>
																<Form.Control.Feedback type='invalid'>
																	{errors.secondarySurnameValue}
																</Form.Control.Feedback>
															</Form.Group>
														</Col>
													</Row>
													<div className="clearfix"></div>
												</Form>
											</Card.Body>
										</Card>
									</Col>
								</Row>
							</Modal.Body>
							<Modal.Footer>
								{editID === true ? (
									<Button
										className="button-l btn-fill"
										variant="primary"
										onClick={(e) => handleUpdateSecondaryIDNumber(e)}
									>
										Update
									</Button>
								) : (
									<Button className="button-l btn-fill" variant="primary" onClick={handleAddIDNumber}>
										Add
									</Button>
								)}
							</Modal.Footer>
						</Modal>
					</Row>
				</Container>
			)}
		</div>
	);
}

export default memo(Profile);