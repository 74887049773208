import React, { memo} from 'react';
import { Col, Container, Row} from 'react-bootstrap';

function PrivacyPolicy() {
    
    return(
        <div className="center-to-screen pt-3 pb-5">
            <Container>
                <Row>
                    <Col>
                        <h1 className="form-header">Privacy Policy</h1>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={8}>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>This clause provides details about our Privacy and Cookie Policy, which Policy forms part of this Agreement.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>PayMyFines takes your privacy seriously. We use the personal information that we collect from you in accordance with this Privacy and Cookie Policy.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.1 Consent</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>Your use of our services signifies your consent to us collecting and using personal information about you as specified below, in accordance with this Policy.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.2 What is personal information</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>When we refer to personal information in this Policy, we mean information that can identify you as an individual or is capable so identifying you. By personal information we don't mean general, statistical, aggregated or anonymized information.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.3 How we collect information about you</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.3.1 You may provide personal information to us when communicating with us.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.3.2 You may provide your name, e-mail address, delivery address, credit or debit card number and expiry date so that the payment can be processed. Sometimes, you may be asked for your telephone number.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.3.3 When you call us, we may monitor or record your calls and store details of the phone number(s) you use to make the calls. This information is used to better address your customer service needs.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.3.5 We will collect information concerning your tastes and preferences both when you tell us what these are and through our analysis of customer traffic, including through the use of cookies (as described below).</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.3.6 It may be that you provide to us details of credit or debit cards or bank accounts in order to make payments to us. Any such information ("confidential financial information") will be used by us only for the specific purpose for which it was provided to us.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.4 How we use your information</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.4.1 We may use your information to confirm that your payments have been received; to validate you as a customer when using our services and calling our Customer Number; to prevent and detect criminal activity, fraud and misuse of or damage to our services or networks; to prosecute those responsible and to contact you to invite you to form part of our consumer panel or various research groups.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.4.2 We may also use your personal information and process such information for the purposes of management, research, analysis, corporate reporting, credit-scoring and improving business efficiencies.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.4.3 We may use personal information collected about you to contact you by post, telephone and electronic mail (that is email, picture, video, and SMS) about functionality changes to our website, services or changes to our terms and conditions of use and to communicate with you about your customer benefits and advise you regarding the use of our services.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.4.4 We may use the information collected about you to contact you by post or phone about new customer offers and services. We may also contact you by electronic mail for these purposes, subject to any preferences selected by you.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.5 Other information we may collect</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.5.1 We may collect network or traffic data related to your use and access to our services. Such data would include, but not be limited to, time spent using our services. We may also use some of this information for research, segmentation of our customer base, marketing our services and to understand and keep you informed about what is the best use of our services.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.5.2 We also collect information from visitors to our websites and registered customers to help us improve the websites and services that we make available. The type of information collected includes how many visitors visit our websites, when they visited, for how long and which areas of our services. This information allows us to continuously improve our services and develop them according to the taste of our customers and visitors. The information we collect from visitors to our websites who are not registered users is anonymous and does not include personal information that allows us to identify you as an individual. However, if you are a PayMyFines customer, the information collected from our websites is not anonymous and PayMyFines may use this information along with your preferences to tailor content, services and offers for you.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.6 How long do we keep your information for?</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>The time periods for which we keep your information may vary according to the use or purpose attached to the information. Unless there is a specific legal requirement requiring us to keep your information, however, we will not keep it for longer than necessary for the purpose for which the information was collected or for which it is to be processed.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.7 Disclosing your information to third parties</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.7.1 PayMyFines reserves the right to access and disclose individual-identifying information to comply with applicable laws, regulations and lawful government requests, to operate its systems properly or to protect itself or its users or to solve any customer disputes.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.7.2 We may provide aggregate statistics about our sales, customers, traffic patterns and other site information to third parties, but these statistics will not include any information that could personally identify you.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.7.3 We will not disclose personal information collected about you without your express consent thereto or through due legal process.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.8 Cookies</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>A cookie is a piece of information that is stored on your computer's hard drive by your web browser. On revisiting our website, our computer server will recognise the cookie, giving us information about your visit.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.9 We use cookies:</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.9.1 In the registration process</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>We allocate cookies during the registration process for our services. These cookies will hold information collected during your registration and will allow us to recognise you as a customer and provide you with the services you require. If your browser is set to reject cookies, you will not be able to register for PayMyFines services. We may also use this data to better understand your interests while online and to personalise your visits to our websites.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.9.2 In different sections of our portal</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>We use cookies to collect information concerning visitors to our website. Our servers use two different types of cookies. The first type is known as a 'session-based' cookie and is allocated to your computer only for the duration of your visit to our website. It helps you to move around the website faster and, if you're a registered customer, it allows us to give you information relevant to your selected PayMyFines service. This cookie automatically expires when you close down your browser.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>The second type of cookie is known as a 'persistent' cookie. A persistent cookie will remain on your computer for the period of time that has been set for each cookie. Persistent cookies are used for the purposes of advertisements on our site and to track whether these advertisements are clicked on by users; to control how often you are shown a particular advertisement; to tailor content to your preferences; to count the number of anonymous users of the site and to provide security within shopping baskets or transactions. The types of cookies used and the manner in which the information generated by them is used will be governed by those advertisers' privacy policies. We have no control over these.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.10 POPI</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>We transfer personal data to the Amazon Web Services. When services is in a country outside South Africa, we apply the necessary safeguards which may include, confirming whether the South African Privacy Protections Laws approves of transfers to the country.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );	
}

export default memo(PrivacyPolicy);
