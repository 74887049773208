import React, { memo, useEffect, useState, useMemo } from 'react';
//import { PopupModalContext } from "../../contexts/popupModalContext";
import { useWindowDimensions } from "../../hooks";
import { generateUUID, getNiceName } from "../../utils";
import DataTable, { defaultThemes } from 'react-data-table-component';
import { Table, Row, Col, Button } from 'react-bootstrap';
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import isArray from 'lodash.isarray';


//example usage:
/*
import { ExportableTable } from './common';

function renderComponent() {
    const trafficData = [
        {
            "id": 20,
            "rego_state": "ACT",
            "clt_catg": "Diplomatic",
            "camera_type": "FIXED ONLY SPEED CAMERA",
            "location_code": "1031",
            "location_desc": "FEDERAL HIGHWAY BETWEEN ANTILL STREET AND MAJURA ROAD",
            "offence_desc": "Non-School Zone Exceed Speed Limit <= 15 Km/H",
            "sum_pen_amt": "0",
            "sum_inf_count": "0",
            "sum_with_amt": "203",
            "sum_with_count": "1"
        },
        {
            "id": 30,
            "rego_state": "ACT",
            "clt_catg": "Diplomatic",
            "camera_type": "FIXED ONLY SPEED CAMERA",
            "location_code": "1027",
            "location_desc": "BARTON HIGHWAY BETWEEN GUNGAHLIN DRIVE AND ELLENBOROUGH STREET",
            "offence_desc": "Non-school zone exceed speed limit by <= 15km/h",
            "sum_pen_amt": "292",
            "sum_inf_count": "1",
            "sum_with_amt": "0",
            "sum_with_count": "0"
        }
    ];


    let tableConfig = {
        "isSearchable": true,
        "isSortable": true,
        "isExportable": true,
        "isPaginated": true,
        "columnAlign": {
            "camera_type": "right",
            "sum_inf_count": "right",
            "offence_desc": "center"
        },
        "columnFormat": {
            "name": (row, index) =>  {return "Mister "+row["name"]}
        },
        "customColumns": [
            {
                "name": "Edit",
                "selector": (row) => (<Button onClick={e => {alert("You asked to edit: "+row.id);}}>Edit</Button>),
                "isSortable": false
            }
        ],
        "omitColumns": ["id", "name"],
        "hideColumnsOnResize": {
            "camera_type": "sm",
            "location_desc": "md",
            "offence_desc": "lg",
            "sum_pen_amt": 40,
        },
        "topFilters": () => (
            <Col><input type="text" name="AnFilter"/></Col>
        )
        "summedColumns": ["sum_with_amt", "sum_inf_count"]  //summes up the supplied columns where possible
    }

    return(
        <ExportableTable title="Traffic Data" config={tableConfig} data={trafficData}/>
    );
}
*/
function ExportableTable(props) {
    const [filteredTableData, setFilteredTableData] = useState([]);
    const [globalSearch, setGlobalSearch] = useState("");
    const [totals, setTotals] = useState(false);
    const { width } = useWindowDimensions();
    const [uuid, setUuid] = useState(generateUUID());


    let title = props.title || "A table";
    let config = {};
    if (props.config) {
        config = { ...props.config };
    }

    let configKeys = Object.keys(config);
    if (!configKeys.includes("isSearchable")) {
        config["isSearchable"] = true;
    }
    if (!configKeys.includes("isSortable")) {
        config["isSortable"] = true;

    }
    if (!configKeys.includes("isExportable")) {
        config["isExportable"] = false;

    }
    if (!configKeys.includes("isPaginated")) {
        config["isPaginated"] = true;

    }
    if (!configKeys.includes("customColumns")) {
        config["customColumns"] = false;

    }
    if (!configKeys.includes("omitColumns")) {
        config["omitColumns"] = false;

    }
    if (!configKeys.includes("hideColumnsOnResize")) {
        config["hideColumnsOnResize"] = false;

    }
    if (!configKeys.includes("summedColumns")) {
        config["summedColumns"] = false;
    }
    if (!configKeys.includes("columnAlign")) {
        config["columnAlign"] = false;
    }
    if (!configKeys.includes("columnFormat")) {
        config["columnFormat"] = false;
    }

    if (!configKeys.includes("paginationServer")) {
        config["paginationServer"] = false;
    }

    let data = useMemo(() => { return props.data || [] }, [props.data]);
    let columnHeaders = [];

    useEffect(() => {
        //apply search filter if needed
        let newData = [];
        if ((!props.searchFilterOverride && globalSearch === "") || (props.searchFilterOverride && props.searchFilterOverride === "") || data.length === 0) {
            newData = data;
        }
        else {
            newData = data.filter(function (object) {
                let keys = Object.keys(object);
                for (let i = 0; i < keys.length; i++) {
                    if (typeof object[keys[i]] !== 'object' && object[keys[i]] !== null && !Array.isArray(object[keys[i]])) {
                        let searchterm = globalSearch;
                        if (props.searchFilterOverride) {
                            searchterm = props.searchFilterOverride;
                        }
                        searchterm = searchterm.trim().toUpperCase();

                        let currentValue = (object[keys[i]] + "").toUpperCase();
                        if (config.columnFormat && Object.keys(config.columnFormat).includes(keys[i])) {
                            currentValue = config.columnFormat[keys[i]](object, i); //format the column the same as it would be displayed
                            currentValue = (currentValue.replace(/\s/g, '') + "").toUpperCase(); //remove whitespaces between words and change to uppercase
                            searchterm = searchterm.replace(/\s/g, ''); //remove whitespaces between words
                        }

                        if (currentValue.includes(searchterm)) {
                            return true;
                        }
                    }
                }
                return false;
            });
        }
        setFilteredTableData(newData);

        if (config["summedColumns"] !== false) {
            let summedColumns = false;
            if (config["summedColumns"].length > 0) {
                summedColumns = {};
                for (let i = 0; i < config["summedColumns"].length; i++) {
                    summedColumns[config["summedColumns"][i]] = 0;
                }
            }
            newData.forEach((row) => {
                let rowKeys = Object.keys(row);
                config["summedColumns"].forEach((colunmToSum) => {
                    if (rowKeys.includes(colunmToSum) && !isNaN(row[colunmToSum])) {
                        summedColumns[colunmToSum] += Number(row[colunmToSum]);
                    }
                });
            });
            setTotals(summedColumns);
        }
    }, [data, globalSearch, props.searchFilterOverride])

    //generate a list column headers to render
    if (data.length > 0) {
        let cHeaders = Object.keys(data[0]);//get a list of all the columns
        for (let i = 0; i < cHeaders.length; i++) {
            //make column name look nice
            let niceName = getNiceName(cHeaders[i]);

            let omitColumn = false;
            if (config.omitColumns && config.omitColumns.includes(cHeaders[i])) {
                omitColumn = true;
            }

            let hideOnResize = false
            if (config.hideColumnsOnResize && Object.keys(config.hideColumnsOnResize).includes(cHeaders[i])) {
                hideOnResize = config.hideColumnsOnResize[cHeaders[i]];
            }

            //add column to list of table headers
            let columnObj = {
                "originalName": cHeaders[i],
                "name": niceName,
                "selector": (row) => { return row[cHeaders[i]]; },
                "sortable": config.isSortable,
                "omit": omitColumn,
                "hide": hideOnResize,
                "wrap": true
            }

            if (config.columnAlign && Object.keys(config.columnAlign).includes(cHeaders[i])) {
                if (config.columnAlign[cHeaders[i]] === "center") {
                    columnObj.center = true;
                }
                else if (config.columnAlign[cHeaders[i]] === "right") {
                    columnObj.right = true;
                }
            }

            if (config.columnFormat && Object.keys(config.columnFormat).includes(cHeaders[i])) {
                columnObj.format = config.columnFormat[cHeaders[i]];
            }

            columnHeaders.push(columnObj);
        }
        if (config.customColumns !== false) {
            let customColumns = config.customColumns;
            if (!isArray(customColumns)) {
                customColumns = [customColumns];
            }
            for (let i = 0; i < customColumns.length; i++) {
                let columnObj = {
                    "originalName": customColumns[i].name,
                    "name": customColumns[i].name,
                    "selector": (row) => { return customColumns[i].selector(row); },
                    "sortable": customColumns[i].isSortable
                }

                if (config.columnAlign && Object.keys(config.columnAlign).includes(customColumns[i].name)) {
                    if (config.columnAlign[customColumns[i].name] === "center") {
                        columnObj.center = true;
                    }
                    else if (config.columnAlign[customColumns[i].name] === "right") {
                        columnObj.right = true;
                    }
                }

                if (config.columnFormat && Object.keys(config.columnFormat).includes(customColumns[i].name)) {
                    columnObj.format = config.columnFormat[customColumns[i].name];
                }

                columnHeaders.push(columnObj);
            }
        }
    }

    function convertArrayOfObjectsToCSV(configuration, array) {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        let keys = Object.keys(data[0]);

        //make column names look nice
        result = '';
        keys.forEach((key) => {
            if (key !== "original" || (key === "original" && configuration.omitColumns.includes("original") === false)) {
                result += getNiceName(key) + columnDelimiter;
            }
        });
        result = result.slice(0, -1);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) {
                    result += columnDelimiter;
                }

                if (key !== "original" || (key === "original" && configuration.omitColumns.includes("original") === false)) {
                    result += item[key];
                    ctr++;
                }
            });
            result += lineDelimiter;
        });

        return result;
    }

    // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
    function downloadCSV(configuration, array) {
        let inArray = [...array];

        //add summation row if applicable
        if (config["summedColumns"] !== false && inArray.length > 0) {
            let typicalKeys = Object.keys(inArray[0]);
            let firstKey = typicalKeys[0];
            let sumRow = {};

            typicalKeys.forEach((key) => {
                let outString = "";
                if (key === firstKey) {
                    outString = "Totals: ";
                }

                if (config["summedColumns"].includes(key)) {
                    outString += totals[key];
                }
                sumRow[key] = outString;
            })
            inArray.push(sumRow);
        }
        let csv = convertArrayOfObjectsToCSV(configuration, inArray);
        if (csv == null) return;

        const filename = title + '.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        const link = document.createElement('a');
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    let customStyles = {
        header: {
            style: {
                minHeight: '56px',
            },
        },
        headRow: {
            style: {
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                borderTopColor: defaultThemes.default.divider.default,
            },
        },
        headCells: {
            style: {
                '&:not(:last-of-type)': {
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    borderRightColor: defaultThemes.default.divider.default,
                },
            },
        },
        cells: {
            style: {
                '&:not(:last-of-type)': {
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    borderRightColor: defaultThemes.default.divider.default,
                },
            },
        },
    };

    useEffect(() => {
        if (config["summedColumns"] !== false && document.querySelector("#" + uuid).getElementsByClassName("rdt_Pagination").length > 0) {
            let sumColumnSizing = () => {
                if (document.querySelector("#" + uuid) && document.querySelector("#" + uuid).getElementsByClassName("rdt_Pagination").length > 0) { //if the page is still valid
                    let footerElement = document.querySelector("#" + uuid).getElementsByClassName("rdt_Table")[0].parentElement;
                    let columnWidth = 10;
                    if (document.querySelector("#" + uuid).getElementsByClassName("rdt_TableCell").length > 0) {
                        columnWidth = document.querySelector("#" + uuid).getElementsByClassName("rdt_TableCell")[0].clientWidth; //russel
                    }

                    if (columnWidth === 0) {//wait until the table has been rendered
                        setTimeout(sumColumnSizing, 450);
                    }
                    else {
                        //check if an old totalsRow exists
                        if (footerElement.querySelector("#totalsRow")) {
                            footerElement.querySelector("#totalsRow").remove()
                        }

                        let totalElement = document.createElement("div");
                        totalElement.setAttribute("id", "totalsRow");

                        let html = `
                            <table class="table table-bordered">
                                <tbody>
                                    <tr style="background-color: #89959F !important; color: #FFFFFF !important;">`;
                        let firstCol = false
                        for (let i = 0; i < columnHeaders.length; i++) {
                            let header = columnHeaders[i];
                            if (!header.omit) {
                                let alignCol = "";
                                let currentTotal = totals[header.originalName];
                                if (header.format) {
                                    currentTotal = header.format(totals);
                                }
                                if (header.right === true) {
                                    alignCol = "; text-align: right;";
                                }
                                if (header.center === true) {
                                    alignCol = "; text-align: center;";
                                }
                                if (firstCol === false) {
                                    firstCol = true;
                                    if (config["summedColumns"].includes(header.originalName)) {
                                        html += `<td style="width: ` + columnWidth + `px;">TOTAL${config["summedColumns"].length > 1 ? 'S' : ''}: <span style="${alignCol}">` + currentTotal + `</span></td>`;
                                    }
                                    else {
                                        html += `<td style="width: ` + columnWidth + `px;">TOTAL${config["summedColumns"].length > 1 ? 'S' : ''}:</td>`;
                                    }
                                }
                                else {
                                    if (config["summedColumns"].includes(header.originalName)) {
                                        html += `<td style="padding-right:26px; width: ` + columnWidth + `px;${alignCol}">` + currentTotal + `</td>`;
                                    }
                                    else {
                                        html += `<td style="width: ` + columnWidth + `px;${alignCol}"></td>`;
                                    }
                                }
                            }
                        }

                        html += `</tr></tbody></table>`;
                        totalElement.innerHTML = html;
                        footerElement.append(totalElement);
                    }
                }
                else {
                    return false;
                }
            }
            setTimeout(sumColumnSizing, 150);
        }
        else {
            if (document.querySelector("#" + uuid).getElementsByClassName("rdt_Table")[0]) {
                let footerElement = document.querySelector("#" + uuid).getElementsByClassName("rdt_Table")[0].parentElement;
                if (footerElement.querySelector("#totalsRow")) {
                    footerElement.querySelector("#totalsRow").remove()
                }
            }
        }
    }, [uuid, totals, width])

    return (
        <div id={uuid}>
            <Row>
                {config.topFilters ?
                    (config.topFilters()) :
                    (<>
                        <Col md={3}></Col>
                        <Col md={3}></Col>
                    </>)
                }
                {config.isSearchable ? (
                    <Col xs={9} md={5} className="mb-3">
                        <br></br>
                        <input type="text" key="searchinput" name="Search" placeholder="Search" className="form-control custom-input"
                            value={props.searchFilterOverride ? props.searchFilterOverride : globalSearch}
                            onChange={(e) => {
                                if (props.searchFilterOverrideSet) {
                                    props.searchFilterOverrideSet(e.target.value);
                                }
                                else {
                                    setGlobalSearch(e.target.value);
                                }
                                e.target.focus();
                            }
                            } />
                    </Col>
                ) : <></>}
                {config.isExportable ? (
                    <Col xs={3} md={1}>
                        <br></br>
                        <Button className="btnExportTable" onClick={e => downloadCSV(config, filteredTableData)}>Export</Button>
                    </Col>
                ) : <></>}
            </Row>
            {width > 600 ? (
                config.paginationServer ?
                    <DataTable
                        key="datatable"
                        columns={columnHeaders}
                        data={filteredTableData}
                        pagination={config.isPaginated}
                        customStyles={customStyles}
                        paginationPerPage={config.paginationPerPage}
                        paginationRowsPerPageOptions={config.paginationRowsPerPageOptions}
                        paginationServer={config.paginationServer}
                        paginationTotalRows={config.paginationTotalRows}
                        paginationDefaultPage={config.paginationDefaultPage}
                        onChangeRowsPerPage={config.onChangeRowsPerPage}
                        onChangePage={config.onChangePage}
                        dense
                    /> :
                    <DataTable
                        key="datatable"
                        columns={columnHeaders}
                        data={filteredTableData}
                        pagination={config.isPaginated}
                        customStyles={customStyles}
                        dense
                    />) : (
                <Table className="mt-3" responsive="xl" bordered>
                    <tbody>
                        {filteredTableData.map((row, index) => {
                            return (<tr key={index} style={{ color: '#9A9A9A' }}>
                                <td md={12} key={index + "sub"}>
                                    {columnHeaders.map((header, headerIndex) => {
                                        if (!header.omit) {
                                            return (<span style={{ lineHeight: '3.5' }}><b>{header.name}</b>: <span style={{ "float": "right" }}>{header.format ? header.format(row) : header.selector(row)}</span><br /></span>);
                                        }
                                        else {
                                            return (<></>);
                                        }
                                    })}
                                </td>
                            </tr>);
                        })}
                        {config["summedColumns"] !== false ?
                            (<tr key="TotalRow" style={{ color: '#9A9A9A' }}>
                                <td md={12} key={"TotalRowSub"}>
                                    <span style={{ lineHeight: '3.5' }}><b>Totals:</b><br></br>
                                        {columnHeaders.map((header, headerIndex) => {
                                            if (config["summedColumns"].includes(header.originalName) && !header.omit) {
                                                return (<span style={{ lineHeight: '3.5' }}><b>{header.name}</b>: <span style={{ "float": "right" }}>{header.format ? header.format(totals) : totals[header.originalName]}</span><br /></span>);
                                            }
                                            else {
                                                return (<></>);
                                            }
                                        })}
                                        <br /></span>
                                </td>
                            </tr>) : (<></>)
                        }
                    </tbody>
                </Table>
            )}
        </div>
    );
}

export default memo(ExportableTable);