import React, {memo} from 'react';
import "./CustomAccordion.css";

function CustomAccordion(props) {

    function handleAccordionClick(){
        var acc = document.getElementsByClassName("accordion");

        for (var i = 0; i < acc.length; i++) {
            acc[i].addEventListener("click", function() {
                this.classList.toggle("active");
                var panel = this.nextElementSibling;
                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null;
                } else {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                } 
            });
        }
    }

    return (
        <div className="accordion-div">
            <button className="accordion" onClick={handleAccordionClick}>{props.title}</button>
            <div className={props.title === "Convenience" ? "overflowHidden panel" : "panel"}>
                {props.children}
            </div>
        </div>
    );
}

export default memo(CustomAccordion);


