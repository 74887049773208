import React, { memo, useState } from 'react';
import {
	Button,
	Form,
    Container,
	Row,
	Col,
    Spinner
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { PopupModalContext } from "../contexts/popupModalContext";
import unityRequests from './unityRequests';
import { getUserDetails, validateFieldType, isLoggedIn } from 'utils';

function QuickFineSearch() {
    let { handlePopupModal } = React.useContext(PopupModalContext);
    const [form, setForm] = useState({
        InfringementNumberValue: ''
    });
	const history = useHistory();
	const [errors, setErrors] = useState({});
    const [showPreloader, setShowPreLoader] = useState(false);
	// const [disabled, setDisabled] = useState(false);

	
	async function searchFines(e) {
		e.preventDefault();
        setShowPreLoader(true);

        const newErrors = validation();

        if(Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors);
            console.log(errors);
        }
        else{
            let userID = "";

            if(isLoggedIn()){
                userID = getUserDetails().data.UserID;
            }
            else{
                userID = "GUEST";
            }

            let cleanedInfringementNumberValue = "";

            if(getField('InfringementNumberValue').includes("-")){
                cleanedInfringementNumberValue = getField('InfringementNumberValue').replace(/-/g, "");
            }else{
                cleanedInfringementNumberValue = getField('InfringementNumberValue');
            }

            let finesRequest = await unityRequests(
                `/getfinesearch?Id=${userID}&noticeNumber=${cleanedInfringementNumberValue}`,
                {},
                'get'
            );
        
            if(finesRequest.result === true) {
                let apiStatus = [];

                let eNatisdata = finesRequest.payload.eNatis;
                let iForceData = finesRequest.payload.iForce;

                let finedata = {};
                if(iForceData === "No response from iForce"){
                    apiStatus.push("iForce did not respond.");
                }
                else {
                    finedata = iForceData.fines[0];
                }

                if(eNatisdata === "No response from eNatis"){
                    apiStatus.push("eNatis did not respond.");
                }
                else {
                    finedata = {...eNatisdata[0]};
                }

                if(apiStatus.length > 1) {
                    setShowPreLoader(false);
                    handlePopupModal("There was a problem trying to fetch fines");
                    return;
                }

                let SelectedFineKeys = Object.keys(finedata);
                setShowPreLoader(false);
                if (SelectedFineKeys.includes('infringementNoticeNumber')) {
                    history.push('/aartofinedetails?id='+finedata.infringementNoticeNumber);
                } else if (SelectedFineKeys.includes('noticeNumber')) {
                    history.push('/cpafinedetails?id='+finedata.noticeNumber);
                }
            }
            else {
                setShowPreLoader(false);
                handlePopupModal("There was a problem trying to fetch fines");
            }
        }
        setShowPreLoader(false);
	}

    const setField = (field, value) => {
		setForm({
			...form,
			[field]: value
		});
		// Check and see if errors exist, and remove them from the error object:
		if (!!errors[field]) {
			setErrors({
				...errors,
				[field]: null
			});
		}
	};

	const getField = (field) => {
		return form[field];
	};

	// function validation(e) {
	// 	if (e.target.value.length > 10 && e.target.value.length < 16) {
	// 		setDisabled(false);
	// 	} else {
	// 		setDisabled(true);
	// 	}
	// }

    function validation(){
		const newErrors = {};

        if(!getField('InfringementNumberValue') || getField('InfringementNumberValue') === '') {
                newErrors.InfringementNumberValue = 'Cannot be blank!';
            }
        else if(!validateFieldType(getField('InfringementNumberValue'), "Text")) {
            newErrors.InfringementNumberValue = 'Must be valid!';
        }

		return newErrors;
	}

	return (
		<div className="center-to-screen pb-3 pt-3">
            {showPreloader ? (
                <div>
                    <Spinner
                        animation="border"
                        role="status"
                        style={{ display: 'block', margin: '0 auto' }}
                    >
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <Container>
                    <Row>
                        <Col>
                            <Form className="form">

                                <Col className="pr-1" md={{span:6, offset: 3}}>
                                    <h1>Fine Search</h1>
                                    <Form.Group>
                                        <label style={{ color: '#000' }}>Infringement Number:</label>
                                        <Form.Control
                                            type="text"
                                            id="txtInfringementNumber"
                                            name="txtInfringementNumber"
                                            placeholder="Infringement Number"
                                            onChange={(e) => {
                                                setField('InfringementNumberValue', e.target.value);
                                                // validation(e);
                                            }}
                                            onBlur={(e) => setField('InfringementNumberValue', e.target.value)}
                                            value={getField('InfringementNumberValue')}
                                            isinvalid={ !!errors.InfringementNumberValue ? "true" : "false" }
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            { errors.InfringementNumberValue }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Form.Group as={Row} className="mb-3">
                                    <Col sm={12} style={{ textAlign: 'center' }}>
                                        <Button
                                            variant="primary"
                                            size="lg"
                                            onClick={(e)=>searchFines(e)}
                                        >
                                            Search
                                        </Button>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            )}
		</div>
	);
}

export default memo(QuickFineSearch);
