import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { isLoggedIn } from '../../utils';
// import PropTypes from 'prop-types';
// import JumbotronWrapper from './JumbotronWrapper';


const NotFound = (props) => (
    <div className="center-to-screen pb-3 pt-3">
        <Container>
            <Row>
                <Col style={{textAlign:'center'}}>
                    <h1>404 not found</h1>
                    <h1>The page you requested could not be found</h1>
                    {isLoggedIn() ? (<Link to="/home">Back to home</Link>) : (<Link to="/">Back to home</Link>)}
                </Col>
            </Row>
        </Container>
    </div>
	// <JumbotronWrapper {...props.jumbotronProps}>
	// 	{props.children}
	// </JumbotronWrapper>
);

// NotFound.propTypes = {
// 	jumbotronProps: PropTypes.shape({
// 		title: PropTypes.string,
// 		description: PropTypes.string
// 	})
// };

// NotFound.defaultProps = {
// 	jumbotronProps: {
// 		title: '404 not found',
// 		description: "The page you requested could not be found"
// 	},
// 	children: (<Link to="/">Back to home</Link>)
// };

export default memo(NotFound);
