import React, { memo, useState, useEffect } from 'react';
import { Container, Row, Col, Spinner, Image } from 'react-bootstrap';
// import { AiFillInfoCircle } from 'react-icons/ai';
import unityRequests from './unityRequests';
import { getUserDetails } from 'utils';
import { useLocalStorage } from '@rehooks/local-storage';
// import tick from '../assets/img/pmf_tick-mark_pos_1.svg';
import tick from '../assets/img/pmf_tick-mark_watermark_1.svg';
import { useHistory } from 'react-router-dom';

function PaymentNotification() {
    let history = useHistory();
    const [showPreloader, setShowPreLoader] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState("");
    const [paymentStatusColor, setPaymentStatusColor] = useState("");
    const [iVeriResponse, setiVeriResponse] = useState(false);
    const [persistedCartItems, setPersistedCartItems] = useLocalStorage(
		'cartItems',
		{}
	);

    useEffect(() => {
		async function onLoad() {
            setShowPreLoader(true);
			try {
                let returnUrlString = window.location.search;
                let returnUrlParams = new URLSearchParams(returnUrlString);
                let status = returnUrlParams.get('status');

                if(status === 'success'){
                    setPersistedCartItems({});
                    setPaymentStatus("Payment Successful");
                    setPaymentStatusColor("rgb(138, 226, 93, 0.7)");
                }
                else if(status === 'error'){
                    setPaymentStatus("Payment Unsuccessful");
                    setPaymentStatusColor("rgba(201, 76, 76, 0.4)");
                }
                else if(status === 'trylater'){
                    setPaymentStatus("Try Again Later");
                    setPaymentStatusColor("rgba(201, 76, 76, 0.4)");
                }
                else if(status === 'fail'){
                    setPaymentStatus("Payment Failed");
                    setPaymentStatusColor("rgba(201, 76, 76, 0.4)");
                }

                if(status !== "success") {
                    setiVeriResponse(returnUrlParams.get('liteResultDescription'));
                    setTimeout(() => {
                        history.push('/Cart');
                    },9000);
                }
                else {
                    setTimeout(() => {
                        history.push('/');
                    },9000)
                }

                setShowPreLoader(false);

			} catch (e) {
				console.log(e);
                setShowPreLoader(false);
			}
		}
		onLoad();
	}, []);

	return (
        <div className="center-to-screen pt-3">
                {showPreloader ? (
                        <div>
                            <Spinner
                                animation="border"
                                role="status"
                                style={{ display: 'block', margin: '0 auto' }}
                            >
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    ) : ( 
                        <Container>
                            <Row lg={12} style={{textAlign:'center', backgroundColor: paymentStatusColor, borderRadius: '25px', padding:'20px', textAlign:'center',display:'flex'}}>
                                <Col xs={6} md={3} className="my-auto"><Image src={tick} width={100}/></Col>
                                <Col xs={6} md={9}>
                                    <Row>
                                        <Col xs={6} md={9}><h1 className="paymentNotificationText" style={{color:"#000 !important"}}>{paymentStatus}</h1></Col>
                                    </Row>
                                    {iVeriResponse !== false ? 
                                        <Row>
                                            <Col xs={6} md={9}><h5 className="paymentNotificationText" style={{color:"#000 !important"}}>{iVeriResponse}</h5></Col>
                                        </Row>
                                    : <></>}
                                </Col>
                            </Row>
                            <Row>
                                {paymentStatus === "Payment Successful"? 
                                    (<>
                                        <Col md={12}><h1 style={{textAlign: "center"}}>Redirecting to home in a few moments</h1></Col>
                                    </>):(<>
                                        <Col md={12}><h1 style={{textAlign: "center"}}>Redirecting back to Cart in a few moments</h1></Col>
                                    </>)
                                }
                            </Row>
                        </Container>
                    )
                }
        </div>
	);
}

export default memo(PaymentNotification);
