import React, { memo , useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { renderButton, checkSignedIn } from "./AnalyticsUtils";
import Report from './AnalyticsReport';

function AdminAnalytics() {
    const [isSignedIn, setIsSignedIn] = useState(false);

    const updateSignin = (signedIn) => { //(3)
        setIsSignedIn(signedIn);
        if (!signedIn) {
          renderButton();
        }
    };

    const init = () => { //(2)
        checkSignedIn()
          .then((signedIn) => {
            updateSignin(signedIn);
          })
          .catch((error) => {
            console.error(error);
          });
      };
    
      useEffect(() => {
        window.gapi.load("auth2", init); //(1)
      });

	return (
		<div className="center-to-screen pt-5 pb-5">
            <Container>
                    <Row>
                        <Col>
                            <h1 className="form-header">Analytics</h1>
                        </Col>
                    </Row>
                    <Row className="w3-padding-large">
                    <Col md={12}>
                        {!isSignedIn ? (
                            <div id="signin-button"></div>
                        ) : (
                            <Report />
                        )}
                    </Col>
                </Row>
            </Container>
		</div>
	);
}

export default memo(AdminAnalytics);