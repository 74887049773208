import React, { memo} from 'react';
import { Col, Container, Row} from 'react-bootstrap';

function TermsAndConditions() {
    
    return(
        <div className="center-to-screen pt-3 pb-5">
            <Container>
                <Row>
                    <Col>
                        <h1 className="form-header">Terms and Conditions</h1>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <p className="Body1PMF">
                            <b>IMPORTANT NOTICE</b>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={8}>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>These Terms and Conditions set out the terms of the agreement between you and us (&ldquo;the Agreement&rdquo;) under which we will provide you with access to the information and services we offer from time to time via the website located at https://all.paymyfines.co.za (&ldquo;the Website&rdquo;). It is, therefore, very much in your interests to read them carefully.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>If there is anything in this Agreement that you do not understand then please contact us as soon as possible by calling the number listed under the Contact Details section below (the "Customer Number"). Please note that calls to the Customer Number are charged at national rates and may be monitored for training, security and quality assurance purposes.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>Unless otherwise stated, the Site is provided to you by TMT Services &amp; Supplies (Pty) Ltd (hereinafter referred to as &ldquo;PayMyFines&rdquo;), a company registered in the Republic of South Africa (Reg No: 2000/022850/07) having its registered office at the address stated in the Contact Details section below. References to "we", "our" and "us" are references to PayMyFines</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1. USE OF THE WEBSITE</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.1 You agree to use the Site for lawful purposes only. Without derogating from the generality of the aforegoing, if you should choose to access or use this Site from locations other than the Republic of South Africa, you do so at your own initiative and you are responsible for compliance with applicable local laws.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.2 You are prohibited from posting or transmitting, by means of reviews, comments, suggestions, ideas, questions or other information through the Site, any content which is, unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, sexually-explicit, profane or hateful, or racially, ethnically or otherwise objectionable content of any kind, which includes but is not limited to:</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.2.1 any content that encourages conduct that would constitute a criminal offence or give rise to civil liability, or otherwise violate any applicable local, provincial, national, or international law; or</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.2.2 any content that constitutes an invasion of privacy; or</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.2.3 any content that is an infringement of any intellectual property right; or</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.2.4 any content that contains software viruses; or</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.2.5 any content that constitutes a political statement, commercial solicitation, or &ldquo;Spam&rdquo;.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.3 Although PayMyFines does not purport to review (nor is it under any obligation to review) any submitted content, it reserves the right to remove any content from the Site where it deems such content, in its sole and absolute discretion, to be an infringement of Clause 3 or harmful in anyway whatsoever. Should you place on or submit to the Site any such harmful content or should you breach any clause in these Terms and Conditions, PayMyFines may immediately terminate and/or suspend your access to all or parts of the Site, without any further notice to you.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.4 You warrant that:</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.4.1 you own or otherwise control all rights to any and all content that you may submit to the Site;</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.4.2 any use of such content will not cause injury or harm to any person or entity; and</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.4.3 you will indemnify PayMyFines and/or its affiliates, directors, officers and employees, for any and all claims of whatever nature resulting from the submitted content.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.5 By submitting reviews, comments and/or any other content (other than your personal details) to PayMyFines for posting on the Site, you automatically grant PayMyFines and its affiliates a non-exclusive, royalty-free, perpetual, irrevocable right and licence to use, reproduce, publish, translate, sub-licence, copy and distribute such content in whole or in part worldwide, and to incorporate it in other works in any form, media, or technology now known or hereinafter developed for the full term of any copyright that may exist in such content. Subject to this licence, you retain any and all rights that may exist in such content.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.6 The following activities on or through the Site are expressly prohibited:</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.6.1 Any non-personal or commercial use of any robot, spider, other automatic device or technology, or manual process to monitor or copy portions of the Site or the content contained thereon, without the prior written authority of PayMyFines; and</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.6.2 The collection or use of any listings, descriptions, and/or price lists from the Site for the benefit of a competing merchant that supplies products comparable to those offered on the Site; and</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.6.3 Any use or action that imposes an unreasonable or disproportionately-large load of traffic on the Site, or otherwise interferes with the proper and timely functioning of the Site.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>1.7 You are responsible for maintaining the confidentiality and security of your User Name and Password for access to the Site and you accept full liability for all activities that occur on or related to the Site under your User Name.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>2. OUR LIABILITY TO YOU</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>2.1 We shall not be liable to you in contract, delict (including for negligence) or otherwise:</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>2.1.1 for any amount in respect of any damage or loss arising from the consequences of viruses received by you via the PayMyFines service or of our failure to provide the PayMyFines service in accordance with this Agreement in excess of the amount that has been received by PayMyFines from you as payment for the service provided by PayMyFines, as at the date of claim; or</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>2.1.2 for any economic losses, any indirect, special or consequential loss, loss of data, goodwill or reputation or any wasted expenditure including but not limited to losses caused by viruses.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>2.1.3 for any failure to perform our obligations or failure to perform our obligations properly as a result of our being prevented from doing so by an event beyond our reasonable control (which may include, without limitation, strikes; labour disputes; acts of God; war; riot; civil action; malicious acts or damage; compliance with any law, governmental or regulatory order, rule, regulation or direction; any act or omission of any government or other competent authority; accident; equipment or services failure, including the unavailability of third party telecommunications services, lines or other equipment; fire; flood or storm).</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>2.2 Each provision of this clause 6 operates separately. If any part is held by a court to be invalid, unreasonable or inapplicable then the other parts shall be severable and shall still apply in their entirety.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>3. LIMITATION OF LIABILITY</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>3.1 You should note that various elements of the service are not provided by us but instead by other companies. We are therefore unable to guarantee that such products and services or any websites accessible via the PayMyFines service are virus- or error-free.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>3.2 We, or merchants and content providers found via the PayMyFines service, may provide links to other websites or resources. We are unable to accept, and do not accept, responsibility for these websites or resources; nor have we endorsed their content, products or services merely because they are accessible via the PayMyFines service.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>3.3 While we make all reasonable efforts to ensure that all information provided by us in connection with the PayMyFines service is accurate at the time of its inclusion on the Site, you acknowledge and understand that there may be errors, inaccuracies or omissions in respect of which we exclude all liability. We make no representations, guarantees or warranties of any nature whatsoever concerning the information included on our web pages (including, but not limited to, links to third parties' web pages). You shall be solely responsible for any decisions or actions you take based on the information contained on such web pages.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>3.4 Information provided by PayMyFines does not constitute legal or professional advice and should not be relied upon as such without taking independent advice.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>3.5 While we take all reasonable steps to safeguard the security of any information you input or send to us in connection with the PayMyFines service, by using secure services and encryption technology where we deem appropriate, we accept no responsibility or liability whatsoever for any damages that you may suffer as a result of the breach of confidentiality of such information.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>4. INTELLECTUAL PROPERTY</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>4.1 All content included on this web site, such as text, graphics, logos, buttons, icons, images, photographs, audio clips, databases and software (&ldquo;the Content&rdquo;), is the property of PayMyFines or its content suppliers and protected by South African and international copyright laws. Furthermore, the compilation (meaning the collection, arrangement, and assembly) of all content on this web site is the exclusive property of PayMyFines and is protected by South Africa and international copyright laws.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>4.2 Except as stated herein, none of the material may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means, including, but not limited to, electronic, mechanical, photocopying, recording, or otherwise, except as permitted by the fair use privilege under the South African copyright laws or without the prior written permission of PayMyFines or the copyright owner.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>4.3 You may not &ldquo;mirror&rdquo; any content contained on the Site on any other server unless with the prior written permission of PayMyFines.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>4.4 You are granted a limited, revocable, and non-exclusive right to create a hyperlink to the home page of the Site, provided that the link does not portray PayMyFines, its affiliates, or their products or services in a false, misleading, derogatory, or otherwise offensive manner. You may not use any PayMyFines logo or any other proprietary graphic or trademark whatsoever as part of the link without the express written permission of PayMyFines, its affiliates and/or content suppliers.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>4.5 All trademarks are and shall remain the exclusive property of PayMyFines.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>4.6 The unauthorised submission, removal, modification or distribution of copyrighted or other proprietary Content is illegal and could subject you to criminal prosecution as well as to personal liability for damages.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>5. SUPPLY OF SERVICES IN TERMS OF THE ELECTRONIC COMMUNICATIONS AND TRANSACTIONS ACT 25 OF 2002 (&ldquo;The Act&rdquo;)</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>5.1 Insofar as this Agreement falls within the ambit of the Act, the following information is made available to you in respect of the supply of goods and/or services offered for sale, hire or exchange by way of an electronic transaction:</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>5.1.1 The main business of PayMyFines is as set out in clause 5.1.3 below.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>5.1.2 All our contact details are set out in clause 10 below.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>5.1.3 Goods and/or Services: The service provided through the website is a service whereby you can check whether you have outstanding traffic fines. You are also able to pay for any outstanding traffic fines by means of the PayMyFines website provided that no Warrant of Arrest has been issued against you for failure to pay your outstanding traffic fines.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>5.1.4 Payment: If you elect to pay for your traffic fines by means of the PayMyFines website then payments may be made online by credit card, through a payment system that utilises 128 bit encryption. The payment system allows for review, corrections and withdrawal by you prior to placing final order. Please note that we reserve the right to limit the number of transactions that may be done per credit card on the Site per day, as a fraud minimization mechanism.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>5.1.5 Complaints and Disputes: We invite you to file complaints with us via the &ldquo;Contact Us&rdquo; service of the website PayMyFines or you can contact us by way of the contact details set out in clause 9 below. We do not currently subscribe to any alternative dispute resolution code or mechanism.</p>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>5.1.6 Policies: Our policies are set out in this Agreement below.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );	
}

export default memo(TermsAndConditions);
