import { Roles } from 'config';

// Components
import { 
	Login,
	Register,
	ForgotPassword,
	LandingPage,
	QuickFineSearch,
	AARTOFineDetails,
	CPAFineDetails,
	Profile,
	Transactions,
	Cart,
	OutstandingFines,
    AdminDashboard,
    AdminAnalytics,
    AdminSettings,
    About,
    PaymentNotification,
    TermsAndConditions,
    PrivacyPolicy,
	ContactUs
} from 'components';

var routes = [
	{
		component: LandingPage,
		path: '/',
		title: 'Home',
		permission: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.MANAGER, Roles.CUSTOMER, Roles.GUEST],
		canNavigate: true,
        showInFooter: true
	},
	{
		component: OutstandingFines,
		path: '/outstandingfines',
		title: 'Outstanding Fines',
		permission: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.MANAGER, Roles.CUSTOMER],
		canNavigate: true,
        showInFooter: true
	},
	{
		component: OutstandingFines,
		path: '/quickidfinesearch',
		permission: [Roles.GUEST, Roles.SUPER_ADMIN, Roles.ADMIN, Roles.MANAGER, Roles.CUSTOMER],
		canNavigate: true,
        showInFooter: true
	},
	{
		component: QuickFineSearch,
		path: '/quickfinesearch',
		title: 'Quick Fine Search',
		permission: [Roles.GUEST, Roles.SUPER_ADMIN, Roles.ADMIN, Roles.MANAGER, Roles.CUSTOMER],
		canNavigate: false,
        showInFooter: false
	},
    {
		component: About,
		path: '/about',
		title: 'About',
		permission: [Roles.GUEST, Roles.SUPER_ADMIN, Roles.ADMIN, Roles.MANAGER, Roles.CUSTOMER],
		canNavigate: true,
        showInFooter: true
	},
	{
		component: ForgotPassword,
		path: '/forgot-password',
		title: 'Forgot Password',
		permission: [Roles.GUEST],
		canNavigate: true,
        showInFooter: false
	},
	{
		component: Login,
		path: '/login',
		title: 'Login',
		permission: [Roles.GUEST],
		canNavigate: true,
        showInFooter: true
	},
	{
		component: Register,
		path: '/register',
		title: 'Register',
		permission: [Roles.GUEST],
		canNavigate: true,
        showInFooter: true
	},
    {
		component: AdminDashboard,
		path: '/adminDash',
		title: 'Admin',
		permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
		canNavigate: true,
        showInFooter: true,
        children: [
            {
                component: AdminDashboard,
                path: '/adminDash',
                title: 'Dashboard',
                permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
                canNavigate: true,
            },
            /*{
                component: AdminDashboardTest,
                path: '/adminDashTest',
                title: 'Dashboard Test',
                permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
                canNavigate: true,
            },*/
            /*{
                component: AdminAnalytics,
                path: '/adminAnalytics',
                title: 'Analytics',
                permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
                canNavigate: true,
            },*/
            {
                component: AdminSettings,
                path: '/adminSettings',
                title: 'Settings',
                permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
                canNavigate: true,
            }
        ]
	},
    /*{
        component: AdminDashboardTest,
        path: '/adminDashTest',
        title: 'Dashboard Test',
        permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
        canNavigate: false,
    },*/
    {
        component: AdminAnalytics,
        path: '/adminAnalytics',
        title: 'Analytics',
        permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
        canNavigate: false,
    },
    {
        component: AdminSettings,
        path: '/adminSettings',
        title: 'Settings',
        permission: [Roles.SUPER_ADMIN, Roles.ADMIN],
        canNavigate: false,
    },
	{
		component: Profile,
		path: '/profile',
		title: 'My Profile',
		permission: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.MANAGER, Roles.CUSTOMER],
		canNavigate: true,
        showInFooter: true,
		children: [
			{
				component: Profile,
				path: '/profile',
				title: 'Edit Profile',
				canNavigate: true,
			},
			{
				component: Transactions,
				path: '/transactions',
				title: 'Transactions',
				canNavigate: true,
			}
		]
	},
	{
		component: CPAFineDetails,
		path: '/cpafinedetails',
		title: 'CPA Fine Details',
		permission: [],
		canNavigate: false
	},
	{
		component: AARTOFineDetails,
		path: '/aartofinedetails',
		title: 'AARTO Fine Details',
		permission: [],
		canNavigate: false
	},
	{
		component: Transactions,
		path: '/transactions',
		title: 'Transactions',
        permission: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.MANAGER, Roles.CUSTOMER],
		canNavigate: false,
        showInFooter: true
	},
	{
		component: Cart,
		path: '/Cart',
		title: 'Cart',
		permission: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.MANAGER, Roles.CUSTOMER],
		canNavigate: false,
        showInFooter: true
	},
    {
		component: TermsAndConditions,
		path: '/TermsAndConditions',
		title: 'Terms And Conditions',
		permission: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.MANAGER, Roles.CUSTOMER, Roles.GUEST],
		canNavigate: false,
        showInFooter: true
	},
    {
		component: PrivacyPolicy,
		path: '/PrivacyPolicy',
		title: 'Privacy Policy',
		permission: [Roles.SUPER_ADMIN, Roles.ADMIN, Roles.MANAGER, Roles.CUSTOMER, Roles.GUEST],
		canNavigate: false,
        showInFooter: true
	},
    {
		component: PaymentNotification,
		path: '/paymentNotification',
		title: 'PaymentNotification',
		permission: [Roles.GUEST, Roles.SUPER_ADMIN, Roles.ADMIN, Roles.MANAGER, Roles.CUSTOMER],
		canNavigate: false,
        showInFooter: false
	},
	{
		component: ContactUs,
		path: '/contactus',
		title: 'Contact Us',
		permission: [Roles.GUEST, Roles.SUPER_ADMIN, Roles.ADMIN, Roles.MANAGER, Roles.CUSTOMER],
		canNavigate: true,
        showInFooter: true
	}
];

export default routes;