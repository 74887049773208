import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Image, Row, Col, Container } from 'react-bootstrap';
import aarto from 'assets/img/aarto_550px.jpg';
import enatis from 'assets/img/enatis_268px.jpg';
import rtia from 'assets/img/rtia_600px.jpg';
import tick from 'assets/img/pmf_tick-mark_pos_1.svg';
import sedan from 'assets/img/pmf_footer-icon_sedan.svg';
import taxi from 'assets/img/pmf_icon_footer_taxi.svg';
import truck from 'assets/img/pmf_icon_footer_truck.svg';
import happy from 'assets/img/pmf_footer-icon_happy.svg';

function Footer(props) {
	return (
		<>
			{props.currentPage !== "/" ?(<Row className="customFooter w3-padding-large">
                
                <Row className="joyDrivingMobile" style={{marginBottom:"30px"}}>
                    <Col md={12} style={{textAlign:'center'}}>
                        <span className="footerTopText">Pay your fines, and get back to the joy of driving</span>
                    </Col>
                </Row>
				<Row>
					<Col xs={12} sm={12} md={12} lg={6} className="footerHeadingsCols">
						<span className="footerHeadings">
							<b>Paymyfines</b>
						</span>
						<br />
						<p className="Body1PMFFooterText">PayMyFines is a registered online platform that brings convenience right to you by facilitating the viewing and payment of traffic fines. No stress, no hassle, no queues… It is the quickest and most efficient method to pay your fines securely online.</p>
					</Col>
					<Col xs={6} sm={6} md={6} lg={{span:2, offset:1}} className="footerHeadingsCols">
						<span className="footerHeadings">
							<b>Links</b>
						</span>
						<br />
						<ul style={{ listStyleType: 'none', paddingLeft: '0px' }}>
                            {props.routes.map(({ path, title, canNavigate, showInFooter, children }) => {
                                if (showInFooter === true) {
                                    return (<li key={path} ><Link key={path} to={`${path}`} style={{textDecoration:"none"}} className="footerLinks">{title}</Link></li>);
                                }
                                else{
                                    return <div key={path}></div>;
                                }
                            })}
						</ul>
					</Col>
					<Col xs={6} sm={6} md={6} lg={3} className="footerHeadingsCols">
						<span className="footerHeadings">
							<b>Contact Us</b>
						</span>
						<br />
                        <Row>
                            <Col>
                                <Row>
                                    <Link to="/contactus">Queries</Link>
                                </Row><br/><br/>
                            </Col>
                            <Col>
                                <b>Postal Address</b><br/>
                                PO Box 234<br/>
                                Century City<br/>
                                Cape Town<br/>
                                South Africa<br/>
                                7446<br/>
                            </Col>
                        </Row>
					</Col>
				</Row>
                <Row>
                    <Col lg={6} xl={6} className="affiliatesDivCol">
                        <Row className="affiliatesDiv">
                            <Col lg={4}>
                                <Image className="affiliatesImg" src={enatis} style={{padding:'10px', width:'180px', height:'70px'}} fluid/>
                            </Col>
                            <Col lg={4}>
                                <Image className="affiliatesImg" src={rtia}  style={{padding:'10px', width:'180px', height:'70px'}} fluid/>
                            </Col>
                            <Col lg={4}>
                                <Image className="affiliatesImg" src={aarto} style={{padding:'10px', width:'180px', height:'70px'}} fluid/>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={{span:6, offset:7}} xl={{span:6, offset:7}}>
                        <div className="footerIconsMobile">
                            <Row className="footericons">
                                <Col className="col" style={{padding:'10px', width:'80px', textAlign:'center'}}>
                                    <Image className="footericonImg" src={tick} alt="tick_mark" style={{height:'91px'}} fluid/>
                                </Col>
                                <Col className="col" style={{padding:'10px', width:'50px', textAlign:'center'}}>
                                    <Image className="footericonImg" src={sedan}  alt="sedan" style={{height:'91px'}} fluid/>
                                </Col>
                                <Col className="col" style={{padding:'10px', width:'50px', textAlign:'center'}}>
                                    <Image className="footericonImg" src={taxi} alt="taxi" style={{height:'91px'}} fluid/>
                                </Col>
                                <Col className="col" style={{padding:'10px', width:'50px', textAlign:'center'}}>
                                    <Image className="footericonImg" src={truck} alt="truck" style={{height:'91px'}} fluid/>
                                </Col>
                                <Col className="col" style={{padding:'10px', width:'50px', textAlign:'center'}}>
                                    <Image className="footericonImg" src={happy} alt="happy" style={{height:'91px'}} fluid/>
                                </Col>
                            </Row>
                        </div>
                        <div className="footerIconsDesktop">
                            <Row className="footericons">
                                <Col xs={2} lg={3} style={{padding:'10px', width:'90px'}}>
                                    <Image className="footericonImg" src={tick} alt="tick_mark" fluid/>
                                </Col>
                                <Col xs={2} lg={2} style={{padding:'10px', width:'60px'}}>
                                    <Image className="footericonImg" src={sedan}  alt="sedan" fluid/>
                                </Col>
                                <Col xs={2} lg={2} style={{padding:'10px', width:'60px'}}>
                                    <Image className="footericonImg" src={taxi} alt="taxi" fluid/>
                                </Col>
                                <Col xs={2} lg={2} style={{padding:'10px', width:'60px'}}>
                                    <Image className="footericonImg" src={truck} alt="truck" fluid/>
                                </Col>
                                <Col xs={2} lg={2} style={{padding:'10px', width:'60px'}}>
                                    <Image className="footericonImg" src={happy} alt="happy" fluid/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
			</Row>
            ) : (<></>)}
            <Container fluid>
                <Row
                    className="footerCopyrightSection"
                >
                    <Col sm={12} md={12} lg={12}>
                        <p
                            style={{
                                textAlign: 'center',
                                fontSize: '14px',
                                marginTop: '20px'
                            }}
                        >
                            &copy; 2021 Paymyfines. All Rights Reserved{' '}
                        </p>
                    </Col>
                </Row>
            </Container>
            
		</>
	);
}

export default memo(Footer);
