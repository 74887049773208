/* eslint-disable import/first */
import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';
import App from 'App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import awsconfig from './aws-config';

Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: awsconfig.cognito.REGION,
      userPoolId: awsconfig.cognito.USER_POOL_ID,
      identityPoolId: awsconfig.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: awsconfig.cognito.APP_CLIENT_ID
    },
    Storage: {
      region: awsconfig.s3.REGION,
      bucket: awsconfig.s3.BUCKET,
      identityPoolId: awsconfig.cognito.IDENTITY_POOL_ID
    },
    API: {
      endpoints: [
        {
          name: "unity",
          endpoint: awsconfig.apiGateway.URL,
          region: awsconfig.apiGateway.REGION
        },
      ]
    }
  });

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
