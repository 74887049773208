import React, { memo, useEffect, useState } from 'react';
import { PopupModalContext } from "../contexts/popupModalContext";
import { LoaderButton } from './common';
import { Form, Row, Container, Col, Spinner } from 'react-bootstrap';
import { FaShoppingCart } from 'react-icons/fa';
import { isLoggedIn } from 'utils';
import { useLocalStorage } from '@rehooks/local-storage';
import { useLocation, useHistory, Link } from "react-router-dom";
import unityRequests from './unityRequests';
import { getUserDetails } from 'utils';
import { IoArrowBackCircle } from 'react-icons/io5';


function AARTOFineDetails() {
    let { handlePopupModal } = React.useContext(PopupModalContext);
    let history = useHistory();
    const [form, setForm] = useState({
        infringementNoticeNumber: '',
        location: '',
        issuingAuthority: '',
        charge: [],
        idNumber: '',
        demeritPointsValue: '',
        vehicleRegistration: '',
        status:'',
        infringementDate:'',
        amountDue:''
    });
	const [persistedCartItems, setPersistedCartItems] = useLocalStorage(
		'cartItems',
		{}
	);

	const [showPreloader, setShowPreLoader] = useState(false);
	const [isAddingTocart, setIsAddingTocart] = useState(false);
	// const [errors, setErrors] = useState({});
    const [apiError, setApiError] = useState(false);
	const [charges, setCharges] = useState([]);
	const [fine, setFine] = useState([]);

	let urlLocation = useLocation();
	let fineID = false;
    let priorPage = false;
	if (urlLocation.search.length > 0) {
        debugger;
		let urlparams = urlLocation.search.substr(1).split('&');
        urlparams.forEach((item) => {
            let hold = item.split("=");
            if (hold[0] === 'id') {
                fineID = hold[1];
            }
            else if (hold[0] === 'priorPage') {             
                if(hold[1] === '/outstandingfines') {   
                    priorPage = hold[1];                     
                }
                else{
                    priorPage = hold[1]+'='+hold[2];                
                }            
            }
        })
	}

   useEffect(() => {
		async function onLoad() {
			try {
                setShowPreLoader(true);

                let userID = "";

                if(isLoggedIn()){
                    userID = getUserDetails().data.UserID;
                }
                else{
                    userID = "GUEST";
                }

                let eNatisFineDetails = await unityRequests(
                        `/queryinfringements?Id=${userID}&noticeNumber=${fineID}`,
                        {},
                        'get'
                );


                if(eNatisFineDetails.status === 504 || eNatisFineDetails.status === 400)//if error switch preloader off and show appropriate message
                {
                    setApiError(true);
                    setShowPreLoader(false);
                }
                else
                {
                    if(eNatisFineDetails.result === true) //if no error switch set fields to data from server response
                    {
                        form.infringementNoticeNumber = eNatisFineDetails.payload[0].infringementNoticeNumber;
                        form.location = eNatisFineDetails.payload[0].location.cityTown;
                        form.issuingAuthority = eNatisFineDetails.payload[0].location.issAuthority.description;
                        form.vehicleRegistration = eNatisFineDetails.payload[0].vehicle.licenseNumber;
                        form.amountDue = eNatisFineDetails.payload[0].payment.amount;
                        if(eNatisFineDetails.payload[0].feesList.debt.length > 0) {
                            form.status = eNatisFineDetails.payload[0].feesList.debt[0].debtType;
                        }
                        else {
                            eNatisFineDetails.payload[0].customerPaymentAllowed = false;
                            form.status = "N/A";
                            form.amountDue = "N/A";
                        }
                        let date = eNatisFineDetails.payload[0].date.split("T");
                        date = date[0].replace(/-/g, '/');
                        form.infringementDate = date;
                        form.idNumber = eNatisFineDetails.payload[0].IDNumber;
                    
                        //can have more than one charge so need to make a for loop and insert charges into array then display it in form by looping through array
                        //can have more than one charge so need to make a for loop and sum up all the charges demerit points, insert sum into a variable then display it in form
                        let chargeArr = [];
                        let demeritSum = 0;
                        for(let i = 0; i < eNatisFineDetails.payload[0].charge.length; i++){
                            chargeArr.push(eNatisFineDetails.payload[0].charge[i].description);
                            demeritSum += eNatisFineDetails.payload[0].charge[i].demeritPoints;
                        }
                        form.demeritPointsValue = demeritSum;

                        setFine({...eNatisFineDetails.payload[0], api: "eNatis"});
                        //set charges
                        setCharges(chargeArr);
                        setShowPreLoader(false);
                    }
                }

			} catch (e) {
				console.log(e);
			}
		}

		onLoad();
	}, [fineID]);

//    function setField(field, value){
// 		setForm({
// 			...form,
// 			[field]: value
// 		});
// 		// Check and see if errors exist, and remove them from the error object:
// 		if (!!errors[field]) {
// 			setErrors({
// 				...errors,
// 				[field]: null
// 			});
// 		}
// 	};
   
	function getField(field){
		return form[field];
	};

	function addToCart(item) {
		if (item) {
			let cartItems = { ...persistedCartItems }; //important to not make changes to the persistedCartItems itself else it wont trigger updates
			var itemKeys = Object.keys(item);
			var fineID = '';
			if (itemKeys.includes('infringementNoticeNumber')) {
				fineID = item.infringementNoticeNumber;
			} else {
				fineID = item.noticeNumber;
			}
			if (!Object.keys(cartItems).includes(fineID)) {
				cartItems[fineID] = item;
				setPersistedCartItems(cartItems);
			} else {
				return false;
			}
		}
		return false;
	}

	function checkIfCartItemExist(item) {
		if (item) {
			let cartItems = { ...persistedCartItems }; //important to not make changes to the persistedCartItems itself else it wont trigger updates
			var itemKeys = Object.keys(item);
			var fineID = '';
			if (itemKeys.includes('infringementNoticeNumber')) {
				fineID = item.infringementNoticeNumber;
			} else {
				fineID = item.noticeNumber;
			}
            if(item.customerPaymentAllowed === false) {
                return true;
            }
			if (Object.keys(cartItems).includes(fineID)) {
				return true;
			} else {
				return false;
			}
		}
		return false;
	}

	function handleAddToCart(e) {
		e.preventDefault();
        setIsAddingTocart(true);
		addToCart(fine);
		handlePopupModal(
			'Fine:' +
				fineID +
				' has been added to shopping cart'
		);
        setIsAddingTocart(false);
	}

    function handleOutsideCheckout(e){
        handleAddToCart(e);
        history.push('/Cart');
    }

	return (
		<div className="center-to-screen pb-3 pt-3">
        {showPreloader ? (
                <div>
                    <Spinner
                        animation="border"
                        role="status"
                        style={{ display: 'block', margin: '0 auto' }}
                    >
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
            <Container>
                {apiError ? (<Row><Col md="12" style={{textAlign:'center'}}><h3>eNatis is down.</h3></Col></Row>) : (
                <>
                    <Row>
                        <Col md="12">
                            <Form className="form">
                                <h1>Fine Details - AARTO</h1>
                                <Row>
                                    <Col className="pr-1 mt-1 mb-1" md={5}>
                                        <div className="mt-1 mb-3 details-screen-boxes" style={{display:'flex', backgroundColor:'#FFFDF2', borderRadius:'40px', width:'auto'}}>
                                            <Col xs={4} md={4} style={{marginTop:'auto', marginBottom:'auto'}}>
                                                <label style={{marginBottom:'0px', color: '#89959F'}}>
                                                    Infringement Number:
                                                </label>
                                            </Col>
                                            <Col md={8}>
                                                <Form.Control
                                                    readOnly
                                                    type="text"
                                                    id="strInfringementNumber"
                                                    value={getField('infringementNoticeNumber')}
                                                    style={{width:'auto', background:'transparent', border:'none', padding:'0px', color: '#5F6F7C'}}
                                                ></Form.Control>
                                            </Col>
                                        </div>
                                    </Col>
                                    <Col className="pr-1 mt-1 mb-1" md={{span: 5, offset:2}}>
                                        <div className="mt-1 mb-3 details-screen-boxes" style={{display:'flex', backgroundColor:'#FFFDF2', borderRadius:'40px', width:'auto'}}>
                                            <Col xs={4} md={4} style={{marginTop:'auto', marginBottom:'auto'}}>
                                                <label style={{marginBottom:'0px', color: '#89959F'}}>
                                                    Status:
                                                </label>
                                            </Col>
                                            <Col md={8}>
                                                <Form.Control
                                                    disabled
                                                    type="text"
                                                    id="strStatus"
                                                    value={getField('status')}
                                                    style={{background:'transparent', border:'none', padding:'0px', color: '#5F6F7C'}}
                                                ></Form.Control>
                                            </Col>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                <Col className="pr-1 mt-1 mb-1" md={5}>
                                    <div className="mt-1 mb-3 details-screen-boxes" style={{display:'flex', backgroundColor:'#FFFDF2', borderRadius:'40px', width:'auto'}}>
                                        <Col xs={4} md={4} style={{marginTop:'auto', marginBottom:'auto'}}>
                                            <label style={{marginBottom:'0px', color: '#89959F'}}>
                                                Issuing Authority:
                                            </label>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Control
                                                disabled
                                                type="text"
                                                id="strIssuingAuthority"
                                                value={getField('issuingAuthority')}
                                                style={{width:'250px', background:'transparent', border:'none', padding:'0px', color: '#5F6F7C'}}
                                            ></Form.Control>
                                            </Col>
                                        </div>
                                    </Col>
                                    <Col className="pr-1 mt-1 mb-1" md={{span: 5, offset:2}}>
                                        <div className="mt-1 mb-3 details-screen-boxes" style={{display:'flex', backgroundColor:'#FFFDF2', borderRadius:'40px', width:'auto'}}>
                                            <Col xs={4} md={4} style={{marginTop:'auto', marginBottom:'auto'}}>
                                                <label style={{marginBottom:'0px', color: '#89959F'}}>
                                                Demerit Points:
                                                </label>
                                            </Col>
                                            <Col md={8}>
                                                <Form.Control
                                                    readOnly
                                                    type="text"
                                                    id="strDemeritPoints"
                                                    value={getField('demeritPointsValue')}
                                                    style={{width:'auto', background:'transparent', border:'none', padding:'0px', color: '#5F6F7C'}}
                                                ></Form.Control>
                                            </Col>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="pr-1 mt-1 mb-1" md={5}>
                                        <div className="mt-1 mb-3 details-screen-boxes" style={{display:'flex', backgroundColor:'#FFFDF2', borderRadius:'40px', width:'auto'}}>
                                            <Col xs={4} md={4} style={{marginTop:'auto', marginBottom:'auto'}}>
                                                <label style={{marginBottom:'0px', color: '#89959F'}}>
                                                    ID Number:
                                                </label>
                                            </Col>
                                            <Col md={8}>
                                                <Form.Control
                                                    disabled
                                                    type="text"
                                                    id="strIDNumber"
                                                    value={isLoggedIn() ? (getField('idNumber') ? getField('idNumber') : "N/A" ) : "*"}
                                                    style={{width:'auto', background:'transparent', border:'none', padding:'0px', color: '#5F6F7C'}}
                                                ></Form.Control>
                                            </Col>
                                        </div>
                                    </Col>
                                    <Col className="pr-1 mt-1 mb-1" md={{span: 5, offset:2}}>
                                        <div className="mt-1 mb-3 details-screen-boxes" style={{display:'flex', backgroundColor:'#FFFDF2', borderRadius:'40px', width:'auto'}}>
                                            <Col xs={4} md={4} style={{marginTop:'auto', marginBottom:'auto'}}>
                                                <label style={{marginBottom:'0px', color: '#89959F'}}>
                                                    Amount Due:
                                                </label>
                                            </Col>
                                            <Col md={8}>
                                                <Form.Control
                                                    disabled
                                                    type="text"
                                                    id="strAmountDue"
                                                    value={getField('amountDue')}
                                                    style={{width:'auto', background:'transparent', border:'none', padding:'0px', color: '#5F6F7C'}}
                                                ></Form.Control>
                                            </Col>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="pr-1" md={5}>
                                        <div className="mt-1 mb-3 details-screen-boxes" style={{display:'flex', backgroundColor:'#FFFDF2', borderRadius:'40px', width:'auto'}}>
                                            <Col xs={4} md={4} style={{marginTop:'auto', marginBottom:'auto'}}>
                                                <label style={{marginBottom:'0px', color: '#89959F'}}>
                                                    Vehicle Registration:
                                                </label>
                                            </Col>
                                            <Col md={8}>
                                                <Form.Control
                                                    disabled
                                                    type="text"
                                                    id="strVehicleRegistration"
                                                    value={getField('vehicleRegistration')}
                                                    style={{width:'auto', background:'transparent', border:'none', padding:'0px', color: '#5F6F7C'}}
                                                ></Form.Control>
                                            </Col>
                                        </div>
                                        <div className="mt-1 mb-3 details-screen-boxes" style={{display:'flex', backgroundColor:'#FFFDF2', borderRadius:'40px', width:'auto'}}>
                                            <Col xs={4} md={4} style={{marginTop:'auto', marginBottom:'auto'}}>
                                                <label style={{marginBottom:'0px', color: '#89959F'}}>
                                                    Infringement Date:
                                                </label>
                                            </Col>
                                            <Col md={8}>
                                                <Form.Control
                                                    disabled
                                                    type="text"
                                                    id="strInfringementDate"
                                                    value={getField('infringementDate')}
                                                    style={{width:'auto', background:'transparent', border:'none', padding:'0px', color: '#5F6F7C'}}
                                                ></Form.Control>
                                        </Col>
                                        </div>
                                        <div className="mt-1 mb-3 details-screen-boxes" style={{display:'flex', backgroundColor:'#FFFDF2', borderRadius:'40px', width:'auto'}}>
                                            <Col xs={4} md={4} style={{marginTop:'auto', marginBottom:'auto'}}>
                                                <label style={{marginBottom:'0px', color: '#89959F'}}>
                                                    Location:
                                                </label>
                                            </Col>
                                            <Col md={8}>
                                                <Form.Control
                                                    disabled
                                                    type="text"
                                                    id="strLocation"
                                                    value={getField('location')}
                                                    style={{width:'auto', background:'transparent', border:'none', padding:'0px', color: '#5F6F7C'}}
                                                ></Form.Control>
                                            </Col>
                                        </div>
                                    </Col>
                                    <Col md={{span: 5, offset:2}}>
                                    {(charges.length > 0 && charges.length <= 3) && charges.map((charge, index) => {
                                            return (
                                                <div className="mt-1 mb-3 details-screen-boxes" key={charge.chargeCode} style={{display:'flex', backgroundColor:'#FFFDF2', borderRadius:'40px', width:'auto'}}>
                                                    <Col xs={4} md={3} style={{marginTop:'auto', marginBottom:'auto'}}>
                                                        <label style={{marginBottom:'0px', color: '#89959F'}}>
                                                            {"Charge "+[index+1]+":"}
                                                        </label>
                                                    </Col>
                                                    <Col xs={8} md={9}>
                                                        <Form.Control
                                                            disabled
                                                            className="textarea-charges"
                                                            as="textarea"
                                                            rows={2}
                                                            id={"strCharge"+[index+1]}
                                                            value={charge}
                                                            style={{border:'none', color: '#5F6F7C', "overflowWrap": 'normal'}}
                                                        ></Form.Control>
                                                    </Col>
                                                </div>
                                            );
                                        })}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={{span:1, offset:3}} className="mt-3">
                                        {priorPage? (
                                            <Link to={priorPage} href={"/"+priorPage}>
                                                <IoArrowBackCircle 
                                                    style={{ "fontSize": '40px' }}
                                                    className="isNotDisabledBtnIconClass"
                                                />
                                            </Link>
                                        ) : (<></>)}
                                    </Col>
                                    <Col md={{span:4}}>
                                        {isLoggedIn() ? (
                                            <LoaderButton
                                                type="submit"
                                                className="btn-grey btn-block mt-3"
                                                isLoading={isAddingTocart}
                                                onClick={handleAddToCart}
                                                disabled={checkIfCartItemExist(fine)}
                                            >
                                                Add to Cart{' '}
                                                <FaShoppingCart style={{ fontSize: '20px' }} />
                                            </LoaderButton>
                                        ) : (<></>)}
                                    </Col>
                                </Row>
                                <div className="clearfix"></div>
                            
                            </Form>
                        </Col>
                    </Row>
                </>
            )}
            </Container>
        )}
		</div>
	);
}

export default memo(AARTOFineDetails);
