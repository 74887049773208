import React, { memo } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from 'utils/history';
import Routing from './Routing';

function Routes() {
	return (
		<Router history={history}>
			<Switch>
				<Route path="">
					<Routing />
				</Route>
			</Switch>
		</Router>
	)
}

export default memo(Routes);

