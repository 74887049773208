/*
* These are the placeholder roles you can replace these with yours
*/
let roles = {
	SUPER_ADMIN: 'SUPER_ADMIN',
	ADMIN: 'ADMIN',
	MANAGER: 'MANAGER',
	CUSTOMER: 'CUSTOMER',
	GUEST: 'GUEST'
};

export default roles