import React from "react";
import { PopupModalContext } from "../../contexts/popupModalContext";
import { Row, Col, Button, Modal } from 'react-bootstrap';

const PopupModal = () => {
    let { popupModalContent, handlePopupModal, popupModal } = React.useContext(PopupModalContext);
    return (
        <Modal show={popupModal} onHide={(e) => handlePopupModal()}>
            <Modal.Body>
                <Row>
                    <Col>
                        <p style={{ textAlign: 'center' }}>
                            {popupModalContent}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button
                            className="btn-grey float-end mb-2"
                            variant=""
                            onClick={(e) => handlePopupModal()}
                            size="sm"
                        >
                            Close
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default PopupModal;
