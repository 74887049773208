import React, { memo, useState } from 'react';
import { PopupModalContext } from "../contexts/popupModalContext";
import { Form, Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { LoaderButton } from './common';
import { Auth , API } from 'aws-amplify';
import {
	FormGroup,
	FormControl
  } from "react-bootstrap";
import { BsCheckCircle } from 'react-icons/bs';
import { validateFieldType } from 'utils';

function ForgotPassword() {
    let history = useHistory();
    let { handlePopupModal } = React.useContext(PopupModalContext);
	const [codeSent, setCodeSent] = useState(false);
	const [confirmed, setConfirmed] = useState(false);
    const [passworded, setPassworded] = useState(false);
	const [isConfirming, setIsConfirming] = useState(false);
	const [isSendingCode, setIsSendingCode] = useState(false);
    const [showUserNotExist, setShowUserNotExist] = useState(false);
	const [form, setForm] = useState({
		"code": "",
		"email":"",
		"confirmEmail":"",
		"password": "",
		"confirmPassword":""
	});
	const [errors, setErrors] = useState({});

	const setField = (field, value) => {
		setForm({
			...form,
			[field]: value
		});
		// Check and see if errors exist, and remove them from the error object:
		if (!!errors[field]) {
			setErrors({
				...errors,
				[field]: null
			});
		}
	}

	const getField = (field) => {
		return form[field];
	}

    const userExists = async (username) => {
        // adapted from @herri16's solution: https://github.com/aws-amplify/amplify-js/issues/1067#issuecomment-436492775
        let response = false;
        try {
          const res = await Auth.confirmSignUp(username, '000000', {
            // If set to False, the API will throw an AliasExistsException error if the phone number/email used already exists as an alias with a different user
            forceAliasCreation: false
          });
          // this should always throw an error of some kind, but if for some reason this succeeds then the user probably exists.
          response = false;
        } catch (err) {
          switch ( err.code ) {
            case 'UserNotFoundException':
                response = true;
                break;
            case 'NotAuthorizedException':
                response = false;
                break;
            case 'AliasExistsException':
                // Email alias already exists
                response = false;
                break;
            case 'CodeMismatchException':
                response = false;
                break;
            case 'ExpiredCodeException':
                response = false;
                break;
            default:
                response = false;
                break;
          }
        }
        return !response
      }

	const validateCodeForm = () => {
		const {email, confirmEmail} = form;
		const newErrors = {};

		if(!email || email === '') {
			newErrors.email = 'Cannot be blank!';
		}
        if(!validateFieldType(email, "Email")) {
            newErrors.email = 'Email is invalid!';
        }

		if(!confirmEmail || confirmEmail === '') {
			newErrors.confirmEmail = 'Cannot be blank!';
		}
		else if(confirmEmail !== email) {
			newErrors.confirmEmail = 'Emails must match!!';
		}

		return newErrors;
	}

	async function handleSendCodeClick(e) {
		e.preventDefault();
		setIsSendingCode(true);
		// get our new errors
		const newErrors = validateCodeForm();
		// Conditional logic:
		if(Object.keys(newErrors).length > 0) {
		  // We got errors!
		  setErrors(newErrors);
		  setIsSendingCode(false);
		}
		else {
            let body = await API.get(
                'unity',
                `/user/details?strEmail=${getField("email")}`
            );
            if (body.result === true) {
                let resp = await userExists(getField("email"));
                if(resp === true){
                    try {
                        await Auth.forgotPassword(getField("email"));
                        setCodeSent(true);
                    }
                    catch(error) {
                        setIsSendingCode(false);
                        handlePopupModal(error.message);
                    }
                }
                else {
                    setCodeSent(true);
                    setPassworded(true);
                }
            }
            else {
                setIsSendingCode(false);
                setShowUserNotExist(true);
            }
		}
	}

    const handleUserNotExist = (proceed) => {
        if (proceed) {
			history.push('/register');
		} else {
			setShowUserNotExist(false);
		}
    };

	function renderRequestCodeForm() {
		return (
        <>
            <Modal
                show={showUserNotExist}
                onHide={() => handleUserNotExist(false)}
                backdrop={true}
                keyboard={true}
            >
                <Modal.Header>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Your account has not been found. Please select yes to
                    redirect to the registration page
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="button-l"
                        bsstyle="primary"
                        onClick={() => handleUserNotExist(true)}
                    >
                        Yes
                    </Button>
                    <Button onClick={() => handleUserNotExist(false)}>No</Button>
                </Modal.Footer>
            </Modal>
            <div className="center-to-screen pt-3">
                <Container>
                    <Row>
                        <Col md={{span:6, offset:3}}>
                            <Form className="form">
                                <h1>Forgot password</h1>
                                <Form.Group controlId="formBasicEmail" >
                                    <Form.Control
                                        type="email" 
                                        placeholder="Email" 
                                        value={getField('email')} 
                                        onChange={e => setField('email', e.target.value)}
                                        isinvalid={ !!errors.email ? "true" : "false" }
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        { errors.email }
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="formBasicConfirmEmail" >
                                    <Form.Control
                                        type="email" 
                                        placeholder="Confirm Email" 
                                        value={getField('confirmEmail')} 
                                        onChange={e => setField('confirmEmail', e.target.value)}
                                        isinvalid={ !!errors.confirmEmail ? "true" : "false" }
                                    />
                                    <Form.Control.Feedback type='invalid'>
                                        { errors.confirmEmail }
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Row>
                                    <Col md={{ span: 4, offset: 4}}>
                                        <LoaderButton
                                            block
                                            type="submit"
                                            bssize="large"
                                            isLoading={isSendingCode}
                                            onClick={handleSendCodeClick}
                                        >
                                            Send Confirmation
                                        </LoaderButton>
                                    </Col>
                                </Row>

                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
		)
	}

    const validatePassForm = () => {
		const {password, confirmPassword} = form;
		const newErrors = {};

		if(!password || password === '') {
			newErrors.password = 'Cannot be blank!';
		}

		if(!confirmPassword || confirmPassword === '') {
			newErrors.confirmPassword = 'Cannot be blank!';
		}
		else if(confirmPassword !== password) {
			newErrors.confirmPassword = 'Passwords must match!!';
		}

		return newErrors;
	}

    async function handlePassClick(e) {
		e.preventDefault();
		setIsConfirming(true);
		// get our new errors
		const newErrors = validatePassForm();
		// Conditional logic:
		if(Object.keys(newErrors).length > 0) {
		  // We got errors!
		  setErrors(newErrors);
		  setIsConfirming(false);
		}
		else {
			try {
                localStorage.setItem('asdf', getField('password'));
                await Auth.signUp({"username": getField('email'), "password": getField('password'), "attributes": {"email": getField('email')}});
                history.push('/register?verify=' + getField('email'));
			}
			catch(error) {
				setIsConfirming(false);
				handlePopupModal(error.message);
			}
		}
	}

    function renderPasswordForm() {
		return (
		<div className="center-to-screen pt-3">
			<Container>
				<Row>
					<Col md={{span:6, offset:3}}>
						<Form className="form">
							<h1>Forgot password</h1>
							<FormGroup bssize="large" controlId="password">
									<Form.Label>New Password</Form.Label>
									<FormControl
										type="password"
										value={getField('password')}
										onChange={e => setField('password', e.target.value)}
									/>
                                    <Form.Control.Feedback type='invalid'>
                                        { errors.password }
                                    </Form.Control.Feedback>
								</FormGroup>
								<FormGroup bssize="large" controlId="confirmPassword">
									<Form.Label>Confirm Password</Form.Label>
									<FormControl
										type="password"
										value={getField('confirmPassword')}
										onChange={e => setField('confirmPassword', e.target.value)}
									/>
                                    <Form.Control.Feedback type='invalid'>
                                        { errors.confirmPassword }
                                    </Form.Control.Feedback>
								</FormGroup>
                                <Col md={{span:3, offset:5}}>
                                    <LoaderButton
                                        block
                                        type="submit"
                                        bssize="large"
                                        isLoading={isConfirming}
                                        onClick={handlePassClick}
                                    >
                                        Confirm
                                    </LoaderButton>
                                </Col>
						</Form>
					</Col>
				</Row>
			</Container>
		</div>
		)
	}

	const validateConfirmForm = () => {
		const {code, password, confirmPassword} = form;
		const newErrors = {};

		if(!code || code === '') {
			newErrors.code = 'Cannot be blank!';
		}


		if(!password || password === '') {
			newErrors.password = 'Cannot be blank!';
		}

		if(!confirmPassword || confirmPassword === '') {
			newErrors.confirmPassword = 'Cannot be blank!';
		}
		else if(confirmPassword !== password) {
			newErrors.confirmPassword = 'Passwords must match!!';
		}

		return newErrors;
	}

	async function handleConfirmClick(e) {
		e.preventDefault();
		setIsConfirming(true);
		// get our new errors
		const newErrors = validateConfirmForm();
		// Conditional logic:
		if(Object.keys(newErrors).length > 0) {
		  // We got errors!
		  setErrors(newErrors);
		  setIsConfirming(false);
		}
		else {
			try {
				await Auth.forgotPasswordSubmit(
					getField('email'),
					getField('code'),
					getField('password')
				);
				setConfirmed(true);
			}
			catch(error) {
				setIsConfirming(false);
				handlePopupModal(error.message);
			}
		}
	}

	function renderConfirmationForm() {
		return (
			<div className="center-to-screen pt-3">
				<Container>
					<Row>
						<Col md={{span:6, offset:3}}>
							<Form className="form">
								<h1>Confirm password reset</h1>
								<FormGroup bssize="large" controlId="code">
									<Form.Label>Confirmation Code</Form.Label>
									<FormControl
										autoFocus
										type="tel"
										value={getField('code')}
										onChange={e => setField('code', e.target.value)}
									/>
									{"Please check your email ("+getField('email')+") for the confirmation code."}
                                    <Form.Control.Feedback type='invalid'>
                                        { errors.code }
                                    </Form.Control.Feedback>
								</FormGroup>
								<hr />
								<FormGroup bssize="large" controlId="password">
									<Form.Label>New Password</Form.Label>
									<FormControl
										type="password"
										value={getField('password')}
										onChange={e => setField('password', e.target.value)}
									/>
                                    <Form.Control.Feedback type='invalid'>
                                        { errors.password }
                                    </Form.Control.Feedback>
								</FormGroup>
								<FormGroup bssize="large" controlId="confirmPassword">
									<Form.Label>Confirm Password</Form.Label>
									<FormControl
										type="password"
										value={getField('confirmPassword')}
										onChange={e => setField('confirmPassword', e.target.value)}
									/>
                                    <Form.Control.Feedback type='invalid'>
                                        { errors.confirmPassword }
                                    </Form.Control.Feedback>
								</FormGroup>
                                <Col md={{span:3, offset:5}}>
                                    <LoaderButton
                                        block
                                        type="submit"
                                        bssize="large"
                                        isLoading={isConfirming}
                                        onClick={handleConfirmClick}
                                    >
                                        Confirm
                                    </LoaderButton>
                                </Col>
							</Form>
						</Col>
					</Row>
				</Container>
			</div>
		);
	  }
	
	function renderSuccessMessage() {
		return (
			<div className="center-to-screen pt3">
				<Container>
					<Row>
						<Col md={{span:6, offset:3}}>
						<h1>Password reset</h1>
						<div className="success">
							<BsCheckCircle/>
							<p className="d-inline pl-3">Your password has been reset.</p>
							<p>
								<Link to="/login">
									Click here to login with your new credentials.
								</Link>
							</p>
						</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}

	return (
		<div className="ResetPassword">
		  {!codeSent
			? renderRequestCodeForm()
			: (!confirmed
			? (passworded? renderPasswordForm() : renderConfirmationForm())
			: renderSuccessMessage())}
		</div>
	)
}

export default memo(ForgotPassword);
