import React, { memo, useState, useEffect } from 'react';
import { ExportableTable, Graphs } from './common';
import { PopupModalContext } from "../contexts/popupModalContext";
import { Form, Container, Row, Col, Jumbotron, Spinner } from 'react-bootstrap';
//import unityRequests from './unityRequests';
import { getUserDetails } from 'utils';
import graphData from './dashboardTestData.js';

function AdminDashboardTest() {
    let currDate = new Date().toISOString().substr(0, 10);
    let priorDate = new Date();
    const month = priorDate.getMonth();
    priorDate.setMonth(priorDate.getMonth() - 1);

    while (priorDate.getMonth() === month) {
        priorDate.setDate(priorDate.getDate() - 1);
    }
    priorDate = priorDate.toISOString().substr(0, 10);

    let { handlePopupModal } = React.useContext(PopupModalContext);
    const [showPreloader, setShowPreLoader] = useState(false);
    const [graphs, setGraphs] = useState([]);
    const [fromDate, setFromDate] = useState(priorDate);
    const [toDate, setToDate] = useState(currDate);

    useEffect(() => {
        var onLoad = async () => {
            setShowPreLoader(true);

            //get the shematics of the graphs
            let params = []
            params.push("userId="+getUserDetails().data.UserID);

            if(fromDate !== false) {
                params.push("startDate="+fromDate);
            }
            if(toDate !== false) {
                params.push("endDate="+toDate);
            }

            //for the example dashboard we are using testing data
            /*let graphSchematics = await unityRequests(
                `/getgraphs?${params.join("&")}`,
                {},
                'get'
            );*/
            var graphdata = graphData();
            let graphSchematics = {
                "result": true,
                "payload": [
                    {
                        "GraphTitle": "Total Fines Per Camera Type",
                        "GraphType": "LineMarkGraph",
                        "GraphX": "cameraType",
                        "GraphY": "",
                        "Data": graphdata.lineCameraDataArr
                    },
                    {
                        "GraphTitle": "Total Fines Per Camera Type",
                        "GraphType": "VerticalBarGraph",
                        "GraphX": "cameraType",
                        "GraphY": "amount",
                        "Data": graphdata.lineCameraDataArr
                    },
                    {
                        "GraphTitle": "Total Fines Per Location",
                        "GraphType": "StackGraph",
                        "GraphX": "Location",
                        "GraphY": "",
                        "Data": graphdata.stackedCameraArr
                    },
                    {
                        "GraphTitle": "Total Fines Per Camera Type",
                        "GraphType": "PieGraph",
                        "GraphX": "cameraType",
                        "GraphY": "amount",
                        "Data": graphdata.radialCameraDataArr
                    },
                    {
                        "GraphTitle": "Total Fines Per Camera Type",
                        "GraphType": "AreaGraph",
                        "GraphX": "cameraType",
                        "GraphY": "amount",
                        "Data": graphdata.lineCameraDataArr
                    },
                    {
                        "GraphTitle": "Total Fines Per Camera Type",
                        "GraphType": "RadarGraph",
                        "GraphX": "cameraType",
                        "GraphY": "amount",
                        "Data": graphdata.lineCameraDataArr
                    },
                    {
                        "GraphTitle": "Fines",
                        "GraphType": "Table",
                        "GraphX": "",
                        "GraphY": "",
                        "Data": graphdata.tableData,
                        "tableConfig": graphdata.tableConfig
                    }
                ]
            }

            if(graphSchematics.result !== true) {
                handlePopupModal("There was an error fetching the graphs!")
            }
            else {
                setGraphs(graphSchematics.payload);
            }

            setShowPreLoader(false);
        }

        onLoad();
    }, [fromDate, toDate]);

    function displayGraph(graph, currentGraphNo){
        if(graph.GraphType === "Table"){
            return (<Col md={12} key={currentGraphNo}>
                        <ExportableTable title={graph.GraphTitle} config={graph.tableConfig} data={graph.Data}/>
                    </Col>
                )
        }
        else {
            return (
                <Col md={6} key={currentGraphNo}>
                    <Jumbotron fluid
                        style={{padding:'20px'}}
                    >
                        <h5 style={{textTransform:'uppercase'}}>{graph.GraphTitle}</h5>
                        <Graphs type={graph.GraphType} data={graph.Data} GraphX={graph.GraphX} GraphY={graph.GraphY}/>
                    </Jumbotron>
                </Col>
            );
        }
    }

    let numRows = Math.floor(graphs.length/2);
    if(numRows !== graphs.length/2) {
        numRows = numRows+1;
    }

    let displayGraphs = [];
    let currentGraphNo = -1;
    for(let i = 0; i < numRows; i++) {
        let hold = [];
        if(graphs[currentGraphNo+1]){
            currentGraphNo++;
            hold.push(displayGraph(graphs[currentGraphNo], currentGraphNo))
        }
        if(graphs[currentGraphNo+1]){
            currentGraphNo++;
            hold.push(displayGraph(graphs[currentGraphNo], currentGraphNo))
        }
        displayGraphs.push(hold);
    }

	return (
		<>
            {showPreloader ? (
                <div>
                    <Spinner
                        animation="border"
                        role="status"
                        style={{ display: 'block', margin: '0 auto' }}
                    >
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <div className="center-to-screen pb-3 pt-3">
                    <Container>
                        {graphs.length === 0 ? (
                            <Row>
                                <Col style={{textAlign:'center'}}>
                                    No Graphs
                                </Col>
                            </Row>
                        ) : (
                            <>
                                <Row>
                                    <Col>
                                        <h1 className="form-header">Admin Dashboard Test</h1>
                                    </Col>
                                </Row>
                                <Row>
                                    <Form className="form">
                                        <Row>
                                            <Col className="pr-1" md={6}>
                                                <Form.Group>
                                                    <label style={{ color: '#000' }}>Start Date:</label>
                                                    <Form.Control
                                                        type="date"
                                                        id="startDate"
                                                        defaultValue={fromDate}
                                                        onChange={(e) => {setFromDate(e.target.value)}}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col className="pr-1" md={6}>
                                                <Form.Group>
                                                    <label style={{ color: '#000' }}>End Date:</label>
                                                    <Form.Control
                                                        type="date"
                                                        id="endDate"
                                                        defaultValue={toDate}
                                                        onChange={(e) => {setToDate(e.target.value)}}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Row>
                                {displayGraphs.map((graphRow, rowNo) => {
                                    return(<Row key={rowNo}>
                                        {graphRow[0]? graphRow[0]: <></>}
                                        {graphRow[1]? graphRow[1]: <></>}
                                    </Row>)
                                })}
                            </>
                        )}
                    </Container>
                </div>
            )}
		</>
	);
}

export default memo(AdminDashboardTest);
