import { API } from "aws-amplify";

async function unityRequests(path, vars = {} , action = "post") { 
	// const user = Auth.currentSession();
   const apiName = 'unity';
	let result = {};

	if(action === "get"){
		await API.get(apiName, path, vars)
      .then(response => {
         result = {...response};
         //console.log(response);
      })
      .catch(error => { 
         result = {status: error.response.status};
         console.log(error.response.status);
      });

	}else{
		await API.post(apiName, path, {body:vars})
      .then(response => {
         result = {...response};
         //console.log(response);
      })
      .catch(error => { 
         result = {status: error.response.status};
         console.log(error.response.status);
      });
	}	
	return result;
}

export default unityRequests;