import React, { useEffect, useState } from 'react';
import { FaShoppingCart, FaUser } from 'react-icons/fa';
import { Navbar, Nav, NavDropdown, Image, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { isLoggedIn } from 'utils';
import { Auth } from 'aws-amplify';
import { useLocalStorage, deleteFromStorage } from '@rehooks/local-storage';
import logoImg from 'assets/img/pmf_logo_pos_1.svg';

function TopNav(props) {
	let history = useHistory();
	const [userDetails] = useLocalStorage('userDetails', {});
	const [currentCartItems] = useLocalStorage('cartItems', {});
	let [itemCount, setItemCount] = useState(0);
	let [username, setUsername] = useState("");
	const [issuingAuthList, setIssuingAuthList] = useLocalStorage(
		'issuingAuthList',
		[]
	);

	useEffect(() => {
		setItemCount(Object.keys({ ...currentCartItems }).length);

        if(isLoggedIn()){
            setUsername(userDetails.data.strName)
        }

	}, [currentCartItems, userDetails]);

	async function handleLogout() {
		try {
			await Auth.signOut();
			deleteFromStorage('roles');
			deleteFromStorage('userDetails');
			deleteFromStorage('userProfileDetails');
			deleteFromStorage('cartItems');
			deleteFromStorage('IDNumbers');
            deleteFromStorage('backFilters');
			setIssuingAuthList([]);
			history.push('/');
		} catch (error) {
			console.log('error signing out: ', error);
		}
	}

	function handleCheckout() {
		history.push('/Cart');
	}

	return (
        <Navbar collapseOnSelect key="navBar" bg="light" expand="lg" className="customNav">
            <Container>
				{userDetails && userDetails !== undefined && userDetails !== null && JSON.stringify(userDetails) !== "{}" ? (
                    <Navbar.Brand key="home" href="/"><Image className="affiliatesImg" src={logoImg} style={{padding:'10px', width:'180px', height:'70px'}} fluid/></Navbar.Brand>
				) : (
					<Navbar.Brand key="home" href="/"><Image className="affiliatesImg" src={logoImg} style={{padding:'10px', width:'180px', height:'70px'}} fluid/></Navbar.Brand>
				)}

				<Navbar.Toggle key="navToggle" aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse key="navCollapse" id="responsive-navbar-nav">
					<Nav key="navLinks" className="topNavLinks">
						{props.routes.map(({ path, title, canNavigate, children, ...rest }, routeNo) => {
							if (canNavigate) {
								if(children) {
									return (
										<>
											<NavDropdown key={path+routeNo} title={title} id="collasible-nav-dropdown">
												{children.map(({ ...subProperties }) => {
													if (subProperties.canNavigate) {
														return (
															<>
																<Nav.Link key={subProperties.path+routeNo} to={subProperties.path} href={subProperties.path} style={{lineHeight:"0"}}>
																	{subProperties.title}
																</Nav.Link>
																<br />
															</>
														);
													}
													else {
														return (<></>);
													}
												})}
											</NavDropdown>
										</>
									);
								}
								else {
									return (
                                        <Nav.Link className="w3-bar-item" key={path+routeNo} to={`${path}`} href={`${path}`}>
                                            {title}
                                        </Nav.Link>
									);
								}
							}
							else {
								return <></>;
							}
						})}
						{isLoggedIn() && (
							<>
                                <Nav.Link key="logout" onClick={handleLogout}>
                                    <Navbar.Text className="onclickNavLinks">
                                        Logout
                                    </Navbar.Text>
                                </Nav.Link>
                                <Nav.Link key="username" style={{ lineHeight: "0px"}}>
                                    <Navbar.Text className="onclickNavLinks">
                                        <FaUser />{" "}{" "}
                                        {username.length > 22? username.substr(0, 22)+"...": username}
                                    </Navbar.Text>
                                </Nav.Link>
                                <Nav.Link key="cart" style={{ lineHeight: '0px' }} onClick={handleCheckout}>
                                    <Navbar.Text className="onclickNavLinks">
                                        <FaShoppingCart /> {itemCount}
                                    </Navbar.Text>
                                </Nav.Link>
							</>
						)}
					</Nav>
				</Navbar.Collapse>
            </Container>
        </Navbar>
	);
}

TopNav.propTypes = {
	routes: PropTypes.arrayOf(
		PropTypes.shape({
			path: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired
		})
	).isRequired,
	prefix: PropTypes.string,
	className: PropTypes.string
};

TopNav.defaultProps = {
	prefix: '',
	className: ''
};

export default TopNav;
