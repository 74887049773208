import React, { memo, useState, useEffect } from 'react';
import { PopupModalContext } from "../contexts/popupModalContext";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Table, Button, Spinner } from 'react-bootstrap';
import { FaTrashAlt } from 'react-icons/fa';
import { useLocalStorage } from '@rehooks/local-storage';
import unityRequests from './unityRequests';
import { getUserDetails, generateUUID } from 'utils';
import { useWindowDimensions } from "../hooks";
import { LoaderButton } from './common';
import awsconfig from '../aws-config';

function Cart() {
    const { width } = useWindowDimensions();
    let { handlePopupModal } = React.useContext(PopupModalContext);
	const [persistedCartItems, setPersistedCartItems] = useLocalStorage(
		'cartItems',
		{}
	);
	const [cartConvenienceTotal, setCartConvenienceTotal] = useState(0);
    const [defaultConvenienceFee, setDefaultConvenienceFee] = useState(0);
	const [cartTotal, setCartTotal] = useState(0);
    const [showPreloader, setShowPreLoader] = useState(false);
    const [isCheckingOut, setIsCheckingOut] = useState(false);
    const [cartCheckout, setcartCheckout] = useLocalStorage(
        'cartCheckout',
        {}
    );

	useEffect(() => {
        async function onLoad() {
			try {
				setShowPreLoader(true);
				let userID = getUserDetails().data.UserID;

                let settingResponse = await unityRequests(
					`/getsettings?userId=${userID}`,
					{},
					'get'
				);

                let convAmt = 0;
                settingResponse.payload.forEach((setting)=>{
                    if(setting.strKey.trim() === "convenience"){
                        convAmt = setting.strValue;
                    } 
                });

                setDefaultConvenienceFee(convAmt);
		        total(convAmt);
				setShowPreLoader(false);
            }
            catch(e){
                console.log(e)
            }
		}

		onLoad();
	}, []);

    useEffect(() => {

        total(defaultConvenienceFee);

	}, [persistedCartItems]);

	function total(convAmt) {
		let cartItems = {...persistedCartItems};
		let keys = Object.keys(cartItems);
		let totalVal = 0;
		for (let i = 0; i < keys.length; i++) {
			if (
				Object.keys(cartItems[keys[i]]).includes(
					'infringementNoticeNumber'
				)
			) {
				for (var j in cartItems[keys[i]].feesList.debt) {
					totalVal += Number(
						cartItems[keys[i]].feesList.debt[j].debtAmt
					);
				}
			} else {
				totalVal += Number(cartItems[keys[i]].totalAmountDue);
			}
		}
        
        let convenienceTotal = keys.length * convAmt;
        setCartConvenienceTotal(convenienceTotal)
		setCartTotal(totalVal+convenienceTotal);
	}

	/*function addToCart(item) {
		if (item) {
			let cartItems = { ...persistedCartItems }; //important to not make changes to the persistedCartItems itself else it wont trigger updates
			let itemKeys = Object.keys(item);
			let fineID = '';
			if (itemKeys.includes('infringementNoticeNumber')) {
				fineID = item.infringementNoticeNumber;
			} else {
				fineID = item.noticeNumber;
			}
			if (!Object.keys(cartItems).includes(fineID)) {
				cartItems[fineID] = item;
				setPersistedCartItems(cartItems);
			} else {
				return false;
			}
		}
		return false;
	}*/

	function removeFromCart(item) {
		if (item) {
			let cartItems = { ...persistedCartItems }; //important to not make changes to the persistedCartItems itself else it wont trigger updates
			let itemKeys = Object.keys(item);
			let fineID = '';
			if (itemKeys.includes('infringementNoticeNumber')) {
				fineID = item.infringementNoticeNumber;
			} else {
				fineID = item.noticeNumber;
			}
			if (Object.keys(cartItems).includes(fineID)) {
				delete cartItems[fineID];
				setPersistedCartItems(cartItems);
			} else {
				return false;
			}
		}
		return false;
	}

	/*function checkIfCartItemExist(item) {
		if (item) {
			let cartItems = { ...persistedCartItems }; //important to not make changes to the persistedCartItems itself else it wont trigger updates
			var itemKeys = Object.keys(item);
			var fineID = '';
			if (itemKeys.includes('infringementNoticeNumber')) {
				fineID = item.infringementNoticeNumber;
			} else {
				fineID = item.noticeNumber;
			}
            if(item.original.customerPaymentAllowed === false) {
                return true;
            }
			if (Object.keys(cartItems).includes(fineID)) {
				return true;
			} else {
				return false;
			}
		}
		return false;
	}*/

	async function checkout() {
        setIsCheckingOut(true);
        let userDetes = getUserDetails().data;
        let uuid = generateUUID();
        let frontEndUrl = encodeURI(window.location.protocol+"//"+window.location.host+"/paymentNotification");
        let apiUrl = awsconfig.apiGateway.URL+"/iveripayment?transactionID="+uuid+"&veriLocation="+frontEndUrl;

        let data = {
            Ecom_BillTo_Postal_Name_Prefix: "",
            Ecom_BillTo_Postal_Name_First: userDetes.strName,
            Ecom_BillTo_Postal_Name_Middle: "",
            Ecom_BillTo_Postal_Name_Last: userDetes.strName,
            Ecom_BillTo_Online_Email: userDetes.strEmail,
            Ecom_ShipTo_Postal_Street_Line1: userDetes.txtBillToAddress,
            Ecom_ConsumerOrderID: "AUTOGENERATE",
            Ecom_SchemaVersion: "",
            Ecom_TransactionComplete: "false",
            Lite_Authorisation: "false",
            Lite_Version: "4.0",//"2.0",
            Lite_Currency_AlphaCode: "ZAR",
            Lite_Merchant_ApplicationID: awsconfig.iVeriAppID,
            buttonSubmit: "Submit",
            Ecom_Payment_Card_Protocols: "iVeri",
            Lite_Order_Terminal: "77777001",
            Lite_Order_AuthorisationCode: "",
            Lite_Website_TextColor: "#ffffff",
            Lite_Website_BGColor: "#1C4231",
            Lite_ConsumerOrderID_PreFix: "LITE",
            Lite_Website_Successful_Url: apiUrl+"&status=success",
            Lite_Website_Fail_Url: apiUrl+"&status=fail",
            Lite_Website_Error_Url: apiUrl+"&status=error",
            Lite_Website_Trylater_Url: apiUrl+"&status=trylater"
        };

        let fineItems = Object.keys(persistedCartItems);
        let infringements = [];
        let i = 0
        for(; i < fineItems.length; i++) {
            let fine = persistedCartItems[fineItems[i]];
            let fineKeys = Object.keys(fine);
            let displayVals = {};
            let fineOut = {};

            if (fineKeys.includes('infringementNoticeNumber')) {
                displayVals.fineID = fine.infringementNoticeNumber;
                displayVals.amount = 0;
                for (let i in fine.feesList.debt) {
                    displayVals.amount = displayVals.amount + Number(fine.feesList.debt[i].debtAmt);
                }
            } else {
                displayVals.fineID = fine.noticeNumber;
                displayVals.amount = fine.totalAmountDue;
            }
            // There is more than one amount in enatis/aarto fines, the total must be 
            // used so as to cover all enatis costs
            fineOut.strAmount = displayVals.amount;

            if(fine.api === "iForce"){
                fineOut.strInfringementSource = "IForce";

                if( fine.idNumber === '' ||
                    fine.idNumber === null ||
                    fine.idNumber === undefined
                ) {
                    fineOut.strIdNumber = "N/A";
                }
                else {
                    fineOut.strIdNumber = fine.idNumber;
                }

                fineOut.strInfringementNumber = fine.noticeNumber;
                let infringementDate = fine.offenceDate.substring(0, 10);
                infringementDate = infringementDate.replace(/-/g, '/');
                fineOut.dtInfringementDate = infringementDate;
                fineOut.strIssuingAuthority = fine.issuingAuthorityCode;
                fineOut.strVechicleRegistration = fine.vehicleRegistrationNumber;
                fineOut.intDemeritPoints = "N/A";
            }else{
                fineOut.strInfringementSource = "Aarto";

                if(fine.IDNumber === '' ||
                    fine.IDNumber === null ||
                    fine.IDNumber === undefined
                ) {
                    fineOut.strIdNumber = "N/A";
                }
                else {
                    fineOut.strIdNumber = fine.IDNumber;
                }

                fineOut.strInfringementNumber = fine.infringementNoticeNumber;
                let infringementDate = fine.date.substring(0, 10);
                infringementDate = infringementDate.replace(/-/g, '/');
                fineOut.dtInfringementDate = infringementDate;
                fineOut.strIssuingAuthority = fine.location.issAuthority.description;
                fineOut.strVechicleRegistration = fine.vehicle.licenseNumber;
                fineOut.intDemeritPoints = fine.charge[0].demeritPoints;
            }
            fineOut.strConvenienceAmount = defaultConvenienceFee;
            infringements.push(fineOut);

            data["Lite_Order_LineItems_Product_"+(i+1)] = "Fine "+displayVals.fineID;
            data["Lite_Order_LineItems_Quantity_"+(i+1)] = "1";
            data["Transaction_LineItems_Amount_"+(i+1)] = displayVals.amount;
            data["Lite_Order_LineItems_Amount_"+(i+1)] = displayVals.amount*100;
        }
        data["Lite_Order_LineItems_Product_"+(i+1)] = "Convenience Fee";
        data["Lite_Order_LineItems_Quantity_"+(i+1)] = "1";
        data["Transaction_LineItems_Amount_"+(i+1)] = cartConvenienceTotal;
        data["Lite_Order_LineItems_Amount_"+(i+1)] = cartConvenienceTotal*100;

        data["Transaction_Amount"] = cartTotal;
        data["Lite_Order_Amount"] = cartTotal*100;
        
        //send api transaction details
        let transactionapi = await unityRequests(
            `/postiveritransaction`,
            {
                "striVeriNumber": uuid,
                "userId": userDetes.UserID,
                "strStatus": "Sent to iVeri",
                "strAmount": cartTotal,
                "intPaid": "NO",
                "infringements": infringements
            },
            'post'
        );

        //check for valid response before continuing to iVeri
        if(Object.keys(transactionapi).includes("result") && transactionapi.result === true) {
            setIsCheckingOut(false);
            //create a form using the details and submit it
            var form = document.createElement("form");
            form.setAttribute("method", "post");
            form.setAttribute("action", "https://portal.nedsecure.co.za/Lite/Authorise.aspx");

            Object.keys(data).forEach(function (key) {
                var hiddenField = document.createElement("input");
                hiddenField.setAttribute("type", "hidden");
                hiddenField.setAttribute("name", key);
                hiddenField.setAttribute("value", data[key]);
                form.appendChild(hiddenField);
            })
            document.body.appendChild(form);
            form.submit();
        }
        else {
            handlePopupModal("There was an issue during checkout. Please try again later or contact an administrator if the issue persists.");
            setIsCheckingOut(false);
        }
	}

	return (
		<div className="center-to-screen pb-3 pt-3">
        {showPreloader ? (
                <div>
                    <Spinner
                        animation="border"
                        role="status"
                        style={{ display: 'block', margin: '0 auto' }}
                    >
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <Container>
                    <Row>
                        <Col>
                            <h1 className="form-header">Cart</h1>
                        </Col>
                    </Row>
                    {Object.keys(persistedCartItems).length <= 0 ? (
                        <Row>
                            <Col style={{textAlign:'center'}}>
                                <h1>No Items in Cart</h1>
                            </Col>
                        </Row>
                        ) : (
                            <>
                                <Row>
                                    <Col xs={12} md={12} lg={12}>
                                        <Table responsive="xl" bordered>
                                            {width > 600? (
                                                <thead>
                                                    <tr style={{ color: 'black' }}>
                                                        <th style={{ borderColor:'#54626e', padding:'10px', fontSize:'14px' }}>Infringement Number</th>
                                                        <th style={{ borderColor:'#54626e', padding:'10px', fontSize:'14px' }}>ID Number</th>
                                                        <th style={{ borderColor:'#54626e', padding:'10px', fontSize:'14px' }}>Vehicle Registration</th>
                                                        <th style={{ borderColor:'#54626e', padding:'10px', fontSize:'14px', textAlign: 'right' }}>Amount</th>
                                                        <th style={{ borderColor:'#54626e', padding:'10px', fontSize:'14px', textAlign: 'center' }}>Remove From Cart</th>
                                                    </tr>
                                                </thead>) : (<></>)
                                            }
                                            <tbody>
                                                {Object.keys(persistedCartItems).map((fineID) => {
                                                    var fine = persistedCartItems[fineID];
                                                    var fineKeys = Object.keys(fine);
                                                    var displayVals = {};

                                                    if (fineKeys.includes('infringementNoticeNumber')) {
                                                        displayVals.fineID = fine.infringementNoticeNumber;
                                                        displayVals.idNumber = fine.IDNumber;
                                                        displayVals.vehicleRegistration = fine.vehicle.licenseNumber;
                                                        displayVals.amount = 0;
                                                        for (var i = 0; i < fine.feesList.debt.length; i++) {
                                                            displayVals.amount = displayVals.amount + Number(fine.feesList.debt[i].debtAmt);
                                                        }
                                                    } else {
                                                        displayVals.fineID = fine.noticeNumber;
                                                        displayVals.idNumber = fine.idNumber;
                                                        displayVals.vehicleRegistration =
                                                            fine.vehicleRegistrationNumber;
                                                        displayVals.amount = fine.totalAmountDue;
                                                    }
                                                    return (
                                                        <tr key={fineID} style={{ color: '#9A9A9A' }}>
                                                            {width > 600? (
                                                                <>
                                                                    <td style={{ borderColor:'#54626e'}}>{displayVals.fineID}</td>
                                                                    <td style={{ borderColor:'#54626e'}}>{displayVals.idNumber}</td>
                                                                    <td style={{ borderColor:'#54626e'}}>{displayVals.vehicleRegistration}</td>
                                                                    <td style={{ textAlign: 'right', borderColor:'#54626e' }}>
                                                                        R {displayVals.amount}
                                                                    </td>
                                                                    <td style={{ textAlign: 'center', borderColor:'#54626e' }}>
                                                                        {/* <Button onClick={(e) => removeFromCart(fine)}> */}
                                                                            <FaTrashAlt 
                                                                                className="isNotDisabledBtnIconClass"
                                                                                style={{ fontSize: '25px' }} 
                                                                                onClick={(e) => removeFromCart(fine)}
                                                                            />
                                                                        {/* </Button> */}
                                                                    </td>
                                                                </>) : (<td>
                                                                    <span style={{lineHeight:'3.5'}}><b>Infringement Number</b>: <span style={{"float": "right"}}>{displayVals.fineID}</span><br/></span>
                                                                    <span style={{lineHeight:'3.5'}}><b>ID Number</b>: <span style={{"float": "right"}}>{displayVals.idNumber}</span><br/></span>
                                                                    <span style={{lineHeight:'3.5'}}><b>Vehicle Registration</b>: <span style={{"float": "right"}}>{displayVals.vehicleRegistration}</span><br/></span>
                                                                    <span style={{lineHeight:'3.5'}}><b>Amount</b>: <span style={{"float": "right"}}>R {displayVals.amount}</span><br/></span>
                                                                    <span style={{lineHeight:'3.5'}}><b>Remove From Cart</b>: <span style={{"float": "right"}}>
                                                                        {/* <Button > */}
                                                                            <FaTrashAlt 
                                                                                className="isNotDisabledBtnIconClass"
                                                                                style={{ fontSize: '30px' }} 
                                                                                onClick={(e) => removeFromCart(fine)}
                                                                            />
                                                                        {/* </Button> */}
                                                                        </span><br/></span>
                                                                </td>)
                                                            }
                                                        </tr>
                                                    );
                                                })}
                                                
                                                <tr style={{ color: '#9A9A9A' }}>
                                                    {width > 600? (<>
                                                            <td colSpan="3" style={{borderColor:'#54626e'}}>Convenience Fee (R{defaultConvenienceFee} per Infringement)</td>
                                                            <td style={{ textAlign: 'right', borderColor:'#54626e' }}>R {cartConvenienceTotal}</td>
                                                            <td></td>
                                                        </>) : (<td><span style={{lineHeight:'3.5', borderColor:'#54626e'}}><b>Convenience Fee (R{defaultConvenienceFee} per Infringement)</b>: <span style={{"float": "right"}}>R {cartConvenienceTotal}</span></span></td>)
                                                    }
                                                </tr>
                                                <tr className="cart-total-row">
                                                    {width > 600? (<>
                                                            <td colSpan="3" style={{borderColor:'#54626e'}}>
                                                                <span className="cart-total-row">TOTAL</span>
                                                            </td>
                                                            <td style={{ textAlign: 'right',borderColor:'#54626e' }}><span className="cart-total-row">R {cartTotal}</span></td>
                                                            <td></td>
                                                        </>) : (<td><span style={{lineHeight:'3.5',borderColor:'#54626e'}}><span className="cart-total-row">TOTAL</span>: <span className="cart-total-row">R {cartTotal}</span></span></td>)
                                                    }
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row style={{"width": "100%", "textAlign": "right"}}>
                                    <div>
                                        <LoaderButton
                                            type="submit"
                                            className="btn-grey btn-block"
                                            isLoading={isCheckingOut}
                                            onClick={checkout}
                                            style={{"width": "250px"}}
                                        >
                                            Proceed to Checkout
                                        </LoaderButton>
                                    </div>
                                </Row>
                            </>
                    )}
                </Container>
            )}

		</div>
		
	);
}

export default memo(Cart);