import React, { memo, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { PopupModalContext } from "../contexts/popupModalContext";
import { Form, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LoaderButton } from './common';
import { Auth, API } from 'aws-amplify';
import unityRequests from './unityRequests';
import { BsCheckCircle } from 'react-icons/bs';
import { validateFieldType, isLoggedIn } from 'utils';
import { GoogleAPIKey } from '../config';


function ContactUs() {
    let { handlePopupModal } = React.useContext(PopupModalContext);
	const [isMultifactor] = useState(true); //If coginto has multifactor-auth enabled, set this to true
	const [form, setForm] = useState({
		"email": "",
		"name": "",
		"surname": "",
        "idNumber": "",
        "ticketnumber": "",
		"message": "",
        "recaptcha": ""
	});
	const [errors, setErrors] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [isRegistering, setIsContacting] = useState(false);
	const [contactUsSent, setContactUsSent] = useState(false);

    let captcha;

    const setCaptchaRef = (ref) => {
        if(ref) {
            return captcha = ref;
        }
    };

    const resetCaptcha = () => {
        // maybe set it till after is submitted
        captcha.reset();
    }

	const setField = (field, value) => {
		setForm({
			...form,
			[field]: value
		});
		// Check and see if errors exist, and remove them from the error object:
		if(!!errors[field]) {
			setErrors({
				...errors,
				[field]: null
			});
		}
	}

	const getField = (field) => {
		return form[field];
	}

	if(isLoading)
	{
		setIsLoading(false);
	}

	const validateContactUsForm = () => {
		const {email, name, surname, idNumber, message,caterogyType} = form;
		const newErrors = {};

		if(!email || email === '') {
			newErrors.email = 'Cannot be blank!';
		}
        else if(!validateFieldType(email, "Email")) {
            newErrors.email = 'Must be valid!';
        }

		if(!name || name === '') {
			newErrors.name = 'Cannot be blank!';
		}
        else if(!validateFieldType(name, "Name")) {
            newErrors.name = 'Must be valid!';
        }

		if(!surname || surname === '') {
			newErrors.surname = 'Cannot be blank!';
		}
        else if(!validateFieldType(surname, "Name")) {
            newErrors.surname = 'Must be valid!';
        }

        if(!idNumber || idNumber === '') {
			newErrors.idNumber = 'Cannot be blank!';
		}

		if(!message || message === '') {
			newErrors.message = 'Cannot be blank!';
		}
        else if(!validateFieldType(message, "Message")) {
            newErrors.message = 'Must be valid!';
        }
		if(!caterogyType || caterogyType === 'Select Category Type') {
			newErrors.caterogyType = 'Select Category Type!';
		}
		



		return newErrors;
	}

    const handleContactUsFormSubmit = async (e) => {
		e.preventDefault();
		setIsContacting(true);
        //do recaptcha check
        let recaptchaResponse = await unityRequests(
            `/recaptcha`,
            {'recaptcha': getField('recaptcha')},
            'post'
        );
        
        if(recaptchaResponse.result === true && recaptchaResponse.payload === 'true') {
            handleContactUsForm();
        }
        else {
            setIsContacting(false);
            resetCaptcha();
            handlePopupModal("The recaptcha failed, please try again");
        }
    }

	const handleContactUsForm = async () => {
		setIsContacting(true);
		// get our new errors
		const newErrors = validateContactUsForm();
		// Conditional logic:
		if(Object.keys(newErrors).length > 0) {
			// We got errors!
			setErrors(newErrors);
			setIsContacting(false);
            resetCaptcha();
		}
		else {
			try {
				debugger;
				await postContactData();              

				setIsContacting(false);
				setContactUsSent(true);
			}
			catch(error) {
				console.log('Error signing up...', error);
				setIsContacting(false);
				if(error.code === "UsernameExistsException")
				{
					setErrors({...errors, email: error.message});
                    resetCaptcha();
				}
				else
				{
					handlePopupModal(error.message)
				}
			}
		}
	}

	function renderContactUsForm() {
		return (
			<>
				<div className="center-to-screen pt-3">
					<Container>
						<Row>
							<Col>
								<Form className="form">
									<h1>Contact Us</h1>
                                    <Row>
										<Col md={6}>
											<Form.Group controlId="formBasicName" >
                                                <label style={{ color: '#000' }}>Name:</label>
												<Form.Control
													type="text" 
													placeholder="Name" 
													value={getField('name')} 
													onChange={e => setField('name', e.target.value)}
													isinvalid={ !!errors.name ? "true" : "false"}
												/>
												<Form.Control.Feedback type='invalid'>
													{ errors.name }
												</Form.Control.Feedback>
											</Form.Group>
										</Col>

										<Col md={6}>
											<Form.Group controlId="formBasicSurname" >
                                                <label style={{ color: '#000' }}>Surname:</label>
												<Form.Control
													type="text" 
													placeholder="Surname" 
													value={getField('surname')} 
													onChange={e => setField('surname', e.target.value)}
													isinvalid={ !!errors.surname ? "true" : "false" }
												/>
												<Form.Control.Feedback type='invalid'>
													{ errors.surname }
												</Form.Control.Feedback>
											</Form.Group>
										</Col>

										<Col md={6}>
										<Form.Group controlId="formBasicEmail" >
                                                    <label style={{ color: '#000' }}>Email Address:</label>
                                                    <Form.Control
                                                        type="email" 
                                                        placeholder="Email" 
                                                        value={getField('email')} 
                                                        onChange={e => setField('email', e.target.value)}
                                                        isinvalid={ !!errors.email  ? "true" : "false" }
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        { errors.email }
                                                    </Form.Control.Feedback>
                                                </Form.Group>
										</Col>
										
										<Col md={6}>
											<Form.Group controlId="formBasicIDNumber" >
                                                <label style={{ color: '#000' }}>ID Number:</label>
												<Form.Control
													type="text" 
													placeholder="ID Number" 
													value={getField('idNumber')} 
													onChange={e => setField('idNumber', e.target.value)}
													isinvalid={ !!errors.idNumber  ? "true" : "false" }
												/>
												<Form.Control.Feedback type='invalid'>
													{ errors.idNumber }
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
                                        
                                      
                                        <Col md={6}>
                                                <Form.Group controlId="formBasicTicketNumber" >
                                                    <label style={{ color: '#000' }}>Ticket Number:</label>
                                                    <Form.Control
                                                        type="text" 
                                                        placeholder="Ticket Number" 
                                                        value={getField('ticketnumber')} 
                                                        onChange={e => setField('ticketnumber', e.target.value)}
                                                        isinvalid={ !!errors.ticketnumber  ? "true" : "false" }
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        { errors.ticketnumber }
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                        </Col>
										<Col md={6}>
											<Form.Group controlId="formBasicCaterogyType" >
                                                <label style={{ color: '#000' }}>Category Type:</label>
												<select
													className="outstandingScreenDropDowns"
													value={getField('caterogyType')} 
													onChange={e => setField('caterogyType', e.target.value)}
													isinvalid={ !!errors.caterogyType  ? "true" : "false" }
                                                    style={{textAlign: "left"}}
												>
													<option key="01" value="Select Category Type">
													Select Category Type
													</option>
													<option key="02" value="Reduction Request">
													Reduction Request
													</option>
													<option key="03" value="Redirection Request">
													Redirection Request
													</option>
													<option key="04" value="Payment Query">
													Payment Query
													</option>
													<option key="05" value="Login Query">
													Login Query
													</option>
													<option key="03" value="Fine Dispute">
													Fine Dispute
													</option>
													<option key="06" value="Copy of Fine">
													Copy of Fine
													</option>
													
												</select>
												<Form.Control.Feedback type='invalid'>
													{ errors.caterogyType }
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
                                        <Col md={6}>
                                                <Form.Group controlId="formBasicMessage" >
                                                    <label style={{ color: '#000' }}>Message:</label>
                                                    <Form.Control
                                                        as="textarea" rows="3"
                                                        placeholder="message" 
                                                        value={getField('message')} 
                                                        onChange={e => setField('message', e.target.value)}
                                                        isinvalid={ !!errors.message  ? "true" : "false" }
                                                    />
                                                    
                                                    <Form.Control.Feedback type='invalid'>
                                                        { errors.message }
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                        </Col>										
                                        <Col md={6}>
                                            <ReCAPTCHA
                                                id="grecap"
                                                ref={(r) => setCaptchaRef(r)}
                                                sitekey={GoogleAPIKey}
                                                onChange={(e) => {
                                                    setField('recaptcha', e);
                                                }}
                                            />
										</Col>										
                                        <Col md={6}>
                                            <Col md={{span:6, offset:3}}>
                                                <LoaderButton
                                                block
                                                type="submit"
                                                bssize="large"
                                                isLoading={isRegistering}
                                                onClick={(e) => {
                                                    handleContactUsFormSubmit(e)
                                                }}
                                                >
                                                    Submit
                                                </LoaderButton>
                                            </Col>
                                            
                                            <Col md={{span:10, offset: 1}}>
                                                <p className="mt-3">By Clicking Submit you agree to <b>Paymyfines.co.za</b> <b><Link to="/TermsAndConditions" href="/TermsAndConditions">Terms and Conditions</Link></b> and <b><Link to="/PrivacyPolicy" href="/PrivacyPolicy">Privacy Policy</Link></b></p>
                                            </Col>
                                        </Col>
									</Row>
								</Form>
							</Col>
						</Row>
					</Container>
				</div>
			
		</>
		)
	}

	const validateCodeForm = () => {
		const {code, password} = form;
		const newErrors = {};

		if(!code || code === '') {
			newErrors.code = 'Cannot be blank!';
		}

		return newErrors;
	}

	const postContactData = async () => {
		debugger;
		try {
			
				let contactUsData = {
					
					"strName": getField('name'),
					"strSurname": getField('surname'),
					"strEmail": getField('email'),
					"strTicketNumber": getField('ticketnumber'),
					"idNumber": getField('idNumber'),
					"strMessage": getField('message'),
					"strCaterogy": getField('caterogyType')
				};
				
				var contactResult = await unityRequests(`/contactUs`, contactUsData);
				if(Object.keys(contactResult).includes("result") && contactResult.result === true) {
					setContactUsSent(true);
				} else {
					setContactUsSent(false);
					isLoading(false);
				}
			    
				
		
		} catch (error) {
			handlePopupModal('Failed to submit contact details.');
		}
	}

	function renderSuccessMessage() {
		return (
			<div className="center-to-screen pb-3 pt-3">
				<Container>
					<Row>
						<Col md={{span:4, offset:4}}>
							<h1>Contact Us</h1>
							<div className="success">
								<p><BsCheckCircle/> Your Information has been submitted.</p>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}

	return (
		<div className="Register">
			{ 
			  !contactUsSent ? renderContactUsForm() : renderSuccessMessage()
			}
		</div>
	)
}

export default memo(ContactUs);