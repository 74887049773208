import React, { memo, useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Spinner, Form, Tabs, Tab, Table, Button, Modal, Card } from "react-bootstrap";
import { WYSIWYG, CustomAccordion, LoaderButton, ExportableTable } from './common';
import unityRequests from './unityRequests';
import { getUserDetails, validateFieldType } from 'utils';
import { PopupModalContext } from "../contexts/popupModalContext";
import { FiEdit3 } from 'react-icons/fi';
import { FaTrashAlt } from 'react-icons/fa';

function AdminSettings() {
   let { handlePopupModal } = useContext(PopupModalContext);
   const [form, setForm] = useState({
        graphIDValue: '',
        graphTypeValue: '',
        graphTitleValue: '',
        graphQueryValue: '',
        graphDescriptionValue: '',
        graphActiveValue: '',
        graphXValue: '',
        graphYValue: ''
    });
    const [convenienceFee, setConvenienceFee] = useState("");
	const [showPreloader, setShowPreLoader] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);
	const [showEditGraphDetailsModal, setshowEditGraphDetailsModal] = useState(false);
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
	const [editGraph, setEditGraph] = useState(false);
	const [graphTypeNotSelected, setGraphTypeNotSelected] = useState(false);
	const [errors, setErrors] = useState({});
    const [stateObj, setStateObj] = useState({});
    const [graphSettingsArr, setGraphSettingsArr] = useState([]);
    const [graphTypeArr, setGraphTypeArr] = useState([]);
    const [currentSelectedGraphSetting, setCurrentSelectedGraphSetting] = useState({});
    const [currentGraphSettingDeleted, setCurrentGraphSettingDeleted] = useState({});
    const [newGraphSettingID, setNewGraphSettingID] = useState("");

    function shortenSql(query) {
        return query.substr(0, 50)+"...";
    }

    useEffect(() => {
		async function onLoad() {
			try {
				setShowPreLoader(true);
				let userID = getUserDetails().data.UserID;

                let settingResponse = await unityRequests(
					`/getsettings?userId=${userID}`,
					{},
					'get'
				);

                let graphSettingResponse = await unityRequests(
					`/getgraphsettings?userId=${userID}`,
					{},
					'get'
				);

                let graphs = graphSettingResponse.payload;
                graphs = graphs.map((graph) => {
                    graph.query = shortenSql(graph.GraphQuery);
                    return graph;
                });

                setGraphSettingsArr(graphs);

                let graphTypes = [];
                for(let i=0; i < graphSettingResponse.payload.length; i++){
                    graphTypes.push(graphSettingResponse.payload[i].GraphType);
                }
                // setGraphTypeArr(graphTypes);
				// console.log(graphTypes);
                setGraphTypeArr(["Table", "CounterTab", "AreaGraph", "LineMarkGraph", "PieGraph", "RadarGraph", "StackGraph", "VerticalBarGraph"]);

                let settingObj = {...stateObj}
                settingObj.userId = getUserDetails().data.UserID;
                for(let i=0; i < settingResponse.payload.length; i++){
                    settingObj[settingResponse.payload[i].strKey] = decodeURI(settingResponse.payload[i].strValue);
                }
                setStateObj(settingObj);

                let defaultConvenienceFee = 0;
                settingResponse.payload.forEach((setting)=>{
                    if(setting.strKey.trim() === "convenience"){
                        defaultConvenienceFee = setting.strValue
                    } 
                })
                setConvenienceFee(defaultConvenienceFee);
				setShowPreLoader(false);
            }
            catch(e){
                console.log(e)
            }
		}

		onLoad();
	}, []);

    const setField = (field, value) => {
		setForm({
			...form,
			[field]: value
		});
		// Check and see if errors exist, and remove them from the error object:
		if (!!errors[field]) {
			setErrors({
				...errors,
				[field]: null
			});
		}
	};

	const getField = (field) => {
		return form[field];
	};

    async function handleSettingsSave(e, settingKey){
        e.preventDefault();
        setIsSaving(true);

        let updatedSettings ={};

        if(stateObj[settingKey].includes("{code}") === false){
            handlePopupModal('Please ensure that "{code}" is included in email template.');
            setIsSaving(false);
            return;
        }

        updatedSettings.userId = getUserDetails().data.UserID;
        updatedSettings[settingKey] = encodeURI(stateObj[settingKey]);

        let updateSettingsResponse = await unityRequests(
            '/postsettings',
            updatedSettings,
            'post'
        );

        if(updateSettingsResponse.result === true){
            handlePopupModal('Settings Updated');
        }
        else{
            handlePopupModal('Settings Not Updated');
        }
         
        setIsSaving(false);
    }

    async function handleConvenienceFeeSave(e){
        e.preventDefault();
        setIsSaving(true);
        
        let updatedConvenienceFee = {userId : getUserDetails().data.UserID, convenience: parseInt(convenienceFee)};

        let updateConvenienceFeeResponse = await unityRequests(
            '/postsettings',
            updatedConvenienceFee,
            'post'
        );

        if(updateConvenienceFeeResponse.result === true){
            handlePopupModal('Convenience Fee Updated');
        }
        else{
            handlePopupModal('Convenience Fee Not Updated');
        }
         
        setIsSaving(false);
    }

    function handleEditButtonClick(e, graphsetting){
        e.preventDefault();
        setEditGraph(true);
        setshowEditGraphDetailsModal(true);

        console.log(graphsetting);

        form.graphIDValue = graphsetting.GraphID;
        form.graphTypeValue = graphsetting.GraphType;
        form.graphTitleValue = graphsetting.GraphTitle ? graphsetting.GraphTitle : 'N/A';
        form.graphQueryValue = graphsetting.GraphQuery ? graphsetting.GraphQuery : 'N/A';
        form.graphDescriptionValue = graphsetting.GraphDescription ? graphsetting.GraphDescription : 'N/A';
        form.graphXValue = graphsetting.GraphX ? graphsetting.GraphX : 'N/A';
        form.graphYValue = graphsetting.GraphY ? graphsetting.GraphY : 'N/A';
        form.graphActiveValue = graphsetting.Active;
        setCurrentSelectedGraphSetting(form);
    }

    function handleAddGraphBtnOnclick() {
		setEditGraph(false);

        form.graphTypeValue = '';
        form.graphTitleValue = '';
        form.graphQueryValue = '';
        form.graphDescriptionValue = '';
        form.graphXValue = '';
        form.graphYValue = '';
        form.graphActiveValue = 0;

        setshowEditGraphDetailsModal(true);
	}

    async function handleGraphSettingSave(e){
        e.preventDefault();

        console.log("Add New");

        const newErrors = FormValidation();

        if(Object.keys(newErrors).length > 0) {
			// We got errors!
            console.log(newErrors);
			setErrors(newErrors);
		}
        else{
            setIsSaving(true);

            let newGraphSetting = { 
                userId: getUserDetails().data.UserID, 
                graphSettings : [{
                    GraphID: "",
                    GraphType: getField("graphTypeValue"),
                    GraphTitle: getField("graphTitleValue"),
                    GraphQuery:  getField("graphQueryValue"),
                    Active: getField("graphActiveValue"),
                    GraphDescription: getField("graphDescriptionValue"),
                    GraphX: getField("graphXValue"),
                    GraphY: getField("graphYValue")
                }]
            };

            let newGraphSettingsResponse = await unityRequests(
                '/postgraphsettings',
                newGraphSetting,
                'post'
            );

            if(newGraphSettingsResponse.result === true){
                newGraphSetting.graphSettings[0].GraphID = newGraphSettingsResponse.payload.GraphID;
                newGraphSetting.graphSettings[0].query = shortenSql(newGraphSetting.graphSettings[0].GraphQuery);
                let newUpdatedgraphSettings = [...graphSettingsArr, newGraphSetting.graphSettings[0]];
                console.log(newUpdatedgraphSettings);
                setGraphSettingsArr(newUpdatedgraphSettings);
                setNewGraphSettingID(newGraphSettingsResponse.payload.GraphID);
                handlePopupModal('Graph Setting Added');
                setshowEditGraphDetailsModal(false);
            }
            else{
                handlePopupModal('Graph Setting Not Added');
            }

            setIsSaving(false);
        }
    }

    async function handleGraphSettingEdit(e){
        e.preventDefault();

        console.log("Edit");

        const newErrors = FormValidation();

        if(Object.keys(newErrors).length > 0) {
			// We got errors!
            console.log(newErrors);
			setErrors(newErrors);
		}
        else{
            setIsSaving(true);

            let updatedGraphSetting = { 
                userId: getUserDetails().data.UserID, 
                graphSettings : [{
                    GraphID: getField("graphIDValue"),
                    GraphType: getField("graphTypeValue"),
                    GraphTitle: getField("graphTitleValue"),
                    GraphQuery:  getField("graphQueryValue"),
                    Active: getField("graphActiveValue"),
                    GraphDescription: getField("graphDescriptionValue"),
                    GraphX: getField("graphXValue"),
                    GraphY: getField("graphYValue")
                }]
            };

            let updatedGraphSettingsResponse = await unityRequests(
                '/postgraphsettings',
                updatedGraphSetting,
                'post'
            );

            if(updatedGraphSettingsResponse.result === true){
                let newUpdatedgraphSettings = [...graphSettingsArr];

                newUpdatedgraphSettings = newUpdatedgraphSettings.map((setting)=>{
                    
                    if(setting.GraphID === updatedGraphSetting.graphSettings[0].GraphID){
                        updatedGraphSetting.graphSettings[0].query = shortenSql(updatedGraphSetting.graphSettings[0].GraphQuery);
                        return updatedGraphSetting.graphSettings[0];
                    }else{
                        return setting;
                    }   
                })
                setGraphSettingsArr(newUpdatedgraphSettings);
                handlePopupModal('Graph Setting Updated');
                setshowEditGraphDetailsModal(false);
            }
            else{
                handlePopupModal('Graph Setting Not Updated');
            }

            setIsSaving(false);
        }
    }

    function handleDeleteButtonOnclick(e, graphsetting){
        e.preventDefault();
        setShowDeleteConfirmationModal(true);
        setCurrentGraphSettingDeleted(graphsetting);
    }

    async function handleGraphSettingDelete(){
        setIsDeleting(true);

        console.log("delete", "exists: "+currentGraphSettingDeleted.GraphID, "new: "+newGraphSettingID);

        let newGraphID = "";
        if(newGraphSettingID !== "")
        {
            newGraphID = newGraphSettingID;
        }else{
            newGraphID = currentGraphSettingDeleted.GraphID;
        }

        let deletingGraphSetting = { 
            userId: getUserDetails().data.UserID, 
            graphSettings : [{
                GraphID: newGraphID,
                GraphType: currentGraphSettingDeleted.GraphType,
                GraphTitle: currentGraphSettingDeleted.GraphTitle,
                GraphQuery:  currentGraphSettingDeleted.GraphQuery,
                Active: currentGraphSettingDeleted.Active,
                Delete: true,
                GraphDescription: currentGraphSettingDeleted.GraphDescription,
                GraphX: currentGraphSettingDeleted.GraphX,
                GraphY: currentGraphSettingDeleted.GraphY
            }]
        };

        let updatedGraphSettingsResponse = await unityRequests(
            '/postgraphsettings',
            deletingGraphSetting,
            'post'
        );

        if(updatedGraphSettingsResponse.result === true){
            let newUpdatedgraphSettings = [...graphSettingsArr];

            newUpdatedgraphSettings = newUpdatedgraphSettings.filter((setting) => {
                if(setting.GraphID !== deletingGraphSetting.graphSettings[0].GraphID){
                    return true;
                }
                else{
                    return false;
                }
            });

            setGraphSettingsArr(newUpdatedgraphSettings);
            setShowDeleteConfirmationModal(false);
            setNewGraphSettingID("");
            handlePopupModal('Graph Setting Deleted');
        }
        else{
            handlePopupModal('Graph Setting Not Deleted');
        }

        setIsDeleting(false);
    }

    function removeDuplicates(array) {
		return array.filter((a, b) => array.indexOf(a) === b);
	}

    function FormValidation(){
		const newErrors = {};

        if(!getField("graphTitleValue") || getField("graphTitleValue") === '') {
                newErrors.graphTitleValue = 'Cannot be blank!';
            }
        else if(!validateFieldType(getField("graphTitleValue"), "Text")) {
            newErrors.graphTitleValue = 'Must be valid!';
        }

        if(!getField("graphTypeValue") || getField("graphTypeValue") === ""){
            setGraphTypeNotSelected(true);
            newErrors.graphTypeValue = 'Must Select!';
        }

		return newErrors;
	}

    let tableConfig = {
        "isSearchable": true,
        "isSortable": true,
        "isExportable": true,
        "isPaginated": true,
        "omitColumns": ["GraphID", "GraphQuery"],
        "columnAlign": {"Edit": "center", "Delete": "center"},
        "customColumns": [
            {
                "name": "Edit",
                "selector": (row) => (
                    <FiEdit3 
                        style={{ "fontSize": '30px' }}
                        onClick={(e) => handleEditButtonClick(e, row)}
                        className="isNotDisabledBtnIconClass"
                    />
                ),
                "isSortable": false
            },
            {
                "name": "Delete",
                "selector": (row) => (
                    <FaTrashAlt
                        id="outFinesCartBtnIcon"
                        className="isNotDisabledBtnIconClass"
                        style={{ "fontSize": '30px' }}
                        onClick={(e) => {handleDeleteButtonOnclick(e, row)}}
                    />
                ),
                "isSortable": false
            }
        ]
    }

	return (
		<div className="center-to-screen mt-5 mb-5">
            {showPreloader ? (
					<div>
						<Spinner
							animation="border"
							role="status"
							style={{ display: 'block', margin: '0 auto' }}
						>
							<span className="visually-hidden">Loading...</span>
						</Spinner>
					</div>
				) : (
                    <Container>
                        <Row>
                            <Col>
                                <h1 className="form-header">Settings</h1>
                            </Col>
                        </Row>
                        <Tabs defaultActiveKey="emailSettings" id="settingsTabs" className="mb-3">
                            <Tab eventKey="emailSettings" title="Email Settings">
                                {Object.keys(stateObj).length > 0 ? (
                                    <Row key="emailSettingsRow">
                                        {Object.keys(stateObj).map((settingkey) => {
        
                                            let SettingValue = stateObj[settingkey];
        
                                            let keyNiceName = settingkey.replace(/_/g, "").split(/([A-Z][a-z0-9]+)/).filter(function(e){return e});
        
                                            keyNiceName = keyNiceName.map((value) => {
                                                return (value.charAt(0).toUpperCase() + value.slice(1)).trim();
                                            })
        
                                            keyNiceName = keyNiceName.join(" ");
                                            if(settingkey !== "userId"){
                                                return settingkey === "convenience " ? (
                                                    <Col lg={12} key={keyNiceName} className="pt-2">
                                                        <CustomAccordion 
                                                            title={keyNiceName} 
                                                        >
                                                            <Form className="row form" style={{position:'relative', padding:"10px"}}>
                                                                <Col xs={6} lg={6}>
                                                                    <Form.Group>
                                                                        <label style={{ color: '#000' }}>Convenience Fee:</label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            id="strConvenienceFee"
                                                                            defaultValue={SettingValue}
                                                                            onChange={(e) => setConvenienceFee(e.target.value)}
                                                                        >
                                                                        </Form.Control>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xs={4} lg={4}>
                                                                    <LoaderButton
                                                                        type="submit"
                                                                        className="btn-fill"
                                                                        isLoading={isSaving}
                                                                        onClick={handleConvenienceFeeSave}
                                                                        style={{position:'absolute', top:'35%'}}
                                                                    >
                                                                        Save
                                                                    </LoaderButton>
                                                                </Col>
                                                            </Form>
                                                        </CustomAccordion>
                                                    </Col>
                                                ) : (
                                                    <>
                                                        <Col lg={12} key={keyNiceName} className="pt-2">
                                                            <CustomAccordion 
                                                                title={keyNiceName} 
                                                            >
                                                                <Row>
                                                                    <Col>
                                                                        <LoaderButton
                                                                            type="submit"
                                                                            className="btn-fill float-end mt-5"
                                                                            isLoading={isSaving}
                                                                            onClick={(e)=>{
                                                                                e.preventDefault();
                                                                                handleSettingsSave(e, settingkey)
                                                                            }}
                                                                        >
                                                                            Save
                                                                        </LoaderButton>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <WYSIWYG title={settingkey} inputState={stateObj} setInputState={setStateObj} settingkey={settingkey}/>
                                                                    </Col>
                                                                </Row>
                                                            </CustomAccordion>
                                                        </Col>
                                                    </>
                                                )
                                            }
                                            else {
                                                return (<></>);
                                            }
                                        })}
                                    </Row>
                                    ) : (
                                        <Row key="NoSettingsMsgRow">
                                            <Col style={{textAlign:'center'}}>
                                                <h1>No Settings to Display</h1>
                                            </Col>
                                        </Row> 
                                    )
                                }
                            </Tab>
                            <Tab eventKey="graphSettings" title="Graph Settings">
                                {graphSettingsArr.length > 0 ? (
                                        <Row key="graphSettingsRow">
                                            <Col sm={12} md={12} lg={12} style={{padding:'10px'}}>
                                                <Button
                                                    className="btn-grey float-end"
                                                    onClick={handleAddGraphBtnOnclick}
                                                >
                                                    Add New Graph
                                                </Button>
                                            </Col>
                                            <Col xs={12} md={12} lg={12}>
                                                <ExportableTable title="Outstanding Fines" config={tableConfig} data={graphSettingsArr}/>
                                            </Col>
                                        </Row>
                                    ) : (
                                        <Row key="NoSettingsMsgRow">
                                            <Col style={{textAlign:'center'}}>
                                                <h1>No Settings to Display</h1>
                                            </Col>
                                        </Row> 
                                    )
                                }
                            </Tab>
                        </Tabs>
                        <Row>
							<Modal
								show={showEditGraphDetailsModal}
								onHide={() => {
                                    setshowEditGraphDetailsModal(false);
                                    setErrors({});
                                    setGraphTypeNotSelected(false);
                                }}
							>
								<Modal.Header closeButton>
                                    {editGraph === true ? (
										<Modal.Title>Edit Graph Details</Modal.Title>
									) : (
										<Modal.Title>Add New Graph</Modal.Title>
									)}
								</Modal.Header>
								<Modal.Body>
									<Row>
										<Col md="12">
											<Card>
												<Card.Body>
													<Form className="form">
														<Row>
															<Col md={4}>
																<label htmlFor="GraphTypeDropdown">
																	<span
																		style={{
																			paddingRight: '10px',
																			color: '#000'
																		}}
																	>
																		Graph Type:
																	</span>
																	<select
																		id="GraphTypeDropdown"
																		className="GraphTypeDropdown outstandingScreenDropDowns"
																		style={{ width: ' auto' }}
                                                                        defaultValue={getField('graphTypeValue')}
                                                                        onChange={(e) => {
                                                                            setField('graphTypeValue', e.target.value);
                                                                        }}
																	>
                                                                        <option
                                                                            value=""
                                                                            disabled
                                                                        >
                                                                            - select -
                                                                        </option>
                                                                        {removeDuplicates(graphTypeArr).map((graph) => {
                                                                            return (
                                                                                <option
                                                                                    key={graph}
                                                                                    value={graph}
                                                                                >
                                                                                    {graph}
                                                                                </option>
                                                                            );
                                                                        })}
																	</select>
																</label>
                                                                {graphTypeNotSelected && <div className="invalid-feedback">Please select!</div>}
															</Col>
														</Row>
														<Row>
															<Col className="pr-1" md={12}>
																<Form.Group>
																	<label style={{ color: '#000' }}>
																		{getField('graphTypeValue') === "Table"? <>Table Title</>: <>Graph Title</>}:
																	</label>
																	<Form.Control
																		type="text"
																		id="strGraphTitle"
                                                                        value={getField('graphTitleValue')}
                                                                        onChange={(e) => {
                                                                            setField('graphTitleValue', e.target.value);
                                                                        }}
                                                                        isinvalid={ !!errors.graphTitleValue ? "true" : "false" }
																	></Form.Control>
                                                                    <Form.Control.Feedback type='invalid'>
                                                                        { errors.graphTitleValue }
                                                                    </Form.Control.Feedback>
																</Form.Group>
															</Col>
                                                            <Col className="pr-1" md={12}>
																<Form.Group>
																	<label style={{ color: '#000' }}>
                                                                        {getField('graphTypeValue') === "Table"? <>Table Query</>: <>Graph Query</>}:
																	</label>
																	<Form.Control
																		as="textarea"
                                                                        rows={3}
																		id="strGraphQuery"
                                                                        defaultValue={getField('graphQueryValue')}
                                                                        onChange={(e) => {
                                                                            setField('graphQueryValue', e.target.value);
                                                                        }}
                                                                        isinvalid={ !!errors.graphQueryValue ? "true" : "false" }
																	></Form.Control>
                                                                    <Form.Control.Feedback type='invalid'>
                                                                        { errors.graphQueryValue }
                                                                    </Form.Control.Feedback>
																</Form.Group>
															</Col>
                                                            <Col className="pr-1" md={12}>
																<Form.Group>
																	<label style={{ color: '#000' }}>
                                                                        {getField('graphTypeValue') === "Table"? <>Table Description</>: <>Graph Description</>}:
																	</label>
																	<Form.Control
																		as="textarea"
                                                                        rows={3}
																		id="strGraphDescription"
                                                                        defaultValue={getField('graphDescriptionValue')}
                                                                        onChange={(e) => {
                                                                            setField('graphDescriptionValue', e.target.value);
                                                                        }}
                                                                        isinvalid={ !!errors.graphDescriptionValue ? "true" : "false" }
																	></Form.Control>
                                                                    <Form.Control.Feedback type='invalid'>
                                                                        { errors.graphDescriptionValue }
                                                                    </Form.Control.Feedback>
																</Form.Group>
															</Col>
                                                            {getField('graphTypeValue') !== "Table"? (
                                                                <>
                                                                    <Col className="pr-1" md={12}>
                                                                        <Form.Group>
                                                                            <label style={{ color: '#000' }}>
                                                                                Graph X:
                                                                            </label>
                                                                            <Form.Control
                                                                                as="textarea"
                                                                                rows={3}
                                                                                id="strGraphDescription"
                                                                                defaultValue={getField('graphXValue')}
                                                                                onChange={(e) => {
                                                                                    setField('graphXValue', e.target.value);
                                                                                }}
                                                                                isinvalid={ !!errors.graphXValue ? "true" : "false" }
                                                                            ></Form.Control>
                                                                            <Form.Control.Feedback type='invalid'>
                                                                                { errors.graphXValue }
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col className="pr-1" md={12}>
                                                                        <Form.Group>
                                                                            <label style={{ color: '#000' }}>
                                                                                Graph Y:
                                                                            </label>
                                                                            <Form.Control
                                                                                as="textarea"
                                                                                rows={3}
                                                                                id="strGraphDescription"
                                                                                defaultValue={getField('graphYValue')}
                                                                                onChange={(e) => {
                                                                                    setField('graphYValue', e.target.value);
                                                                                }}
                                                                                isinvalid={ !!errors.graphYValue ? "true" : "false" }
                                                                            ></Form.Control>
                                                                            <Form.Control.Feedback type='invalid'>
                                                                                { errors.graphYValue }
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </Col>
                                                            </>) : (
                                                                <>
                                                                    <Col className="pr-1" md={12}>
                                                                        <Form.Group>
                                                                            <label style={{ color: '#000' }}>
                                                                                Table Config:
                                                                            </label>
                                                                            <Form.Control
                                                                                as="textarea"
                                                                                rows={4}
                                                                                id="strGraphDescription"
                                                                                defaultValue={getField('graphXValue')}
                                                                                onChange={(e) => {
                                                                                    setField('graphXValue', e.target.value);
                                                                                }}
                                                                                isinvalid={ !!errors.graphXValue ? "true" : "false" }
                                                                            ></Form.Control>
                                                                            <Form.Control.Feedback type='invalid'>
                                                                                { errors.graphXValue }
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </Col>
                                                                </>
                                                            )
                                                        }
														</Row>
														<Row>
                                                            <Col className="pr-1" md={2}>
																<label htmlFor="ActiveDropdown">
																	<span
																		style={{
																			paddingRight: '10px',
																			color: '#000'
																		}}
																	>
																		Active:
																	</span>
																	<select
																		id="ActiveDropdown"
																		className="ActiveDropdown outstandingScreenDropDowns"
																		style={{ width: ' auto' }}
                                                                        defaultValue={getField('graphActiveValue')}
                                                                        onChange={(e) => {
                                                                            setField('graphActiveValue', e.target.value);
                                                                        }}
																	>
                                                                        <option
                                                                            value="0"
                                                                        >
                                                                            0
                                                                        </option>
                                                                        <option
                                                                            value="1"
                                                                        >
                                                                            1
                                                                        </option>
																	</select>
																</label>
															</Col>
														</Row>
														<div className="clearfix"></div>
													</Form>
												</Card.Body>
											</Card>
										</Col>
									</Row>
								</Modal.Body>
								<Modal.Footer>
                                    <LoaderButton  
                                        className="button-l btn-fill" 
                                        variant="primary" 
                                        onClick={editGraph ? ((e)=>{handleGraphSettingEdit(e)}) : ((e)=>{handleGraphSettingSave(e)})}
                                    >
                                        {editGraph ? ("Update") : ("Add")}
                                    </LoaderButton>
								</Modal.Footer>
							</Modal>
						</Row>
                        <Row>
							<Modal
								show={showDeleteConfirmationModal}
								onHide={() => {
                                    setShowDeleteConfirmationModal(false);
                                }}
							>
								<Modal.Header closeButton>
                                    <Modal.Title>Delete Confirmation</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<Row>
										<Col md="12" style={{textAlign:'center', fontWeight:'bold'}}>
											 Are you sure?
										</Col>
									</Row>
								</Modal.Body>
								<Modal.Footer>
                                    <LoaderButton  
                                        className="btn-fill" 
                                        variant="primary" 
                                        onClick={handleGraphSettingDelete}
                                    >
                                        Yes
                                    </LoaderButton>
                                    <LoaderButton  
                                        className="btn-fill" 
                                        variant="primary" 
                                        onClick={(e)=>{setShowDeleteConfirmationModal(false)}}
                                    >
                                        No
                                    </LoaderButton>
								</Modal.Footer>
							</Modal>
                        </Row>
                    </Container>
                )
            }
		</div>
	);
}

export default memo(AdminSettings);
