import React, { memo} from 'react';
import { Col, Container, Row, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import aboutDriveImg from 'assets/img/pmf_about_1.jpg';

function About() {
    
    return(
        <div className="center-to-screen pt-3 pb-5">
            <Container>
                <Row>
                    <Col>
                        <h1 className="form-header">About Paymyfines</h1>
                    </Col>
                </Row>
                {/* <Row className="w3-padding-large"> */}
                <Row>

                    <Col md={12}>
                        <p className="Body1PMF">
                            <b>Welcome to the Paymyfines online payment platform. Access your fine history through our quick search button, pay your outstanding fines in a few easy clicks, and in no time you will be on your way again with trouble-free motoring.</b>
                        </p>
                    </Col>
                </Row>
                {/* <Row className="w3-padding-large"> */}
                <Row>
                    <Col xs={12} sm={12} md={12} lg={8}>
                        <p className="Body1PMF" style={{textAlign:'justify'}}>
                            We know that paying motoring fines can at times be difficult and inconvenient, with days off work and long queues. Frustrating indeed. At Paymyfines, we have simplified the process with cutting-edge simplicity and ease of use to ensure the best and quickest way to get you back on the road again. After all, it is your motoring pleasure that we care about most. Paymyfines have secured direct relationships with the national road traffic authorities, allowing us to streamline your payment options and facilitate queries and concerns at the click of a button. We are confident that Paymyfines will ease the burden and get you back to the joy of driving. <b>It is the best way to pay.</b>
                        </p>
                        <br/>
                        <p className="Body1PMF">
                            Paymyfines.co.za payment platform is 100% safe and secure, and your privacy is guaranteed. For more information on online payment security <a href="https://iveri.com/payment-gateway/" target="_blank" rel="noreferrer" >click here.</a>
                        </p>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={4}>
                        <Image src={aboutDriveImg} fluid/>
                    </Col>
                </Row>
            </Container>
        </div>
    );	
}

export default memo(About);
