import { intersection } from 'lodash';
import { Roles } from 'config';

export function isLoggedIn() {
	/*
		* Note:
		*  This app assume if local storage have roles it means
		*  user is authenticated you can update this logic as per your app.
	*/
	return !!localStorage.getItem('roles')
}

export function getUserDetails() {
	return JSON.parse(localStorage.getItem('userDetails'))
}

export function isArrayWithLength(arr) {
	return (Array.isArray(arr) && arr.length)
}

export function getAllowedRoutes(routes) {
	var roles = JSON.parse(localStorage.getItem('roles'));
	if(roles == null) {
		roles = [Roles.GUEST];
	}
	return routes.filter(({ permission }) => {
		if(!permission) return true;
		else if(!isArrayWithLength(permission)) return true;
		else return intersection(permission, roles).length;
	});
}

function mod10CheckDigit (numbers) {
    if(typeof numbers !== 'string') {
        throw new Error('Invalid number provided')
    }

    const doubledSum = numbers.split('')
        .map((val, index) => {
            if((index + numbers.length) % 2 !== 0) {
                const double = Number.parseInt(val) * 2
                return double > 9 ? double - 9 : double
            }

            return val
        })
        .reduce((acc, curr) => Number.parseInt(acc) + Number.parseInt(curr))

    return (doubledSum * 9) % 10
}

export function validateFieldType(value, fieldType=false) {
    if(fieldType === "02") {//RSA ID Number
        let expression = /^\d{2}[0-1][0-9][0-3]\d\d{4}[0-1]\d{2}$/;
        let result = expression.test(value) && mod10CheckDigit(value.slice(0, value.length - 1)) === Number.parseInt(value[value.length - 1]);
        return result;
    }
    else if(fieldType === "03") {//Foreign ID Number
        return true;
    }
    else if(fieldType === "04") {//Business Registration
        return true;
    }
    else if(fieldType === "Name") {
        let expression = /^[\p{L} .’'`-]+$/u;
        let result = expression.test(value);
        return result;
    }
    else if(fieldType === "Phone") {
        let expression = /^\+?\d{10,13}$/;
        let result = expression.test(value);
        return result;
    }
    else if(fieldType === "Email") {
        let expression = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
        let result = expression.test(value);
        return result;
    }else if(fieldType === "Text") {
        let expression = /^([a-zA-Z0-9\s\[!@#\]$*%&(){}\\`.+,/\";:\'\?~<>=\-])*$/;
        let result = expression.test(value);
        return result;
    }
    else {
        return "Invalid fieldType";
    }
}

export function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = (performance && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    let str = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });

    if((/[0-9]/).test(str[0])) {
        str = "a"+str;
    }
    return str;
}

export function getNiceName(name) {
    let niceName = name+"";

    //remove database prefixes
    let stringToRemove = ["dt", "str", "int"];
    for(let j = 0; j < stringToRemove.length; j++)
    {
        if(niceName.indexOf(stringToRemove[j]) === 0) {
            niceName = niceName.replace(stringToRemove[j], "");
        }
    }

    if(niceName.includes("_") === true) { //if header is split by _'s
        niceName = niceName.split("_");
    }
    else { //else if header is camelCase
        niceName = niceName.split(/([A-Z][a-z0-9]+)/).filter(function(e){return e});
    }
    niceName = niceName.map((value) => {
        return (value.charAt(0).toUpperCase() + value.slice(1)).trim();
    })
    niceName = niceName.join(" ");
    return niceName;
}