import React, { memo, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { PopupModalContext } from "../contexts/popupModalContext";
import { Form, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LoaderButton } from './common';
import { Auth, API } from 'aws-amplify';
import unityRequests from './unityRequests';
import { BsCheckCircle } from 'react-icons/bs';
import { validateFieldType, isLoggedIn } from 'utils';
import { GoogleAPIKey } from '../config';


function Register() {
    let { handlePopupModal } = React.useContext(PopupModalContext);
	const [isMultifactor] = useState(true); //If coginto has multifactor-auth enabled, set this to true
	const [form, setForm] = useState({
		"email": "",
		"name": "",
		"surname": "",
        "idNumber": "",
        "idNumberConf": "",
        "idNumberType": "02",
		"password": "",
		"confirmPassword": "",
		"code": "",
        "cellphoneNumber": "",
        "recaptcha": ""
	});
	const [errors, setErrors] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [isRegistering, setIsRegistering] = useState(false);
	const [registrationSent, setRegistrationSent] = useState(false);
	const [isSendingCode, setIsSendingCode] = useState(false);
	const [codeSent, setCodeSent] = useState(false);
    const [codeVerified, setCodeVerified] = useState(false);
    const [passwordDefined, setPasswordDefined] = useState(false);
    const [isBusiness, setisBusiness] = useState(false);

	const [showTOS, setShowTOS] = useState(false);
	const [showPrivacy, setShowPrivacy] = useState(false);
    let captcha;

    const setCaptchaRef = (ref) => {
        if(ref) {
            return captcha = ref;
        }
    };

    const resetCaptcha = () => {
        // maybe set it till after is submitted
        captcha.reset();
    }

	const setField = (field, value) => {
		setForm({
			...form,
			[field]: value
		});
		// Check and see if errors exist, and remove them from the error object:
		if(!!errors[field]) {
			setErrors({
				...errors,
				[field]: null
			});
		}
	}

	const getField = (field) => {
		return form[field];
	}

	if(isLoading)
	{
		let emailVerification = window.location.search.substring(1).split('=');
		if(emailVerification[0] === "verify" && emailVerification[0].length > 0)
		{
            if(localStorage.getItem("asdf")) {
                let pass = localStorage.getItem("asdf");
                setField("password", pass);
                localStorage.removeItem("asdf");
                setPasswordDefined(true);
            }
			setField("email", emailVerification[1]);
			setRegistrationSent(true);
		}
		setIsLoading(false);
	}

    function handleBusinessAccountCheck(e){
        setField('idNumberType', e.target.value);

        if(e.target.value === "04"){
             setisBusiness(true);
        }
        else{
            setisBusiness(false);
        }
    }

    function handleBusinessAccountCheckBoxOnchange(){
        if(isBusiness === true){
            setisBusiness(false);
            setField('idNumberType', "02");
        }else{
            setisBusiness(true);
            setField('idNumberType', "04");
        }
    }

	const validateRegistrationForm = () => {
		const {email, name, surname, idNumber, idNumberConf, idNumberType, password, confirmPassword, cellphoneNumber} = form;
		const newErrors = {};

		if(!email || email === '') {
			newErrors.email = 'Cannot be blank!';
		}
        else if(!validateFieldType(email, "Email")) {
            newErrors.email = 'Must be valid!';
        }

		if(!name || name === '') {
			newErrors.name = 'Cannot be blank!';
		}
        else if(!validateFieldType(name, "Name")) {
            newErrors.name = 'Must be valid!';
        }

		if(!surname || surname === '') {
			newErrors.surname = 'Cannot be blank!';
		}
        else if(!validateFieldType(surname, "Name")) {
            newErrors.surname = 'Must be valid!';
        }

        if(!cellphoneNumber || cellphoneNumber === '') {
			newErrors.cellphoneNumber = 'Cannot be blank!';
		}
        else if(!validateFieldType(cellphoneNumber, "Phone")) {
            newErrors.cellphoneNumber = 'Must be valid!';
        }

        if(!idNumber || idNumber === '') {
			newErrors.idNumber = 'Cannot be blank!';
		}
        else if(!validateFieldType(idNumber, idNumberType)) {
            newErrors.idNumber = 'Must be valid!';
        }

        if(idNumberConf !== idNumber) {
			newErrors.idNumberConf = 'Must match ID Number!';
		}

		if(!password || password === '') {
			newErrors.password = 'Cannot be blank!';
		}

		if(!confirmPassword || confirmPassword === '') {
			newErrors.confirmPassword = 'Cannot be blank!';
		}
		else if(password !== confirmPassword) {
			newErrors.confirmPassword = 'Passwords must match!';
		}

		return newErrors;
	}

    const handleRegistrationFormSubmit = async (e) => {
		e.preventDefault();
		setIsRegistering(true);
        //do recaptcha check
        let recaptchaResponse = await unityRequests(
            `/recaptcha`,
            {'recaptcha': getField('recaptcha')},
            'post'
        );
        
        if(recaptchaResponse.result === true && recaptchaResponse.payload === 'true') {
            handleRegistrationFormCode();
        }
        else {
            setIsRegistering(false);
            resetCaptcha();
            handlePopupModal("The recaptcha failed, please try again");
        }
    }

	const handleRegistrationFormCode = async () => {
		setIsRegistering(true);
		// get our new errors
		const newErrors = validateRegistrationForm();
		// Conditional logic:
		if(Object.keys(newErrors).length > 0) {
			// We got errors!
			setErrors(newErrors);
			setIsRegistering(false);
            resetCaptcha();
		}
		else {
			try {
				debugger;
				await Auth.signUp({"username": getField('email'), "password": getField('password'), "attributes": {"email": getField('email')}})
												 .then(async result => {
													debugger;
													if(result.user.username != null) {
														await postUserData();
													}
												 });
                setPasswordDefined(true);

				setIsRegistering(false);
				setRegistrationSent(true);
			}
			catch(error) {
				console.log('Error signing up...', error);
				setIsRegistering(false);
				if(error.code === "UsernameExistsException")
				{
					setErrors({...errors, email: error.message});
                    resetCaptcha();
				}
				else
				{
					handlePopupModal(error.message)
				}
			}
		}
	}

	function renderRegistrationForm() {
		return (
			<>
				<div className="center-to-screen pt-3">
					<Container>
						<Row>
							<Col>
								<Form className="form">
									<h1>Register</h1>
                                    <Row>
										<Col md={6}>
											<Form.Group controlId="formBasicName" >
                                                <label style={{ color: '#000' }}>Name:</label>
												<Form.Control
													type="text" 
													placeholder="Name" 
													value={getField('name')} 
													onChange={e => setField('name', e.target.value)}
													isinvalid={ !!errors.name ? "true" : "false"}
												/>
												<Form.Control.Feedback type='invalid'>
													{ errors.name }
												</Form.Control.Feedback>
											</Form.Group>
										</Col>

										<Col md={6}>
											<Form.Group controlId="formBasicSurname" >
                                                <label style={{ color: '#000' }}>Surname:</label>
												<Form.Control
													type="text" 
													placeholder="Surname" 
													value={getField('surname')} 
													onChange={e => setField('surname', e.target.value)}
													isinvalid={ !!errors.surname ? "true" : "false" }
												/>
												<Form.Control.Feedback type='invalid'>
													{ errors.surname }
												</Form.Control.Feedback>
											</Form.Group>
										</Col>

										<Col md={6}>
											<Form.Group controlId="formBasicIDNumberType" >
                                                <label style={{ color: '#000' }}>Select ID Type:</label>
												<select
													className="outstandingScreenDropDowns"
													value={getField('idNumberType')} 
													onChange={(e) => {handleBusinessAccountCheck(e)}}
													isinvalid={ !!errors.idNumberType  ? "true" : "false" }
                                                    style={{textAlign: "left"}}
												>
													<option key="02" value="02">
														RSA ID Number
													</option>
													<option key="03" value="03">
														Foreign ID Number
													</option>
													<option key="04" value="04">
														Business Registration
													</option>
												</select>
												<Form.Control.Feedback type='invalid'>
													{ errors.idNumberType }
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
										
										<Col md={6}>
											<Form.Group controlId="formBasicIDNumber" >
                                                <label style={{ color: '#000' }}>ID Number:</label>
												<Form.Control
													type="text" 
													placeholder="ID Number" 
													value={getField('idNumber')} 
													onChange={e => setField('idNumber', e.target.value)}
													isinvalid={ !!errors.idNumber  ? "true" : "false" }
												/>
												<Form.Control.Feedback type='invalid'>
													{ errors.idNumber }
												</Form.Control.Feedback>
											</Form.Group>
										</Col>

                                        <Col md={6}></Col>

                                        <Col md={6}>
											<Form.Group controlId="formBasicIDNumberConf" >
                                                <label style={{ color: '#000' }}>Confirm ID Number:</label>
												<Form.Control
													type="text" 
													placeholder="Confirm ID Number" 
													value={getField('idNumberConf')} 
													onChange={e => setField('idNumberConf', e.target.value)}
													isinvalid={ !!errors.idNumberConf  ? "true" : "false" }
												/>
												<Form.Control.Feedback type='invalid'>
													{ errors.idNumberConf }
												</Form.Control.Feedback>
											</Form.Group>
										</Col>

                                        <Col md={6}>
											<Form.Group controlId="formBasicPassword" >
                                                <label style={{ color: '#000' }}>Password:</label>
												<Form.Control
													type="password" 
													placeholder="Password"
													value={getField('password')}
													onChange={e => setField('password', e.target.value)}
													isinvalid={ !!errors.password  ? "true" : "false" }
												/>
                                                <Form.Text className="text-muted">
                                                    Minimum length 8<br />
                                                    Require numbers<br />
                                                    Require special character<br />
                                                    Require uppercase letters<br />
                                                    Require lowercase letters
                                                </Form.Text>
												<Form.Control.Feedback type='invalid'>
													{ errors.password }
												</Form.Control.Feedback>
											</Form.Group>
										</Col>

										<Col md={6}>
											<Form.Group controlId="formBasicConfirmPassword" >
                                                <label style={{ color: '#000' }}>Confirm Password:</label>
												<Form.Control
													type="password"
													placeholder="Confirm Password"
													value={getField('confirmPassword')}
													onChange={e => setField('confirmPassword', e.target.value)}
													isinvalid={ !!errors.confirmPassword ? "true" : "false" }
												/>
												<Form.Control.Feedback type='invalid'>
													{ errors.confirmPassword }
												</Form.Control.Feedback>
											</Form.Group>
										</Col>

                                        <Col md={6}>
                                                <Form.Group controlId="formBasicEmail" >
                                                    <label style={{ color: '#000' }}>Email Address:</label>
                                                    <Form.Control
                                                        type="email" 
                                                        placeholder="Email" 
                                                        value={getField('email')} 
                                                        onChange={e => setField('email', e.target.value)}
                                                        isinvalid={ !!errors.email  ? "true" : "false" }
                                                    />
                                                    <Form.Control.Feedback type='invalid'>
                                                        { errors.email }
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                                <Form.Group controlId="formBasicMobileNumber" >
                                                    <label style={{ color: '#000' }}>Cellphone Number:</label>
                                                    <Form.Control
                                                        type="text" 
                                                        placeholder="Cellphone Number" 
                                                        value={getField('cellphoneNumber')} 
                                                        onChange={e => setField('cellphoneNumber', e.target.value)}
                                                        isinvalid={ !!errors.cellphoneNumber  ? "true" : "false" }
                                                    />
                                                    <Form.Text className="text-muted">
                                                        Format example. 0821234567 or +27831234567
                                                    </Form.Text>
                                                    <Form.Control.Feedback type='invalid'>
                                                        { errors.cellphoneNumber }
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                        </Col>

                                        <Col md={6} className="mb-4">
                                            <input
                                                readOnly
                                                type="checkbox"
                                                id="blnBusinessAccount"
                                                name="blnBusinessAccount"
                                                checked={getField('isBusiness')}
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    verticalAlign: 'middle',
                                                    borderRadius:'50% !important'
                                                }}
                                                onChange={handleBusinessAccountCheckBoxOnchange}
                                            />
                                            <label
                                                htmlFor="blnBusinessAccount"
                                                style={{ paddingLeft: '10px', color: '#000' }}
                                            >
                                                Business Account
                                            </label>
                                        </Col>

                                        <Col md={6}></Col>

                                        <Col md={6}>
                                            <ReCAPTCHA
                                                id="grecap"
                                                ref={(r) => setCaptchaRef(r)}
                                                sitekey={GoogleAPIKey}
                                                onChange={(e) => {
                                                    setField('recaptcha', e);
                                                }}
                                            />
										</Col>
										
                                        <Col md={6}>
                                            <Col md={{span:6, offset:3}}>
                                                <LoaderButton
                                                block
                                                type="submit"
                                                bssize="large"
                                                isLoading={isRegistering}
                                                onClick={(e) => {
                                                    handleRegistrationFormSubmit(e)
                                                }}
                                                >
                                                    Submit
                                                </LoaderButton>
                                            </Col>
                                            
                                            <Col md={{span:10, offset: 1}}>
                                                <p className="mt-3">By Clicking Submit you agree to <b>Paymyfines.co.za</b> <b><Link to="/TermsAndConditions" href="/TermsAndConditions">Terms and Conditions</Link></b> and <b><Link to="/PrivacyPolicy" href="/PrivacyPolicy">Privacy Policy</Link></b></p>
                                            </Col>
                                        </Col>
									</Row>
								</Form>
							</Col>
						</Row>
					</Container>
				</div>
			
		</>
		)
	}

	const validateCodeForm = () => {
		const {code, password} = form;
		const newErrors = {};

		if(!code || code === '') {
			newErrors.code = 'Cannot be blank!';
		}

		return newErrors;
	}

	const postUserData = async () => {
		debugger;
		try {
			let result = await unityRequests(`/user/details?strEmail=${getField('email')}`, {}, 'get');
			
			if(result.payload === "User does not exist") {
				let newUserData = {
					"strCognitoUuid": null,
					"strName": getField('name'),
					"strSurname": getField('surname'),
					"strEmail": getField('email'),
					"strIdType": getField('idNumberType'),
					"strIdNumber": getField('idNumber'),
					"strMobileNumber": getField('cellphoneNumber')
				};
				await unityRequests(`/register`, newUserData);
			}
				
		
		} catch (error) {
			handlePopupModal('Failed to submit user details.');
		}
	}

    const linkAccount = async () => {
        let pass = getField('password');
        try {
            let cogintoUserData = await Auth.signIn(getField('email'), pass);
            //const jwtToken = cogintoUserData.signInUserSession.accessToken.jwtToken;
            if(cogintoUserData.attributes.email_verified === true) {
                const userUuid = cogintoUserData.username;
                let uuidRequest = API.get("unity", `/user/details?strCognitoUuid=${userUuid}`);
                let emailRequest = API.get("unity", `/user/details?strEmail=${getField('email')}`);

                Promise.allSettled([uuidRequest, emailRequest]).then(async (results) => {
                    var uuidRequestResult = results[0].value;
                    var emailRequestResult = results[1].value;
                    
                    if(uuidRequestResult.result === false || emailRequestResult.result === false) {
                        if(emailRequestResult.payload === "User does not exist") {
                            let newUserData = {
                                "strCognitoUuid": userUuid,
                                "strName": getField('name'),
                                "strSurname": getField('surname'),
                                "strEmail": getField('email'),
                                "strIdType": getField('idNumberType'),
                                "strIdNumber": getField('idNumber'),
                                "strMobileNumber": getField('cellphoneNumber')
                            };
                            //add a new entry
                            await unityRequests(`/register`, newUserData);
                        }
                        else {
                            //update the users refUserID to match their coginto identity
                        // await unityRequests(`/updateregistration`, {"body": {strCognitoUuid:userUuid, strEmail: getField('email')}});
                            await API.post('unity', `/updateregistration`, { "body": { "strCognitoUuid": userUuid, "strEmail": getField('email') } });

                        }
                    }
                    setIsSendingCode(false);
                    setCodeSent(true);
                });
            }
            else {
                throw new Error("User mail remains unconfirmed");
            }
        }
        catch(error) {
            const newErrors = {};
            if (error.code === 'NotAuthorizedException' || error.code === 'UserNotFoundException') {
                newErrors.password = 'Incorrect email or password';
            }
            setErrors(newErrors);
            setIsSendingCode(false);
        }
    }

	const handleCodeFormSubmit = async (e) => {
		e.preventDefault();
		setIsSendingCode(true);
		// get our new errors
		const newErrors = validateCodeForm();
		// Conditional logic:
		console.log("Clicker");
		if(Object.keys(newErrors).length > 0) {
			// We got errors!
			console.log(errors);
			setErrors(newErrors);
			setIsSendingCode(false);
		}
		else {
            if(codeVerified === false) {
                try {
                    await Auth.confirmSignUp(getField('email'), getField('code').trim());
					setIsSendingCode(false);
                    setCodeVerified(true);
					setCodeSent(true);
					if(passwordDefined) {
						linkAccount(); 
					}
                }
                catch(error) {
                    if(error.message === "User cannot be confirmed. Current status is CONFIRMED") {
					   console.log("error");
					  	setIsSendingCode(false);
						setCodeVerified(true);
						setCodeSent(true);
                    }
                    else{
                        const newErrors = {};
                        if (error.code === 'NotAuthorizedException' || error.code === 'UserNotFoundException') {
                            newErrors.password = 'Incorrect email or password';
                        }
                        else if(error.code === 'CodeMismatchException') {
                            newErrors.code = 'Invalid code';
                        }
                        setErrors(newErrors);
                        setIsSendingCode(false);
                    }
                }
            }
            else {
				setCodeVerified(true);
				setCodeSent(true);
            }
		}
	}

	const handleResendInvite = async (e) => {
		e.preventDefault();
		setIsSendingCode(true);
		try {
			await Auth.resendSignUp(getField('email'));
			setIsSendingCode(false);
			handlePopupModal('New invite sent!');
		}
		catch(error) {
			console.log('Error resending invite...', error);
			setIsSendingCode(false);
			handlePopupModal('Error resending invite');
		}
	}

	function renderCodeVerification()
	{
		return(
			<div className="center-to-screen pb-3 pt-3">
				<Container>
					<Row>
						<Col md={12}>
                            <div className="fontGrey">Didn't get an email? Click <span style={{"color": "#007bff"}} onClick={handleResendInvite}>here</span> to resend it.</div>
                            <Form className="form">
                            <h1>{"Confirm the verification code emailed to '"+getField('email')+"'"}</h1>
                                {codeVerified === false ? (
                                    <Form.Group controlId="formBasicVerificationCode" >
                                        <Form.Label>Verification Code <b>*</b></Form.Label>
                                        <Form.Control
                                            type="text" 
                                            placeholder="Code" 
                                            value={getField('code')} 
                                            onChange={e => setField('code', e.target.value)}
                                            isinvalid={ !!errors.code ? "true" : "false" }
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            { errors.code }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    ) : (<></>)
                                }
                              {/*   { passwordDefined === false ? (
                                    <Form.Group controlId="formBasicVerificationCode" >
                                        <Form.Label>Password <b>*</b></Form.Label>
                                        <Form.Control
                                            type="password" 
                                            placeholder="Password"
                                            value={getField('password')}
                                            onChange={e => setField('password', e.target.value)}
                                            isinvalid={ !!errors.password  ? "true" : "false" }
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            { errors.password }
                                        </Form.Control.Feedback>
                                    </Form.Group>) : (<></>)
                                } */}

                                <Col md={{span:4, offset:4}}>
                                    <LoaderButton
                                        block
                                        type="submit"
                                        bssize="large"
                                        isLoading={isSendingCode}
                                        onClick={handleCodeFormSubmit}
                                    >
                                    Confirm Code
                                    </LoaderButton>
                                </Col>
							</Form>
						</Col>
					</Row>
				</Container>
				
			</div>
		)
	}

	function renderSuccessMessage() {
		return (
			<div className="center-to-screen pb-3 pt-3">
				<Container>
					<Row>
						<Col md={{span:4, offset:4}}>
							<h1>Register</h1>
							<div className="success">
								<p><BsCheckCircle/> Your account registration has been submitted.</p>
								<p>
									<Link className="fontGrey" href="/login" to="/login">
										Click here to login with your new account.
									</Link>
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}

	function renderConfirmSuccessMessage() {
		return (
			<div className="center-to-screen pb-3 pt-3">
				<Container>
					<Row>
						<Col md={{span:4, offset:4}}>
							<h1>Register</h1>
							<div className="success">
								<p><BsCheckCircle/> Your account has been verified.</p>
								<p>
									<Link className="fontGrey" href="/login" to="/login">
										Click here to login with your new account.
									</Link>
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}

	return (
		<div className="Register">
			{!isMultifactor
				? !registrationSent ? renderRegistrationForm() : renderSuccessMessage()
				: !registrationSent ? renderRegistrationForm() : !codeSent ? renderCodeVerification() 
				: codeVerified ? renderConfirmSuccessMessage() : renderSuccessMessage()
			}
		</div>
	)
}

export default memo(Register);