import React, { Fragment, useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { getAllowedRoutes } from 'utils';
import { RoutingConfig } from 'config';
import { TopNav, Footer } from 'components/common';
import MapAllowedRoutes from 'routes/MapAllowedRoutes';

function Routing() {
	const match = useRouteMatch('/');
	let allowedRoutes = getAllowedRoutes(RoutingConfig);
    const location = useLocation();

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
      }, [location.pathname]);
    
	return (
		<Fragment>
			<TopNav routes={allowedRoutes} prefix={match.path} className="bg-white" />
			<MapAllowedRoutes routes={allowedRoutes} basePath="/" isAddNotFound />
			<Footer routes={allowedRoutes} currentPage={location.pathname}/>
		</Fragment>
	);
}

export default Routing;
