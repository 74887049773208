import React from "react";

function usePopupModal() {
  let [popupModal, setPopupModal] = React.useState(false);
  let [popupModalContent, setPopupModalContent] = React.useState("Something went wrong...");

  let handlePopupModal = (content = false) => {
    setPopupModal(!popupModal);
    if (content) {
        setPopupModalContent(content);
    }
  };

  return { popupModal, handlePopupModal, popupModalContent };
};

export default usePopupModal;