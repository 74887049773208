import React from "react";
import usePopupModal from "../hooks/usePopupModal";
import PopupModal from "../components/common/PopupModal";

let PopupModalContext;
let { Provider } = (PopupModalContext = React.createContext());

let PopupModalProvider = ({ children }) => {
  let { popupModal, handlePopupModal, popupModalContent } = usePopupModal();
  return (
    <Provider value={{ popupModal, handlePopupModal, popupModalContent }}>
      <PopupModal />
      {children}
    </Provider>
  );
};

export { PopupModalContext, PopupModalProvider };
