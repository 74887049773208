import React, { memo } from 'react';
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import { Editor } from '@tinymce/tinymce-react';
import { Row, Col } from 'react-bootstrap';

const WYSIWYG = ({inputState, setInputState, title="Editor", settingkey}) => {
    
    const handleEditorChange = (content, editor) => {
        let hold = {...inputState};
        hold[settingkey] = content;
        setInputState(hold);
    }

    return (
        <>
            <Row>
                <Col md={6} className="pt-2">
                    {/* <div>{title}:</div> */}
                    <Editor
                        init={{
                            browser_spellcheck: true,
                            skin: false,
                            content_css: false,
                            height: 500,
                            menubar: false,
                            plugins: [
                                'link image',
                                'table paste'
                            ],
                            toolbar: [
                                `undo redo | styleselect | fontselect | fontsizeselect lineheight | visualaid removeformat`,
                                `bold italic underline strikethrough forecolor backcolor | 
                                link image | alignleft aligncenter alignright alignjustify | 
                                bullist numlist outdent indent`
                            ]
                        }}
                        value={inputState[settingkey]}
                        onEditorChange={handleEditorChange}
                    />
                </Col>
                <Col md={6} className="pt-2">
                    <div style={{"height": "100%"}}>
                        <div>Preview:</div>
                        <div style={{"height": "100%", "overflow": "scroll", "background": "#ffffffff"}} dangerouslySetInnerHTML={{__html:  inputState[settingkey]}}></div>
                    </div>
                </Col>
            </Row>
        </>
    );
}    

 export default memo(WYSIWYG);