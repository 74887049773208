import React, { memo, useState, useEffect } from 'react';
import Moment from 'moment';
import { ExportableTable, LoaderButton } from './common';
import { Container, Row, Col, Button, Spinner, Modal, Form, Card } from 'react-bootstrap';
import { AiFillInfoCircle } from 'react-icons/ai';
import unityRequests from './unityRequests';
import { getUserDetails } from 'utils';

function Transactions() {
    const [showPreloader, setShowPreLoader] = useState(false);
    const [currentDetailRow, setCurrentDetailRow] = useState(false);
    const [showDetailModal, setShowDetailModal] = useState(false);
	const [transactions, setTransactions] = useState([]);
	const [IDNumberValue, setIDNumberValue] = useState('ALL');
    let IDNumberDropdownList = JSON.parse(localStorage.getItem('IDNumbers'));

	useEffect(() => {
		async function onLoad() {
            setShowPreLoader(true);
			try {
				let otherParams = "";

				if(IDNumberValue.toLocaleLowerCase() !== "all") {
                    let id = IDNumberValue.split(":") //id:idType
					otherParams = "&strIdNumber="+id[0];
                    otherParams += "&strIdType="+id[1];
				}

				let apiResponse = await unityRequests(
					'/transactions?userId='+getUserDetails().data.UserID+otherParams,
					{},
					'get'
				);
                if(apiResponse.result === true) {
                    if(Array.isArray(apiResponse.payload)) {
                        setTransactions(apiResponse.payload);
                    }
                    else {
                        setTransactions([]);
                    }
                    setShowPreLoader(false);
                }
                else {
                    console.log(apiResponse.payload);
                    setShowPreLoader(false);
                }
			} catch (e) {
				console.log(e);
                setShowPreLoader(false);
			}
		}
		onLoad();
	}, [IDNumberValue]);


	function handleInfoClick(e, item) {
        e.preventDefault();
        setCurrentDetailRow(item);
		setShowDetailModal(true)
	}

    function handleClearFilterClick(e){
        e.preventDefault();
        setIDNumberValue("ALL");
    }

    let tableConfig = {
        "isSearchable": true,
        "isSortable": true,
        "isExportable": true,
        "isPaginated": true,
        "summedColumns": ["strAmount"],
        "columnAlign": {"Info": "center", "strAmount": "right"},
        "columnFormat": {
            "strAmount": (row, index) => {return "R "+row["strAmount"]},
            "dtPaid": (row, index) => {return Moment(row["dtPaid"]).format('YYYY/MM/DD')}
        },
        "omitColumns": [
            "ClientId",
            "TransactionId",
            "strIVeriNumber",
            "refClientID",
            "intPaid",
            "dtPayment",
            "dtIVeriSettlement",
            "strName",
            "IdSurname",
            "dtPayment",
            "strIssuingAuthority",
            "strStatus",
            "strVechicleRegistration",
            "strVeriResponse",
            "strSurname",
            "IdName",
            "dtInfringementDate",
            "intDemeritPoints"
        ],
        "customColumns": [
            {
                "name": "Info",
                "selector": (row) => (
                    // <Button onClick={(e) => {handleInfoClick(e, row)}}>
                        <AiFillInfoCircle 
                            className="isNotDisabledBtnIconClass"
                            style={{ fontSize: '25px' }} 
                            onClick={(e) => {handleInfoClick(e, row)}}
                        />
                    // </Button>
                ),
                "isSortable": false
            }
        ],
        "topFilters": () => (
            <>
                <Col md={4}>
                    <br></br>
                    <label htmlFor="IDNumberDropDown">
                        <select
                            id="IDNumberDropDown"
                            className="IDNumberDropDown outstandingScreenDropDowns"
                            value={IDNumberValue}
                            onChange={(e) => setIDNumberValue(e.target.value)}
                            onBlur={(e) => setIDNumberValue(e.target.value)}
                        >
                            <option value="ALL">All</option>
                            {IDNumberDropdownList.map((idNumber) => {
                                return (
                                    <option key={idNumber} value={idNumber}>
                                        {idNumber.split(":")[0]}
                                    </option>
                                );
                            })}
                        </select>
                    </label>
                </Col>
                <Col md={2}></Col>
            </>
        )
    }

	return (
        <>
            <Modal
                show={showDetailModal}
                onHide={() => setShowDetailModal(false)}
            >
                <Modal.Header closeButton>
                    Transaction Details
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md="12">
                            <Card>
                                <Card.Body>
                                    <Form>
                                        <Row>
                                            <Col className="pr-1" md={6}>
                                                <Form.Group>
                                                    <label style={{ color: '#000' }}>
                                                        Transaction ID:
                                                    </label>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        id="TransactionID"
                                                        value={currentDetailRow.TransactionId}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col className="pr-1" md={6}>
                                                <Form.Group>
                                                    <label style={{ color: '#000' }}>
                                                        IVeri Number:
                                                    </label>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        id="strIVeriNumber"
                                                        value={currentDetailRow.strIVeriNumber}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="pr-1" md={6}>
                                                <Form.Group>
                                                    <label style={{ color: '#000' }}>
                                                        Client ID:
                                                    </label>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        id="ClientId"
                                                        value={currentDetailRow.ClientId}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col className="pr-1" md={6}>
                                                <Form.Group>
                                                    <label style={{ color: '#000' }}>
                                                        Paid:
                                                    </label>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        id="intPaid"
                                                        value={currentDetailRow.intPaid}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="pr-1" md={6}>
                                                <Form.Group>
                                                    <label style={{ color: '#000' }}>
                                                        Date Paid:
                                                    </label>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        id="dtPaid"
                                                        value={currentDetailRow.dtPaid}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col className="pr-1" md={6}>
                                                <Form.Group>
                                                    <label style={{ color: '#000' }}>
                                                        Date Payment:
                                                    </label>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        id="dtPayment"
                                                        value={currentDetailRow.dtPayment}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="pr-1" md={6}>
                                                <Form.Group>
                                                    <label style={{ color: '#000' }}>
                                                        Status:
                                                    </label>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        id="strStatus"
                                                        value={currentDetailRow.strStatus}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col className="pr-1" md={6}>
                                                <Form.Group>
                                                    <label style={{ color: '#000' }}>
                                                        Amount:
                                                    </label>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        id="strAmount"
                                                        value={currentDetailRow.strAmount}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="pr-1" md={6}>
                                                <Form.Group>
                                                    <label style={{ color: '#000' }}>
                                                        Date IVeri Settlement:
                                                    </label>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        id="dtIVeriSettlement"
                                                        value={currentDetailRow.dtIVeriSettlement}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col className="pr-1" md={6}>
                                                <Form.Group>
                                                    <label style={{ color: '#000' }}>
                                                        Receipt Number:
                                                    </label>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        id="strReceiptNumber"
                                                        value={currentDetailRow.strReceiptNumber}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        {currentDetailRow.strVeriResponse !== "No Result" ?
                                            <Row>
                                                <Col className="pr-1" md={6}>
                                                    <Form.Group>
                                                        <label style={{ color: '#000' }}>
                                                            IVeri Response:
                                                        </label>
                                                        <Form.Control
                                                            disabled
                                                            type="text"
                                                            id="strVeriResponse"
                                                            value={currentDetailRow.strVeriResponse}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col className="pr-1" md={6}>
                                                </Col>
                                            </Row>
                                        : <></>}
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <div className="center-to-screen pt-3">
                {showPreloader ? (
                        <div>
                            <Spinner
                                animation="border"
                                role="status"
                                style={{ display: 'block', margin: '0 auto' }}
                            >
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    ) : (
                        
                            <Container>
                                <Row>
                                    <Col>
                                        <h1 className="form-header">Transactions</h1>
                                    </Col>
                                </Row>
                                {transactions.length <= 0 ? (
                                        <Row>
                                            <Col style={{textAlign:'center'}}>
                                                <h1>No Transactions Available</h1> <LoaderButton style={{color:'#fff'}} onClick={(e)=>handleClearFilterClick(e)} isLoading={showPreloader}>Clear Filters</LoaderButton>
                                            </Col>
                                        </Row> 
                                ) : (
                                    <>
                                        <Row>
                                            <Col md={12}>
                                                <ExportableTable title="" config={tableConfig} data={transactions}/>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </Container>
                    )
                }
            </div>
        </>
	);
}

export default memo(Transactions);