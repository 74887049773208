import React, { memo, useState, useEffect } from 'react';
import { PopupModalContext } from "../contexts/popupModalContext";
import { LoaderButton } from './common';
import { Form, Row, Container, Col, Image, Spinner, Modal } from 'react-bootstrap';
import { FaShoppingCart } from 'react-icons/fa';
import { isLoggedIn } from 'utils';
import { useLocalStorage } from '@rehooks/local-storage';
import { useLocation, useHistory, Link } from "react-router-dom";
import unityRequests from './unityRequests';
import { getUserDetails } from 'utils';
import { IoArrowBackCircle } from 'react-icons/io5';


function CPAFineDetails() {
    let { handlePopupModal } = React.useContext(PopupModalContext);
    let history = useHistory();
    const [form, setForm] = useState({
      noticeNumber: '',
      location: '',
      districtName: '',
      idNumber: '',
      vehicleRegistrationNumber: '',
      phase:'',
      offenceDate:'',
      summonsNumber:'',
      courtName:'',
      courtDate:'',
      warrantOfArrestNumber:'',
      totalAmountDue:''
   });
	const [persistedCartItems, setPersistedCartItems] = useLocalStorage(
		'cartItems',
		{}
	);
	const [showPreloader, setShowPreLoader] = useState(false);
	const [isAddingTocart, setIsAddingTocart] = useState(false);
	const [apiError, setApiError] = useState(false);
	const [showEvidenceModal, setShowEvidenceModal] = useState(false);
	const [evidenceModalImageSrc, setEvidenceModalImageSrc] = useState("");
	const [charges, setCharges] = useState([]);
	const [evidence, setEvidence] = useState([]);
	const [infringementDate, setInfringementDate] = useState("");
	const [dtCourt, setDtCourt] = useState("");

	function getField(field){
		return form[field];
	};

   //get noticenumber from URL and clean it
   let urlLocation = useLocation();
   let fineID = false;
   let priorPage = false;
   if (urlLocation.search.length > 0) {
       let urlparams = urlLocation.search.substr(1).split('&');
       urlparams.forEach((item) => {
           let hold = item.split("=");
           if (hold[0] === 'id') {
               fineID = hold[1];
           }
           else if (hold[0] === 'priorPage') {           
            if(hold[1] === '/outstandingfines') {   
                priorPage = hold[1];                     
            }
            else{
                priorPage = hold[1]+'='+hold[2];                
            }              
           }
       })
   }

    useEffect(() => {
        async function onLoad() {
            try {
                setShowPreLoader(true);
    
                let userID = "";

                if(isLoggedIn()){
                    userID = getUserDetails().data.UserID;
                }
                else{
                    userID = "GUEST";
                }
                
                let iForceFineDetailsResponse = await unityRequests(
                    `/getoutstandingfines?Id=${userID}&noticeNumber=${fineID}`,
                    {},
                    'get'
                );
    
                if(iForceFineDetailsResponse.status === 504 || iForceFineDetailsResponse.status === 400)//if error switch preloader off and show appropriate message
                {
                    setApiError(true);
                    setShowPreLoader(false);
                }
                else
                {
                    if(iForceFineDetailsResponse.result === true) //if no error switch set fields to data from server response
                    {
                        setApiError(false);

                        //get charges of infringement
                        let iforceChargesResponse = await unityRequests(
                            `/getcharges?Id=${userID}&noticeNumber=${fineID}`,
                            {},
                            'get'
                        );

                        if(iforceChargesResponse.status === 504 || iforceChargesResponse.status === 400)//if error switch preloader off and show appropriate message
                        {
                            setApiError(true);
                            setShowPreLoader(false);
                            return;
                        }
                            
                        //get evidence of infringement
                        let iforceEvidenceResponse;
                        if(iForceFineDetailsResponse.payload.fines[0].evidence.length > 0) {
                            iforceEvidenceResponse = await unityRequests(
                                `/evidence?Id=${userID}&evidenceToken=${iForceFineDetailsResponse.payload.fines[0].evidence[0].evidenceToken}&includeImage=true`,
                                {},
                                'get'
                            );

                            if(iforceEvidenceResponse.status === 504 || iforceEvidenceResponse.status === 400)//if error switch preloader off and show appropriate message
                            {
                                setApiError(true);
                                setShowPreLoader(false);
                                return;
                            }
                        }

                        //set form details
                        setForm({...iForceFineDetailsResponse.payload.fines[0], api: "iForce"});
                        let offenceDate = iForceFineDetailsResponse.payload.fines[0].offenceDate.split("T");
                        offenceDate = offenceDate[0].replace(/-/g, '/');
                        setInfringementDate(offenceDate);

                        let dateCourt = "";
                        if(iForceFineDetailsResponse.payload.fines[0].courtDate !== null) {
                            dateCourt = iForceFineDetailsResponse.payload.fines[0].courtDate.split("T");
                            dateCourt = dateCourt[0].replace(/-/g, '/');
                        }
                        setDtCourt(dateCourt);

                        //set charges
                        if(iforceChargesResponse.payload) {
                            setCharges(iforceChargesResponse.payload);
                        }
                        
                        //set evidence
                        if(iforceEvidenceResponse !== undefined) {
                            setEvidence([iforceEvidenceResponse.payload]);
                        }
                        else {
                            setEvidence([]);
                        }
                        
                        setShowPreLoader(false);
                    }
                }
    
            } catch (e) {
                setApiError(true);
                console.log(e);
                setShowPreLoader(false);
            }
        }
		onLoad();
	}, [fineID]);
    
	function addToCart(item) {
		if (item) {
			let cartItems = { ...persistedCartItems }; //important to not make changes to the persistedCartItems itself else it wont trigger updates
			var fineID = '';
			fineID = item.noticeNumber;
			if (!Object.keys(cartItems).includes(fineID)) {
				cartItems[fineID] = item;
				setPersistedCartItems(cartItems);
			} else {
				return false;
			}
		}
		return false;
	}

	function checkIfCartItemExist(item) {
		if (item) {
			let cartItems = { ...persistedCartItems }; //important to not make changes to the persistedCartItems itself else it wont trigger updates
			var itemKeys = Object.keys(item);
			var fineID = '';
			if (itemKeys.includes('infringementNoticeNumber')) {
				fineID = item.infringementNoticeNumber;
			} else {
				fineID = item.noticeNumber;
			}
            if(item.customerPaymentAllowed === false) {
                return true;
            }
			if (Object.keys(cartItems).includes(fineID)) {
				return true;
			} else {
				return false;
			}
		}
		return false;
	}

	function handleAddToCart(e) {
	    e.preventDefault();
        console.log(form);
        setIsAddingTocart(true);
		addToCart(form);
		handlePopupModal(
			'Fine:' + fineID +' has been added to shopping cart'
		);
        setIsAddingTocart(false);
	}

    function openImageInNewTab(imageSrc){
        let newTab = window.open("", '_blank')
        newTab.document.write("<img src='"+ imageSrc +"' width='400px'/>");
        newTab.window.stop();
    }

    function handleOutsideCheckout(e){
        handleAddToCart(e);
        history.push('/Cart');
    }

    function openImageInModal(imageSrc){
        setShowEvidenceModal(true);
        setEvidenceModalImageSrc(imageSrc);
    }

	return (
		<div className="center-to-screen pb-3 pt-3">
        {showPreloader ? (
                <div>
                    <Spinner
                        animation="border"
                        role="status"
                        style={{ display: 'block', margin: '0 auto' }}
                    >
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
            <Container>
                {apiError ? (<Row><Col md="12" style={{textAlign:'center'}}><h3>iForce is down.</h3></Col></Row>) : (
                    <>
                        <Row>
                            <Col md="12">
                                <Form className="form">
                                    <h1>Fine Details - CPA</h1>
                                    {/* <Row style={{paddingTop:'15px', marginRight:'10px'}}>
                                        <Col className="evidenceImagesDiv" lg={6} style={{textAlign:'center', margin:'10px', padding: '4rem 2rem', borderRadius: '.3rem', backgroundColor:'#89959f' }}>
                                            {evidence.length > 0 && evidence.map((item) => {
                                                return (
                                                    <div key={item.fileName}>
                                                        {evidence.length !== 2 ? (
                                                            <>
                                                                <Col lg={12}>
                                                                    <span className="evidenceImagesText">
                                                                        <p>Image not available</p>
                                                                    </span>
                                                                </Col>
                                                                <Col lg={12}>
                                                                    <Image src={`data:${item.mimeType};base64,${item.fileContents}`} alt={item.fileName} onClick={()=>openImageInNewTab(`data:${item.mimeType};base64,${item.fileContents}`)} key={item.fileName} width={200} fluid/>
                                                                </Col>
                                                            </>
                                                        ) : (
                                                            <Col lg={12}>
                                                                <Image src={`data:${item.mimeType};base64,${item.fileContents}`} alt={item.fileName} onClick={()=>openImageInNewTab(`data:${item.mimeType};base64,${item.fileContents}`)} key={item.fileName} width={200} className="mb-2" fluid/>
                                                            </Col>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </Col>
                                    </Row> */}
                                    <Row style={{paddingTop:'15px', marginRight:'10px'}}>
                                        <Col lg={12} style={{display:'flex', textAlign:'center', margin:'10px', padding: '4rem 2rem', borderRadius: '3.4rem', backgroundColor:'#89959f' }}>
                                            {evidence.length > 0? evidence.map((item) => {
                                                return (
                                                    <>
                                                        {evidence.length !== 2 ? (
                                                            <>
                                                                <Col style={{marginTop:'auto', marginBottom:'auto'}}>
                                                                    <span className="evidenceImagesText">
                                                                        <p>Image not available</p>
                                                                    </span>
                                                                </Col>
                                                                <Col>
                                                                    <Image src={`data:${item.mimeType};base64,${item.fileContents}`} alt={item.fileName} /*onClick={()=>openImageInNewTab(`data:${item.mimeType};base64,${item.fileContents}`)}*/ onClick={()=>openImageInModal(`data:${item.mimeType};base64,${item.fileContents}`)} key={item.fileName} width={200} fluid/>
                                                                </Col>
                                                            </>
                                                        ) : (
                                                            <Col>
                                                                <Image src={`data:${item.mimeType};base64,${item.fileContents}`} alt={item.fileName} /*onClick={()=>openImageInNewTab(`data:${item.mimeType};base64,${item.fileContents}`)}*/ onClick={()=>openImageInModal(`data:${item.mimeType};base64,${item.fileContents}`)} key={item.fileName} width={200} className="mb-2" fluid/>
                                                            </Col>
                                                        )}
                                                    </>
                                                );
                                            }) :
                                                <>
                                                    <Col style={{marginTop:'auto', marginBottom:'auto'}}>
                                                        <span className="evidenceImagesText">
                                                            <p>Image not available</p>
                                                        </span>
                                                    </Col>
                                                    <Col style={{marginTop:'auto', marginBottom:'auto'}}>
                                                        <span className="evidenceImagesText">
                                                            <p>Image not available</p>
                                                        </span>
                                                    </Col>
                                                </>
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pr-1 mt-1 mb-1" md={5}>
                                            <div className="mt-1 mb-3 details-screen-boxes" style={{display:'flex', backgroundColor:'#FFFDF2', borderRadius:'40px', width:'auto'}}>
                                                <Col md={4} style={{marginTop:'auto', marginBottom:'auto'}}>
                                                    <label style={{marginBottom:'0px', color: '#89959F'}}>
                                                        Infringement Number:
                                                    </label>
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        id="strNoticeNumber"
                                                        value={getField('noticeNumber')}
                                                        style={{width:'auto', background:'transparent', border:'none', padding:'0px', color: '#5F6F7C'}}
                                                    />
                                                </Col>
                                            </div>
                                        </Col>
                                        <Col className="pr-1 mt-1 mb-1" md={{span: 5, offset:2}}>
                                            <div className="mt-1 mb-3 details-screen-boxes" style={{display:'flex', backgroundColor:'#FFFDF2', borderRadius:'40px', width:'auto'}}>
                                                <Col md={4} style={{marginTop:'auto', marginBottom:'auto'}}>
                                                    <label style={{marginBottom:'0px', color: '#89959F'}}>
                                                        Status:
                                                    </label>
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        id="strStatus"
                                                        value={getField('phase')}
                                                        style={{width:'auto', background:'transparent', border:'none', padding:'0px', color: '#5F6F7C'}}
                                                    />
                                                </Col>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pr-1 mt-1 mb-1" md={5}>
                                            <div className="mt-1 mb-3 details-screen-boxes" style={{display:'flex', backgroundColor:'#FFFDF2', borderRadius:'40px', width:'auto'}}>
                                                <Col md={4} style={{marginTop:'auto', marginBottom:'auto'}}>
                                                    <label style={{marginBottom:'0px', color: '#89959F'}}>
                                                        Issuing Authority:
                                                    </label>
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        id="strIssuingAuthority"
                                                        value={getField('districtName')}
                                                        style={{width:'auto', background:'transparent', border:'none', padding:'0px', color: '#5F6F7C'}}
                                                    />
                                                </Col>
                                            </div>
                                        </Col>
                                        <Col className="pr-1 mt-1 mb-1" md={{span: 5, offset:2}}>
                                            <div className="mt-1 mb-3 details-screen-boxes" style={{display:'flex', backgroundColor:'#FFFDF2', borderRadius:'40px', width:'auto'}}>
                                                <Col md={4} style={{marginTop:'auto', marginBottom:'auto'}}>
                                                    <label style={{marginBottom:'0px', color: '#89959F'}}>
                                                        Location:
                                                    </label>
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        id="strLocation"
                                                        value={getField('location')}
                                                        style={{width:'auto', background:'transparent', border:'none', padding:'0px', color: '#5F6F7C'}}
                                                    />
                                                </Col>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pr-1 mt-1 mb-1" md={5}>
                                            <div className="mt-1 mb-3 details-screen-boxes" style={{display:'flex', backgroundColor:'#FFFDF2', borderRadius:'40px', width:'auto'}}>
                                                <Col md={4} style={{marginTop:'auto', marginBottom:'auto'}}>
                                                    <label style={{marginBottom:'0px', color: '#89959F'}}>
                                                        ID Number:
                                                    </label>
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        id="strIDNumber"
                                                        value={isLoggedIn() ? getField('idNumber') : "*"}
                                                        style={{width:'auto', background:'transparent', border:'none', padding:'0px', color: '#5F6F7C'}}
                                                    />
                                                </Col>
                                            </div>
                                        </Col>
                                        <Col className="pr-1 mt-1 mb-1" md={{span: 5, offset:2}}>
                                            <div className="mt-1 mb-3 details-screen-boxes" style={{display:'flex', backgroundColor:'#FFFDF2', borderRadius:'40px', width:'auto'}}>
                                                <Col md={4} style={{marginTop:'auto', marginBottom:'auto'}}>
                                                    <label style={{marginBottom:'0px', color: '#89959F'}}>
                                                        Amount Due:
                                                    </label>
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        id="strAmountDue"
                                                        value={'R '+getField('totalAmountDue')}
                                                        style={{width:'auto', background:'transparent', border:'none', padding:'0px', color: '#5F6F7C'}}
                                                    />
                                                </Col>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pr-1" md={5}>
                                            <div className="mt-1 mb-3 details-screen-boxes" style={{display:'flex', backgroundColor:'#FFFDF2', borderRadius:'40px', width:'auto'}}>
                                                <Col md={4} style={{marginTop:'auto', marginBottom:'auto'}}>
                                                    <label style={{marginBottom:'0px', color: '#89959F'}}>
                                                        Vehicle Registration:
                                                    </label>
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        id="strVehicleRegistration"
                                                        value={getField('vehicleRegistrationNumber')}
                                                        style={{width:'auto', background:'transparent', border:'none', padding:'0px', color: '#5F6F7C'}}
                                                    />
                                                </Col>
                                            </div>
                                            <div className="mt-1 mb-3 details-screen-boxes" style={{display:'flex', backgroundColor:'#FFFDF2', borderRadius:'40px', width:'auto'}}>
                                                <Col md={4} style={{marginTop:'auto', marginBottom:'auto'}}>
                                                    <label style={{marginBottom:'0px', color: '#89959F'}}>
                                                        Infringement Date:
                                                    </label>
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        id="dtInfringementDate"
                                                        value={infringementDate}
                                                        style={{width:'auto', background:'transparent', border:'none', padding:'0px', color: '#5F6F7C'}}
                                                    />
                                                </Col>
                                            </div>
                                            <div className="mt-1 mb-3 details-screen-boxes" style={{display:'flex', backgroundColor:'#FFFDF2', borderRadius:'40px', width:'auto'}}>
                                                <Col md={4} style={{marginTop:'auto', marginBottom:'auto'}}>
                                                    <label style={{marginBottom:'0px', color: '#89959F'}}>
                                                        Summons Number:
                                                    </label>
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        id="strSummonsNumber"
                                                        value={getField('summonsNumber') || "N/A"}
                                                        style={{width:'auto', background:'transparent', border:'none', padding:'0px', color: '#5F6F7C'}}
                                                    />
                                                </Col>
                                            </div>
                                            <div className="mt-1 mb-3 details-screen-boxes" style={{display:'flex', backgroundColor:'#FFFDF2', borderRadius:'40px', width:'auto'}}>
                                                <Col md={4} style={{marginTop:'auto', marginBottom:'auto'}}>
                                                    <label style={{marginBottom:'0px', color: '#89959F'}}>
                                                        Court:
                                                    </label>
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        id="strCourt"
                                                        value={getField('courtName')}
                                                        style={{width:'auto', background:'transparent', border:'none', padding:'0px', color: '#5F6F7C'}}
                                                    />
                                                </Col>
                                            </div>
                                            <div className="mt-1 mb-3 details-screen-boxes" style={{display:'flex', backgroundColor:'#FFFDF2', borderRadius:'40px', width:'auto'}}>
                                                <Col md={4} style={{marginTop:'auto', marginBottom:'auto'}}>
                                                    <label style={{marginBottom:'0px', color: '#89959F'}}>
                                                        Court Date:
                                                    </label>
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        id="dtCourtDate"
                                                        value={dtCourt}
                                                        style={{width:'auto', background:'transparent', border:'none', padding:'0px', color: '#5F6F7C'}}
                                                    />
                                                </Col>
                                            </div>
                                            <div className="mt-1 mb-3 details-screen-boxes" style={{display:'flex', backgroundColor:'#FFFDF2', borderRadius:'40px', width:'auto'}}>
                                                <Col md={4} style={{marginTop:'auto', marginBottom:'auto'}}>
                                                    <label style={{marginBottom:'0px', color: '#89959F'}}>
                                                        Warrant Number:
                                                    </label>
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        id="strWarrantNumber"
                                                        value={getField('warrantOfArrestNumber') || "N/A"}
                                                        style={{width:'auto', background:'transparent', border:'none', padding:'0px', color: '#5F6F7C'}}
                                                    />
                                                </Col>
                                            </div>
                                        </Col>
                                        <Col md={{span: 5, offset:2}}>
                                            {(charges.length > 0 && charges.length <= 3) && charges.map((charge, index) => {
                                                return (
                                                    <div className="mt-1 mb-3 details-screen-boxes" key={charge.chargeCode} style={{display:'flex', backgroundColor:'#FFFDF2', borderRadius:'40px', width:'auto'}}>
                                                        <Col md={3} style={{marginTop:'auto', marginBottom:'auto'}}>
                                                            <label style={{marginBottom:'0px', color: '#89959F'}}>
                                                                {"Charge "+[index+1]+":"}
                                                            </label>
                                                        </Col>
                                                        <Col md={9}>
                                                            <Form.Control
                                                                disabled
                                                                as="textarea"
                                                                rows={2}
                                                                id={"strCharge"+index}
                                                                value={charge.description}
                                                                style={{border:'none', color: '#5F6F7C', "overflowWrap": 'normal'}}
                                                            />
                                                        </Col>
                                                    </div>
                                                );
                                            })}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={{span:1, offset:3}} className="mt-3">
                                            {priorPage? (
                                                <Link to={priorPage} href={"/"+priorPage}>
                                                    <IoArrowBackCircle 
                                                        style={{ "fontSize": '40px' }}
                                                        className="isNotDisabledBtnIconClass"
                                                    />
                                                </Link>
                                            ) : (<></>)}
                                        </Col>
                                        <Col md={{span:4}}>
                                            {isLoggedIn() ? (
                                                <LoaderButton
                                                    type="submit"
                                                    className="btn-grey btn-block mt-3"
                                                    isLoading={isAddingTocart}
                                                    onClick={handleAddToCart}
                                                    disabled={checkIfCartItemExist(form)}
                                                >
                                                    Add to Cart{' '}
                                                    <FaShoppingCart style={{ fontSize: '20px' }} />
                                                </LoaderButton>
                                            ) : (<></>)}
                                        </Col>
                                    </Row>
                                    <div className="clearfix"></div>
                                </Form>
                            </Col>
                        </Row>
                    </>
                )}
                    <Row>
                        <Modal
                            show={showEvidenceModal}
                            onHide={() => {
                                setShowEvidenceModal(false);
                            }}
                        >
                            <Modal.Header closeButton>
                                {/* <Modal.Title>Evidence</Modal.Title> */}
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col md="12" style={{textAlign:'center'}}>
                                        <Image src={evidenceModalImageSrc} width={400} style={{padding:'10px'}}/>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            {/* <Modal.Footer>
                            </Modal.Footer> */}
                        </Modal>
                    </Row>
            </Container>
        )}
		</div>
	);
}

export default memo(CPAFineDetails);
