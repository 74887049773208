var config = {
    dev: {
        MAX_ATTACHMENT_SIZE: 5000000,
        s3: {
            REGION: "eu-west-1",
            BUCKET: "pmf-development-invoices"
        },
        apiGateway: {
            REGION: "eu-west-1",
            URL: "https://h8vzvoqq9c.execute-api.eu-west-1.amazonaws.com/development"
        },
        cognito: {
            REGION: "eu-west-1",
            USER_POOL_ID: "eu-west-1_8MkXacpCX",
            APP_CLIENT_ID: "k607qc16a64ln1jq44kth31li",
            IDENTITY_POOL_ID: "eu-west-1:898145914743:userpool/eu-west-1_8MkXacpCX"
        },
        iVeriAppID: "{2ee048e5-8338-491b-aaea-dc91d43847f1}"
    },
    prod: {
        MAX_ATTACHMENT_SIZE: 5000000,
        s3: {
            REGION: "eu-west-1",
            BUCKET: "tmt-pmf-prod-invoices"
        },
        apiGateway: {
            REGION: "eu-west-1",
            URL: "https://puru95wxc8.execute-api.eu-west-1.amazonaws.com/production"
        },
        cognito: {
            REGION: "eu-west-1",
            USER_POOL_ID: "eu-west-1_gzZa8Iitq",
            APP_CLIENT_ID: "24kutsb2l5dlrm3k6c9tjrlp7d",
            IDENTITY_POOL_ID: "eu-west-1:475415325198:userpool/eu-west-1_gzZa8Iitq"
        },
        iVeriAppID: "{4C03BE1B-05AF-48BF-972B-00DAE5F11AD9}"
    }
};


var awsconfigPath = "dev";
if(window.location.origin === "https://www.paymyfines.co.za") {
    awsconfigPath = "prod";
}
export default config[awsconfigPath];